

const getTeamRanking = () => {
  const MAX = 2020
  const MIN = 2001
  const dss = { share: [1,1], group: ['Sijoitus', 'Tavoite'] }
  let data = {
    labels:[],
    datas: [],
    titles: []
  }
  for(let year = MIN; year < MAX; year++) {
    data.labels.push(year)
    for(let i = 0; i < dss.share.length; i++) {
      if(!data.datas[i]) {
        data.datas[i] = []
      }
      data.datas[i].push( 1 + Math.round(((Math.random() * 20) * dss.share[i])))
    }
  }
  data.titles = dss.group
  return data

}

const getCoachingGenders = () => {
  const MAX = 2016
  const MIN = 2001
  const dss = { share: [0.6,0.4], group: ['Pojat', 'Tytöt'] }
  let data = {
    labels:[],
    datas: [],
    titles: []
  }
  for(let year = MIN; year < MAX; year++) {
    data.labels.push(year)
    for(let i = 0; i < dss.share.length; i++) {
      if(!data.datas[i]) {
        data.datas[i] = []
      }
      data.datas[i].push(Math.round(((year-MIN)/MIN) * 100 * (Math.random() * 100) * dss.share[i]))
    }
  }
  data.titles = dss.group
  return data
}


const getCoachingAges = () => {
  const MAX = 2016
  const MIN = 2001
  const dss = { share: [0.1,0.2,0.7], group: ['Kilparyhmä', 'Esikilpa', 'Harraste'] }
  let data = {
    labels:[],
    datas: [],
    titles: []
  }
  for(let year = MIN; year < MAX; year++) {
    data.labels.push(year)
    for(let i = 0; i < dss.share.length; i++) {
      if(!data.datas[i]) {
        data.datas[i] = []
      }
      data.datas[i].push(Math.round(((year-MIN)/MIN) * 100 * (Math.random() * 100) * dss.share[i]))
    }
  }
  data.titles = dss.group
  return data
}

// import workdata from './work.json'

const getWorkData = () => {
  var workdata = require('./work.json')
  return workdata;
}


 const getInvoices = () => {
  var invoices = require('./invoices.json')
  return invoices;
 }

module.exports = {
  getCoachingAges,
  getWorkData,
  getInvoices,
  getCoachingGenders,
  getTeamRanking
}
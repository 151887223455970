import React, { Component } from 'react';
import { withFirebase } from '../Firebase'
import {
  Input,
  Progress
} from "reactstrap";

class InvoiceImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importstate: 1,
      progressFiles: null,
      uploaded: 0
    }

    this.handleChange = this
      .handleChange
      .bind(this);
  }

  verifyUploads = () => {
    if ((this.state.uploaded + 1) === this.state.progressFiles.length) {
      this.setState({ importstate: this.state.importstate + 1 , uploaded: 0, progressFiles: null })
    } else {
      this.setState({ uploaded: this.state.uploaded + 1 })
    }
  }

  handleChange = e => {
    this.setState({ progressFiles: e.target.files })
    for (let i = 0; i < e.target.files.length; i++) {
      this.handleUpload(e.target.files[i])
    }
  }

  handleUpload = (docufile) => {
    const storageRef = this.props.folder + '/' + this.props.profile.customer.identity + '/' + docufile.name
    const uploadTask = this.props.firebase.storage.ref(`${storageRef}`).put(docufile);
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        let _d = {}
        _d[docufile.name] = progress
        // _d.some = progress
        this.setState(_d);
      },
      (error) => {
        // error function ....
        console.log(error);
      },
      () => {
        this.verifyUploads()
      })
  }


  render() {
    const style = {
      // height: '100vh',
      // display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      // marginBottom: 10
    }
    let progresses = null
    if (this.state.progressFiles) {
      progresses = Array.from(this.state.progressFiles).map((documentFile) => <Progress className="multiupload" color="success" key={documentFile.name} max="100" value={this.state[documentFile.name]}>{documentFile.name}</Progress>)
    }

    return (
      <div style={style}>
        <div id="uploads" className="uploadprogressbars">
          {progresses}
        </div>
        <br />
        <div id="invoicedocuments">
          {this.state.importstate === 1 ? (<>Invoice images
              <Input className="documentupload" placeholder="Document" name="documentFile" type="file" multiple onChange={this.handleChange} />
            </>) : ''}
            {this.state.importstate === 2 ? (<>Finvoice XML
              <Input className="documentupload" placeholder="Document" name="xmlFile" type="file" onChange={this.handleChange} />
            </>) : ''}
          {this.state.importstate === 3 ? (<>Done</>) : ''}
        </div>
        <div id="finvoice">
        </div>
      </div>
    )
  }
}

export default withFirebase(InvoiceImport)
import React from 'react';

import { AuthUserContext, UserProfileContext } from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        profile: null,
        customer: null,
        clubdata: null,
        demoticks: {},
        toggleDemoTick: (d) => { this.setState({ profile: this.updateDemoTick(d) })}
      };
    }

    makeSureAuthIsNull = () => {
      // console.log('All nulled')
    }

    updateDemoTick = (d) => {
      let _profile = this.state.profile
      _profile.demoticks[d] = true
      return _profile
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          localStorage.removeItem('loading')
          this.props.firebase.getUser().then(userprofile => {
          // this.props.firebase.user(authUser.uid).get().then(userprofile => {
            if (userprofile) {
              this.props.firebase.customerid = userprofile.data().customerIds[0]
              this.props.firebase.customer().get().then((customer) => {
                if(customer.data()) {
                  customer.data().clubdata.get().then((clubdata) => {
                    let _cd = clubdata.data()
                    _cd.acronym = clubdata.id
                    let _c = customer.data()
                    _c.id = customer.id
                    // this.props.firebase.customerid = customer.id
                    let _profile = { userprofile: userprofile.data(), customer: _c, clubdata: _cd,  demoticks: this.state.demoticks, toggleDemoTick: this.state.toggleDemoTick } 
                    this.setState({  authUser: authUser, profile: _profile,  })
                    // console.log('get clubdata')
                    // console.log(clubdata.data())
                  })
                }
              })
            }
          }).catch((error) => {
            // let errorMessage = error.message
            // console.log(errorMessage)
            // console.log(authUser.uid)
            this.setState({ loginError: error.message });
            // this.props.firebase.doSignOut()
          })
         // this.setState({ authUser: authUser })
        } else {
          this.setState({ authUser: null, profile: null, customer: null, clubdata: null }, this.makeSureAuthIsNull);
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <UserProfileContext.Provider value={this.state.profile}>
            <Component {...this.props} authUser={this.state.authUser} profile={this.state.profile} loginError={this.state.loginError} />
          </UserProfileContext.Provider>
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
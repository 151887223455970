/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// react component for creating dynamic tables
import { withFirebase } from '../../components/Firebase'
import ReactTable from 'react-table'
// import Switch from "react-bootstrap-switch";
import moment from 'moment'
import 'moment/locale/fi'
import DemonstrationData from '../components/DemonstrationData'
import DemoAlert from '../components/DemoAlert'
import equal from 'fast-deep-equal'
import Select from 'react-select'

// reactstrap components
import { Card, CardBody, Row, Col } from 'reactstrap'
import { getWorkData } from 'variables/mockup-data'
// import { data } from 'jquery'

class WorkReport extends React.Component {
  constructor(props) {
    super(props)
    this.reactTable = React.createRef()
    // this.handleSwitch = this.handleSwitch.bind(this)
    this.state = {
      data: null,
      loading: false,
      hours: [],
      empNames: [],
      statusFilter: 'nothandled',
      filtered: [],
      selectedmonth: moment().subtract(1, 'month').format('MM-YYYY'),
      allMonths: [],
      columns: []
    }
  }

  getDemoData = () => {
    const _data = getWorkData()
    this.setState({ data: _data }, this.filterData)
  }

  updateFilter = (status) => {
    this.setState({ statusFilter: status }, this.filterData)
  }

  handleClick(elem, state, docid) {
    if (this.props.profile.customer.plan !== 'free') {
      this.props.firebase
        .acceptHours(docid)
        .set({ status: state }, { merge: true })
        .then((data) => {
          console.log(data)
        })
        .catch((error) => {
          alert(error.message)
        })
    } else {
      let _hs = [...this.state.data]
      let _hi = _hs.findIndex((h) => h.id === docid)
      let _h = { ..._hs[_hi] }
      _h.status = state
      _hs[_hi] = _h
      this.setState({ data: _hs }, this.filterData)
    }
  }

  handlePayment(elem, state, docid) {
    if (this.props.profile.customer.plan !== 'free') {
      this.props.firebase
        .acceptHours(docid)
        .set({ payment: state }, { merge: true })
        .then((data) => {
          console.log(data)
        })
        .catch((error) => {
          alert(error.message)
        })
    } else {
      let _hs = [...this.state.data]
      let _hi = _hs.findIndex((h) => h.id === docid)
      let _h = { ..._hs[_hi] }
      _h.payment = state
      _hs[_hi] = _h
      this.setState({ data: _hs }, this.filterData)
    }
  }

  filterData = () => {
    if (this.state.data) {
      let data = this.state.data
      let aggregate = {}
      let _allMonths = {}
      data.map((r) => {
        if (!r.employee) return null
        if (!aggregate[r.employee]) aggregate[r.employee] = {}
        if (r.starttime) r.start = moment.unix(r.starttime.seconds).format('YYYY-MM-DDTHH:mm')
        if (r.endtime) r.end = moment.unix(r.endtime.seconds).format('YYYY-MM-DDTHH:mm')
        if (!r.duration) {
          if (r.start && r.end) {
            r.duration = moment(r.end).diff(moment(r.start), 'hours', true)
          } else {
            r.duration = 0
          }
        }
        _allMonths[moment(r.start).format('MM-YYYY')] = 0 // for selection filter values

        if (this.state.selectedmonth) {
          if (this.state.selectedmonth === moment(r.start).format('MM-YYYY')) {
            if (!aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')]) {
              aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')] = { r: 0, a: 0, n: 0, disp: '' }
            }

            if (r.status === 'rejected') {
              aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')].r +=
                r.duration
            } else if (r.status === 'accepted') {
              aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')].a +=
                r.duration
            } else {
              aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')].n +=
                r.duration
            }
            aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')].disp = aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')].a + '/' + aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')].n + '/' + aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')].r
          }
        } else {
          if (!aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')]) {
            aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')] = { r: 0, a: 0, n: 0, disp: '' }
          }
          // aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')] +=
          //   r.duration
          if (r.status === 'rejected') {
            aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')].r +=
              r.duration
          } else if (r.status === 'accepted') {
            aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')].a +=
              r.duration
          } else {
            aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')].n +=
              r.duration
          }
          aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')].disp = aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')].a + '/' + aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')].n + '/' + aggregate[r.employee][moment(r.start).format('DD-MM-YYYY')].r
      }

        return null
      })

      let empNames = Object.keys(aggregate)
      let hours = empNames.map((e) => {
        let r = { ...aggregate[e] }
        r.employee = e
        return r
      })
      let allMonths = Object.keys(_allMonths).sort()
      const columns = this.getThisMonthColumns(hours, empNames)
      this.setState({
        hours: hours,
        loading: false,
        empNames: empNames,
        aggregate: aggregate,
        allMonths: allMonths,
        columns: columns
      })
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    if (this.props.profile.customer.plan !== 'free') {
      if (this.props.employeedata) {
        this.setState(
          { data: this.props.employeedata, employee: this.props.employee },
          this.filterData
        )
      } else {
        this.unsubscribe = this.props.firebase
          .hours()
          .onSnapshot((snapshot) => {
            let rawData = []
            snapshot.forEach((doc) => {
              let _d = { ...doc.data(), id: doc.id }
              rawData.push(_d)
            })
            this.setState({ data: rawData }, this.filterData)
          })
      }
    } else {
      this.getDemoData()
    }
  }
  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.employeedata) {
      if (!equal(this.props.employeedata, prevProps.employeedata)) {
        this.setState({ data: this.props.employeedata }, this.filterData)
      }
    }
  }

  onTableFilterChange = () => {
    const current = this.reactTable.current
    const allData = current.getResolvedState().sortedData
    this.setState({ filtered: allData })
  }

  getThisMonthColumns = (hours, empNames) => {
    let beginOfMonth = moment(this.state.selectedmonth, 'MM-YYYY').startOf('month')
    let endOfMonth = moment(this.state.selectedmonth, 'MM-YYYY').endOf('month')
    let cols = [
      {
        Header: 'Työntekijä',
        accessor: 'employee',
        maxWidth: 120,
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value=''>Kaikki</option>
            {empNames.map((n) => (
              <option key={n} value={n}>
                {n}
              </option>
            ))}
          </select>
        ),
      },
    ]
    while (beginOfMonth.isSameOrBefore(endOfMonth)) {
      cols.push({
        Header: beginOfMonth.format('DD.'),
        accessor: beginOfMonth.format('DD-MM-YYYY') + '.disp',
        // accessor: data => {
        //   console.log(data, beginOfMonth.format('DD-MM-YYYY'))
        //   return data[beginOfMonth.format('DD-MM-YYYY')] ? data[beginOfMonth.format('DD-MM-YYYY')].n : ''
        // },
        minWidth: 35,
        sortable: false,
        filterable: false,
        Footer: (
          <span>
            {hours.reduce((total, curr) => {
              if (curr[beginOfMonth.format('DD-MM-YYYY')]) {
                total += curr[beginOfMonth.format('DD-MM-YYYY')].n + curr[beginOfMonth.format('DD-MM-YYYY')].a
              }
              return total
            }, 0)}
          </span>
        ),
      })
      beginOfMonth.add(1, 'days')
    }
    cols.push({
      Header: 'Sum',
      accessor: (row) => {
        return Object.keys(row).reduce((total, curr) => {
          if (curr !== 'employee') total += row[curr].a + row[curr].n
          return total
        }, 0)
      },
      id: 'sum',
      width: 60,
      sortable: false,
      filterable: false,
    })
    return cols
  }

  render() {
    const { loading } = this.state
    if (!loading) {
      moment.locale('fi')
      let pagesize = Math.ceil(this.state.empNames.length / 10) * 10
      return (
        <>
          <div className='content'>
            <DemoAlert demolocation='workacceptance' {...this.props} />
            <Row>
              <Col md='12'>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <div className='helptext'>X/Y/Z  X = hyväksytyt tunnit, Y = kuittaamattomat tunnit, Z = hylätyt tunnit. Summa riveillä X + Y</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg='3' md='3'>
                        <h4 style={{ marginTop: '0' }}>
                          {this.state.selectedmonth}
                        </h4>
                      </Col>
                      <Col lg='3' md='3'>
                        <Select
                          className='react-select primary'
                          classNamePrefix='react-select'
                          style={{zIndex: 1 }}
                          name='selectedMonth'
                          value={{
                            value: this.state.selectedmonth,
                            label: this.state.selectedmonth,
                          }}
                          onChange={(value) =>
                            this.setState(
                              { selectedmonth: value.value },
                              this.filterData
                            )
                          }
                          options={this.state.allMonths.map((m) => {
                            return { value: m, label: m }
                          })}
                          placeholder='Valitse kuukausi'
                        />
                        <DemonstrationData
                          plan={this.props.profile.customer.plan}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ReactTable
                          data={this.state.hours}
                          filterable
                          resizable={false}
                          ref={this.reactTable}
                          onFilteredChange={this.onTableFilterChange}
                          columns={this.state.columns}
                          defaultPageSize={pagesize}
                          showPaginationTop
                          showPaginationBottom={false}
                          style={{ fontSize: 10 }}
                          /*
                            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                          */
                          className='-striped -highlight primary-pagination'
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }
}

export default withFirebase(WorkReport)

import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle
} from "reactstrap";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import DemonstrationData from '../../components/DemonstrationData'
function newDateString(days) {
  return moment().add(days, 'd').format('DD/MM/YYYY HH:mm');
}
function randomMessageFactor() {
  return Math.round(Math.random() * 1.6)
};
function randomFollowerFactor(init) {
  return init + Math.round(Math.random() * 1)
};

function getNetworkChart(init) {
  let fInit = init
  return {
    data: {
      labels: Array(30).fill(0).map((e, i) => newDateString(i)),
      datasets: [
        {
          type: 'bar',
          label: 'Posts',
          backgroundColor: '#55acee',
          borderColor: '#55acee',
          yAxisID: 'messages',
          data: Array(30).fill(0).map((e, i) => randomMessageFactor()),
        },
        {
          type: 'line',
          label: 'Followers',
          backgroundColor: '#3b5998',
          borderColor: '#3b5998',
          fill: false,
          yAxisID: 'followers',
          data: Array(30).fill(0).map((e, i) => { fInit = randomFollowerFactor(fInit); return fInit }),
        }
      ]
    },
    options: {
      title: {
        text: 'Chart.js Combo Time Scale'
      },
      scales: {
        xAxes: [{
          type: 'time',
          display: true,
          time: {
            format: 'DD/MM/YYYY HH:mm',
            // round: 'day'
          }
        }],
        yAxes: [{
          type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
          display: true,
          position: 'left',
          id: 'followers',
        }, {
          type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
          display: true,
          position: 'right',
          id: 'messages',
          ticks: {
            stepSize: 1,
            suggestedMax: 3
          }
        }]
      },
    }
  }
}

class NetworkTimeline extends React.Component {

  render() {
    const networkChart = getNetworkChart(this.props.followers)
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{this.props.network}<DemonstrationData plan={this.props.plan}/></CardTitle>
          <p className="card-category"></p>
        </CardHeader>
        <CardBody>
          <Bar
            data={networkChart.data}
            options={networkChart.options}
            width={400}
            height={180}
          />
        </CardBody>
      </Card>
    )
  }
}

export default NetworkTimeline
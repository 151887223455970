/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// react plugin for creating vector maps
// import { VectorMap } from "react-jvectormap";
import { withFirebase } from '../components/Firebase'
import Members from './charts/memberstats/Members'
import { getTeamRanking } from "variables/mockup-data"
import DemonstrationData from '../views/components/DemonstrationData'
import TeamSelectDemo from '../views/components/TeamSelectDemo'

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// colors = ['#006600', '#009900', '#00CC00', '#00FF00', '#66FF66']


class Teamsdashboard extends React.Component {
  constructor(props) {
    super(props);
    this.timeChange = this.timeChange.bind(this)
    this.updateData = this.updateData.bind(this)
    let defaultSlot = this.getTimeSlots()[0]
    this.state = {
      timeslot: defaultSlot.value,
      rankings: {},
      teamdata: null
    }
  }
  /*
    groupBy = (objectArray, property) => {
      return objectArray.reduce(function (acc, obj) {
        let key = obj[property]
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
    }
  */
  /*
    getSums = (docs, leaf, attrs, doSum, max) => {
      let _default = {}
      for (let i = 0; i < attrs.length; i++) {
        _default[attrs[i]] = 0
      }
      //    let _male = docs.filter((d) => { return d.data()[leaf] && (d.data().gender === 'M') } ).map(d => { return d.data()[leaf]} ).reduce((p, c) => {
      let _male = docs.filter((d) => { return d[leaf] && (d.gender === 'M') }).map(d => { return d[leaf] }).reduce((p, c) => {
        let _k = {}
        for (let i = 0; i < attrs.length; i++) {
          if (max) {
            if (c[attrs[i]] > p[attrs[i]]) {
              _k[attrs[i]] = c[attrs[i]]
            } else {
              _k[attrs[i]] = p[attrs[i]]
            }
          } else {
            _k[attrs[i]] = p[attrs[i]] + c[attrs[i]]
          }
        }
        return _k
      }, _default)
      let _female = docs.filter((d) => { return d[leaf] && (d.gender === 'L') }).map(d => d[leaf]).reduce((p, c) => {
        //      let _female = docs.filter((d) => { return d.data()[leaf] && (d.data().gender === 'L') } ).map(d => d.data()[leaf]).reduce((p, c) => {
        let _k = {}
        for (let i = 0; i < attrs.length; i++) {
          if (max) {
            if (c[attrs[i]] > p[attrs[i]]) {
              _k[attrs[i]] = c[attrs[i]]
            } else {
              _k[attrs[i]] = p[attrs[i]]
            }
          } else {
            _k[attrs[i]] = p[attrs[i]] + c[attrs[i]]
          }
        }
        return _k
      }, _default)
      if (doSum) {
        let _sum = [_male, _female].reduce((p, c) => {
          let _k = {}
          for (let i = 0; i < attrs.length; i++) {
            _k[attrs[i]] = p[attrs[i]] + c[attrs[i]]
          }
          return _k
        })
        let _a = Object.keys(_sum).map(key => _sum[key]).reduce((p, c) => c + p)
        return { M: _male, F: _female, A: _a }
      }
      return { M: _male, F: _female }
    }
  
    getMembers = (docs) => {
      console.log(docs)
      let _male = docs.filter((d) => { return d.members && (d.gender === 'M') }).map(d => d.members).reduce((p, c) => {
        //      let _male = docs.filter((d) => { return d.data().members && (d.data().gender === 'M') } ).map(d => d.data().members).reduce((p, c) => {
        return {
          adults: p.adults + c.adults,
          juniors: p.juniors + c.juniors
        }
      }, { adults: 0, juniors: 0 })
      _male.a = _male.adults
      _male.j = _male.juniors
      console.log(_male)
      let _female = docs.filter((d) => { return d.members && (d.gender === 'L') }).map(d => d.members).reduce((p, c) => {
        //      let _female = docs.filter((d) => { return d.data().members && (d.data().gender === 'L') } ).map(d => d.data().members).reduce((p, c) => {
        return {
          adults: p.adults + c.adults,
          juniors: p.juniors + c.juniors
        }
      }, { adults: 0, juniors: 0 })
      _female.a = _female.adults
      _female.j = _female.juniors
  
      let _sum = [_male, _female].reduce((p, c) => { return { a: p.a + c.a, j: p.j + c.j } })
      console.log(_female)
      return { M: _male, F: _female, S: _sum, A: (_sum.a + _sum.j) }
    }
  
    getLicenseOwners = (docs) => {
      let _male = docs.filter((d) => { return d.licenses && (d.gender === 'M') }).map(d => d.licenses).reduce((p, c) => {
        //      let _male = docs.filter((d) => { return d.data().licenses && (d.data().gender === 'M') } ).map(d => d.data().licenses).reduce((p, c) => {
        return {
          adults: p.adults + c.adults,
          onetimeyte: p.onetimeyte + c.onetimeyte,
          seniors: p.seniors + c.seniors,
          u10: p.u10 + c.u10,
          u18: p.u18 + c.u18
        }
      }, this.getDefaultLisences().M)
      let _female = docs.filter((d) => { return d.licenses && (d.gender === 'L') }).map(d => d.licenses).reduce((p, c) => {
        //      let _female = docs.filter((d) => { return d.data().licenses && (d.data().gender === 'L') } ).map(d => d.data().licenses).reduce((p, c) => {
        return {
          adults: p.adults + c.adults,
          onetimeyte: p.onetimeyte + c.onetimeyte,
          seniors: p.seniors + c.seniors,
          u10: p.u10 + c.u10,
          u18: p.u18 + c.u18
        }
      }, this.getDefaultLisences().F)
      let _sum = [_male, _female].reduce((p, c) => {
        return {
          adults: p.adults + c.adults,
          onetimeyte: p.onetimeyte + c.onetimeyte,
          seniors: p.seniors + c.seniors,
          u10: p.u10 + c.u10,
          u18: p.u18 + c.u18
        }
      }, this.getDefaultLisences().S)
      let _a = Object.keys(_sum).map(key => _sum[key]).reduce((p, c) => c + p)
      return { M: _male, F: _female, S: _sum, A: _a }
    }
  */

  async updateData(timeslot) {
    let times = timeslot.split(';')
    let members = await this.props.firebase.membersCount(this.props.profile.clubdata.tswClubid, times[0], times[1])
    let _rtdbSnapshotMembers = members.val()
    let docs = Object.keys(_rtdbSnapshotMembers).map(key => _rtdbSnapshotMembers[key]).filter(m => m.tswclubid === this.props.profile.clubdata.tswClubid)
    // let data = await members.data()
    // let docs = await members.docs

    let topFours = await this.props.firebase.topFours(times[0], times[1])
    // let data = await members.data()
    let _rtdbSnapshotTopFours = topFours.val()
    if (!_rtdbSnapshotTopFours) _rtdbSnapshotTopFours = []
    let tops = Object.keys(_rtdbSnapshotTopFours).map(key => _rtdbSnapshotTopFours[key]).filter(m => !!m.rankings) // await topFours.docs
    let _lics = Object.keys(_rtdbSnapshotMembers).map(key => _rtdbSnapshotMembers[key]).filter(m => !!m.licenses)
    let _mems = Object.keys(_rtdbSnapshotMembers).map(key => _rtdbSnapshotMembers[key]).filter(m => !!m.members)

    // let tops = _tops.map((d) => { return d.data() } )
    let _rankings = tops.map((d) => { let i = {}; i.name = d.name; i.topfour = d.rankings.topfour; i.threeoffour = d.rankings.threeoffour; i.gender = d.gender; return i })
    let _licenses = _lics.map((d) => { let i = {}; i.name = d.name; i.adults = d.licenses.adults; i.juniors = d.licenses.u18 + d.licenses.u10; i.gender = d.gender; return i })
    let _members = _mems.map((d) => { let i = {}; i.name = d.name; i.adults = d.members.adults; i.juniors = d.members.juniors; i.gender = d.gender; return i })

    this.setState({
      members: this.getMembers(docs),
      licenses: this.getLicenseOwners(docs),
      rankings: this.getSums(docs, 'rankings', ['threeoffour', 'topfour'], false, true),
      ratings: this.getSums(docs, 'ratings', ['A1', 'A2', 'A3', 'B1', 'B2', 'B3', 'C1', 'C2', 'C3', 'D1', 'D2', 'E1',], false, true),
      clubRankings: _rankings,
      clubLicenses: _licenses,
      clubMembers: _members,
      timeslot: timeslot
    })
    /*
    for (let doc of docs) {
      console.log(`Document found : ${JSON.stringify(doc)}`)
    }
    */
  }

  timeChange(value) {
    if (this.state.timeslot === value.value) {
      console.log('do nothing')
    } else {
      console.log('update')
      // this.updateData(value.value)
    }
  }

  componentDidMount() {
    // this.updateData(this.state.timeslot)
    this.randomiseData()
  }


  randomiseData = (value) => {
    let _f = this.getChart()
    this.setState({ teamdata: _f })
  }

  getChartColors = () => ["#0b8523", "#a3a3a3"]
  getChartDash = () => [[], [5,5]]

  getChart = () => {
    let chartData = {
      data: {
        labels: [],
        datasets: []
      },
      options: {
        legend: {
          display: true
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#9f9f9f",
                fontStyle: "bold",
                suggestedMin: 1,
                // maxTicksLimit: 2,
                // padding: 1,
                reverse: true,
                max: 20,
                min: 1,
                stepSize: 2
              },
              gridLines: {
                zeroLineColor: "transparent",
                display: true,
                drawBorder: false,
                color: "#9f9f9f"
              }
            }
          ],
          xAxes: [
            {
              stacked: true,
              barPercentage: 0.8,
              gridLines: {
                zeroLineColor: "white",
                display: false,

                drawBorder: false,
                color: "transparent"
              },
              ticks: {
                padding: 2,
                fontColor: "#9f9f9f",
                fontStyle: "bold"
              }
            }
          ]
        }
      }
    }
    let mock = null
    mock = getTeamRanking()
    for (let i = 0; i < mock.datas.length; i++) {
      let ds = {
        label: mock.titles[i],
        borderColor: this.getChartColors()[i],
        fill: false,
        backgroundColor: this.getChartColors()[i],
        hoverBorderColor: this.getChartColors()[i],
        borderDash: this.getChartDash()[i],
        borderWidth: 2,
        data: mock.datas[i],
        stacked: false,
        lineTension: 0
      }
      /*
      if(i === 1) {
        ds.fill = '-1'
      }
      */
      chartData.data.datasets.push(ds)
    }
    chartData.data.labels = mock.labels

    return chartData
  }



  getTimeSlots() {
    const slot = new Date()
    let options = []
    let y = slot.getFullYear()
    for (let m = slot.getMonth() + 1; m > -1; m--) {
      if (m === 9) options.push({ value: y + '-09-01;' + y + '-12-31', label: '09-12 ' + y })
      if (m === 5) options.push({ value: y + '-05-01;' + y + '-08-31', label: '05-08 ' + y })
      if (m === 1) options.push({ value: y + '-01-01;' + y + '-04-30', label: '01-04 ' + y })
    }
    for (let k = y - 1; k > y - 2; k--) {
      options.push({ value: k + '-09-01;' + k + '-12-31', label: '09-12 ' + k })
      options.push({ value: k + '-05-01;' + k + '-08-31', label: '05-08 ' + k })
      options.push({ value: k + '-01-01;' + k + '-04-30', label: '01-04 ' + k })
    }
    return options
  }

  render() {
    // const tss = this.getTimeSlots()
    // const { timeValue } = this.state.timeslot
    if (this.props.summary) {
      return (<><Members members={this.state.members} /></>)
    } else {
      if (this.props.profile.customer.federations[0] === 'tennisfin') {
        return (
          <>
            <div className="content">
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      Tulossa sarjajoukkue tilastot.
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        );
      } else { // salibandy
        let _badgeSize = 'normal'
        if (this.state.teamdata) {
          return (
            <>
              <div className="content">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col lg="5" md="5" sm="3"><CardTitle tag="h5">Joukkue ranking <small className="headerupdate">{this.state.updated}</small></CardTitle></Col>
                          <Col lg="5" md="5" sm="2"><TeamSelectDemo identity={this.props.profile.customer.identity} update={this.randomiseData.bind(this)} /></Col>
                          <Col lg="2" md="1" sm="1"><DemonstrationData plan={this.props.profile.customer.plan} size={_badgeSize} /></Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Line
                          data={this.state.teamdata.data}
                          options={this.state.teamdata.options}
                          width={400}
                          height={180}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </>
          )
        } else {
          return (<Card>
            <CardHeader>
              <CardTitle tag="h5">Joukkueiden ranking</CardTitle>
              <p className="card-category"></p>
            </CardHeader>
            <CardBody>loading ... </CardBody></Card>)
        }
      }
    }
  }
}

export default withFirebase(Teamsdashboard)
import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table
} from "reactstrap";

class Members extends React.Component {

  render() {
    return (
      <Card className="card-stats">
        <CardBody>
        <Row>
        <Col lg="2" md="2" xs="2">
            <div className="icon-big text-center icon-warning">
              <i className="nc-icon nc-favourite-28 text-warning" />
            </div>
          </Col>
          <Col lg="10" md="10" xs="10">
          <div className="numbers">
                  <p className="card-category">Jäseniä</p>
                  <CardTitle tag="p">{this.props.members.A}</CardTitle>
                  <p />
                  </div>
            </Col>
          </Row>
          <Row>
            <Col lg="1" md="1" xs="1"></Col>
            <Col lg="11" md="11" xs="11">
          <Table responsive borderless size="sm">
            <thead>
              <tr>
                <th></th>
                <th><h5>Miehet</h5></th>
                <th><h5>Naiset</h5></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><h5>Aikuiset</h5></td>
                <td><h5>{this.props.members.M.a}</h5></td>
                <td><h5>{this.props.members.F.a}</h5></td>
              </tr>
              <tr>
                <td><h5>Juniorit</h5></td>
                <td><h5>{this.props.members.M.j}</h5></td>
                <td><h5>{this.props.members.F.j}</h5></td>
              </tr>
            </tbody>
          </Table>
          </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}

export default Members
/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// react component for creating dynamic tables
import { withFirebase } from '../../components/Firebase'
import ReactTable from 'react-table'
// import Switch from "react-bootstrap-switch";
import moment from 'moment'
import 'moment/locale/fi'
import DemonstrationData from '../components/DemonstrationData'
import DemoAlert from '../components/DemoAlert'
import equal from 'fast-deep-equal'

// reactstrap components
import { Button, Card, CardBody, ButtonGroup, Row, Col,  Modal,
  ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { getWorkData } from 'variables/mockup-data'
import { Divider } from '@material-ui/core'

class TravelExpensesAcceptance extends React.Component {
  constructor(props) {
    super(props)
    this.reactTable = React.createRef()
    // this.handleSwitch = this.handleSwitch.bind(this)
    this.state = {
      data: null,
      loading: true,
      expenses: [],
      empNames: [],
      statusFilter: 'nothandled',
      filtered: [],
      selectedExpense: null,
      expensemodalstate: false
    }
  }

  getDemoData = () => {
    const _data = getWorkData()
    this.setState({ data: _data }, this.filterData)
  }

  updateFilter = (status) => {
    this.setState({ statusFilter: status }, this.filterData)
  }

  handleClick(elem, state, docid) {
    if (this.props.profile.customer.plan !== 'free') {
      this.props.firebase
        .acceptTravelExpenses(docid)
        .set({ status: state }, { merge: true })
        .then((data) => {
          console.log(data)
        })
        .catch((error) => {
          alert(error.message)
        })
    } else {
      let _hs = [...this.state.data]
      let _hi = _hs.findIndex((h) => h.id === docid)
      let _h = { ..._hs[_hi] }
      _h.status = state
      _hs[_hi] = _h
      this.setState({ data: _hs }, this.filterData)
    }
  }

  handlePayment(elem, state, docid) {
    if (this.props.profile.customer.plan !== 'free') {
      this.props.firebase
        .acceptTravelExpenses(docid)
        .set({ payment: state }, { merge: true })
        .then((data) => {
          console.log(data)
        })
        .catch((error) => {
          alert(error.message)
        })
    } else {
      let _hs = [...this.state.data]
      let _hi = _hs.findIndex((h) => h.id === docid)
      let _h = { ..._hs[_hi] }
      _h.payment = state
      _hs[_hi] = _h
      this.setState({ data: _hs }, this.filterData)
    }
  }

  filterData = () => {
    if (this.state.data) {
      let data = this.state.data
      let empNames = []
      let _tExpenses = []
      let expenses = []
      for (let i = 0; i < data.length; i++) {
        let rawData = data[i]
        // TODO: import yhteensopivuus trackerin kanssa
        if (rawData.start) rawData.starttime = { seconds: moment(rawData.start).unix() }
        if (rawData.end) rawData.endtime = { seconds: moment(rawData.end).unix() }
        if (rawData.rows) {
          rawData.sum = rawData.rows.reduce((acc, row) => acc += parseFloat(row.sum), 0)
        }
        if (this.state.statusFilter === 'nothandled') {
          if (!rawData.status) {
            // only to be accepted selected
            _tExpenses.push(rawData)
          }
        } else if (this.state.statusFilter === 'accepted') {
          if (rawData.status === 'accepted') {
            // only to be accepted selected
            _tExpenses.push(rawData)
          }
        } else if (this.state.statusFilter === 'rejected') {
          if (rawData.status === 'rejected') {
            // only to be accepted selected
            _tExpenses.push(rawData)
          }
        } else if (this.state.statusFilter === 'notpaid') {
          if (rawData.status === 'accepted' && rawData.payment !== 'done') {
            // only to be accepted selected
            _tExpenses.push(rawData)
          }
        } else {
          _tExpenses.push(rawData)
        }
      }
      for (let k = 0; k < _tExpenses.length; k++) {
        if (_tExpenses[k].locked) {
          expenses.push({
            ..._tExpenses[k],
            actions: (
              <>
                <div className='actions-right'>Lukittu</div>
              </>
            ),
          })
        } else {
          if (
            this.props.profile.userprofile.role &&
            this.props.profile.userprofile.role === 'ADMIN'
          ) {
            expenses.push({
              ..._tExpenses[k],
              details: (
                <Button
                color='success'
                size='sm'
                onClick={(el) => {
                  let obj = this.state.expenses.find(
                    (o) => o.id === _tExpenses[k].id
                  )
                  console.log(obj)
                  this.setState({ selectedExpense: obj }, this.toggleExpenseModal)
                  // this.toggleModal(el, 'accepted', obj.id)
                }}
              > {'Näytä lasku'}
              </Button>
              ),
              actions: (
                <>
                  <div className='actions-right'>
                    {}
                    <Button
                      disabled={_tExpenses[k].status === 'accepted'}
                      color='success'
                      size='sm'
                      onClick={(el) => {
                        let obj = this.state.expenses.find(
                          (o) => o.id === _tExpenses[k].id
                        )
                        this.handleClick(el, 'accepted', obj.id)
                      }}
                    >
                      <span className='btn-label'>
                        <i className='nc-icon nc-check-2' />
                      </span>
                    </Button>
                    <Button
                      disabled={_tExpenses[k].status === 'rejected'}
                      color='danger'
                      size='sm'
                      onClick={(el) => {
                        let obj = this.state.expenses.find(
                          (o) => o.id === _tExpenses[k].id
                        )
                        this.handleClick(el, 'rejected', obj.id)
                      }}
                    >
                      <span className='btn-label'>
                        <i className='nc-icon nc-simple-remove' />
                      </span>
                    </Button>
                    {}
                    {''}
                  </div>
                </>
              ),
              paymentactions: (
                <>
                  <div className='actions-right'>
                    {}
                    <Button
                      disabled={_tExpenses[k].payment === 'done'}
                      color='success'
                      size='sm'
                      onClick={(el) => {
                        let obj = this.state.expenses.find(
                          (o) => o.id === _tExpenses[k].id
                        )
                        this.handlePayment(el, 'done', obj.id)
                      }}
                    >
                      <span className='btn-label'>
                        <i className='nc-icon nc-check-2' />
                      </span>
                    </Button>
                    <Button
                      disabled={_tExpenses[k].payment === 'rejected'}
                      color='danger'
                      size='sm'
                      onClick={(el) => {
                        let obj = this.state.expenses.find(
                          (o) => o.id === _tExpenses[k].id
                        )
                        this.handlePayment(el, 'rejected', obj.id)
                      }}
                    >
                      <span className='btn-label'>
                        <i className='nc-icon nc-simple-remove' />
                      </span>
                    </Button>
                    {}
                    {''}
                  </div>
                </>
              ),
            })
          } else {
            expenses.push({
              ..._tExpenses[k],
              actions: (
                <>
                  <div className='actions-right'>
                    {}
                    <Button
                      disabled={_tExpenses[k].status === 'accepted'}
                      color='success'
                      size='sm'
                      onClick={(el) => {
                        let obj = this.state.expenses.find(
                          (o) => o.id === _tExpenses[k].id
                        )
                        this.handleClick(el, 'accepted', obj.id)
                      }}
                    >
                      <span className='btn-label'>
                        <i className='nc-icon nc-check-2' />
                      </span>
                    </Button>
                    <Button
                      disabled={_tExpenses[k].status === 'rejected'}
                      color='danger'
                      size='sm'
                      onClick={(el) => {
                        let obj = this.state.expenses.find(
                          (o) => o.id === _tExpenses[k].id
                        )
                        this.handleClick(el, 'rejected', obj.id)
                      }}
                    >
                      <span className='btn-label'>
                        <i className='nc-icon nc-simple-remove' />
                      </span>
                    </Button>
                    {}
                    {''}
                  </div>
                </>
              ),
            })
          }
        }
        // console.log(_tHours[k])
        if (empNames.indexOf(_tExpenses[k].employee) === -1) {
          if(_tExpenses[k].employee && _tExpenses[k].employee.length > 1) {
            empNames.push(_tExpenses[k].employee)
          }
        }
      }
      this.setState({
        expenses,
        loading: false,
        empNames: empNames,
      })
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    if (this.props.profile.customer.plan !== 'free') {
      if(this.props.employeedata) {
        this.setState({ data: this.props.employeedata, employee: this.props.employee }, this.filterData)
      } else {
      this.unsubscribe = this.props.firebase
        .travelexpenses()
        .onSnapshot((snapshot) => {
          let rawData = []
          snapshot.forEach((doc) => {
            let _d = { ...doc.data(), id: doc.id }
            rawData.push(_d)
          })
          this.setState({ data: rawData }, this.filterData)
        })
      }
    } else {
      this.getDemoData()
    }
  }
  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.employeedata) {
      if(!equal(this.props.employeedata, prevProps.employeedata)) {
        this.setState({ data: this.props.employeedata }, this.filterData)
      }
    }
  }

  onTableFilterChange = () => {
    const current = this.reactTable.current
    const allData = current.getResolvedState().sortedData
    this.setState( { filtered: allData })
  }

  getColumns = () => {
    let isAdmin = false
    if (
      this.props.profile.userprofile.role &&
      this.props.profile.userprofile.role === 'ADMIN'
    ) isAdmin = true

    let _cols =
    [
      {
        Header: 'Työntekijä',
        accessor: 'employee',
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) =>
              onChange(event.target.value)
            }
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value=''>Kaikki</option>
            {this.state.empNames.map((n) => (
              <option key={n} value={n}>
                {n}
              </option>
            ))}
          </select>
        ),
      },
      {
        id: 'starttime',
        Header: 'Alkuaika',
        // render: rowInfo => <span>{rowInfo.row.firstName} - {rowInfo.row.lastName}</span>,
        // render: ({hour}) => <span>{hour.starttime.toDate().toLocaleDateString("fi-FI", dateoptions)}</span>,
        accessor: (row) =>
          moment
            .unix(row.starttime.seconds)
            .format('dd DD.MM.YY HH:mm'),
        sortMethod: (a, b) => {
          var a1 = moment(a, 'dd DD.MM.YY HH:mm')
          var b1 = moment(b, 'dd DD.MM.YY HH:mm')
          if (a1 < b1) return 1
          else if (a1 > b1) return -1
          else return 0
        },
      },
      {
        id: 'endtime',
        Header: 'Loppuaika',
        // render: rowInfo => <span>{rowInfo.row.firstName} - {rowInfo.row.lastName}</span>,
        // render: ({hour}) => <span>{hour.starttime.toDate().toLocaleDateString("fi-FI", dateoptions)}</span>,
        accessor: (row) =>
          moment
            .unix(row.endtime.seconds)
            .format('dd DD.MM.YY HH:mm'),
        sortMethod: (a, b) => {
          var a1 = moment(a, 'dd DD.MM.YY HH:mm')
          var b1 = moment(b, 'dd DD.MM.YY HH:mm')
          if (a1 < b1) return 1
          else if (a1 > b1) return -1
          else return 0
        },
      },
      {
        Header: 'Tarkoitus',
        accessor: 'purpose',
      },
      {
        Header: 'Kotimaa päivä',
        accessor: 'homelandfull',
        maxWidth: 30,
      },
      {
        Header: 'Kotimaa ½päivä',
        accessor: 'homelandhalf',
        maxWidth: 30,
      },
      {
        Header: 'Ulkomaan päivä',
        accessor: 'abroadfull',
        maxWidth: 30,
      },
      {
        Header: 'Ulkomaan ½päivä',
        accessor: 'abroadhalf',
        maxWidth: 30,
        },
      {
        Header: 'KM',
        accessor: 'km',
        maxWidth: 50,
        },
        { 
          Header: 'Kulut',
          accessor: 'sum',
          maxWidth: 50,
      },
      {
        Header: 'Hyväksyntä',
        accessor: 'status',
      },
      {
        Header: 'Maksuntila',
        accessor: 'payment',
      },
      {
        Header: 'Lasku',
        accessor: 'details',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Hyväksy',
        accessor: 'actions',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Maksa',
        accessor: 'paymentactions',
        sortable: false,
        filterable: false,
      },
    ]
    if (!isAdmin) {
      _cols.splice((_cols.length-1), 1)
    }
    if (this.props.personal) {
      _cols.splice(0, 1)
      delete _cols[3].Footer
    }
    return _cols
  }

  toggleExpenseModal = () => {
    this.setState({ expensemodalstate: !this.state.expensemodalstate })
  }


  expenseModal = () => {
    const { km, route, rows, purpose, start, end, homelandfull, homelandhalf, abroadfull, abroadhalf } = this.state.selectedExpense
    return (
      <div>
        <Modal
          isOpen={this.state.expensemodalstate}
          toggle={this.toggleExpenseModal}
        >
          <ModalHeader style={{ display: 'flex' }}>
            {purpose}
          </ModalHeader>
          <ModalBody>
            <Row><Col><strong>{moment(start).format('DD.MM.YY HH:mm')} - {moment(end).format('DD.MM.YY HH:mm')}</strong></Col></Row>
            <Divider />
            <Row><Col>Km</Col><Col>{km}</Col><Col>{route}</Col></Row>
            <Divider />
            {homelandhalf ? <Row><Col>Kotimaan puolipäiväraha</Col><Col>{homelandhalf}</Col></Row> : null}
            {homelandfull ? <Row><Col>Kotimaan kokopäiväraha</Col><Col>{homelandfull}</Col></Row> : null}
            {abroadhalf ? <Row><Col>Ulkomaan puolipäiväraha</Col><Col>{abroadhalf}</Col></Row> : null}
            {abroadfull ? <Row><Col>Ulkomaan kokopäiväraha</Col><Col>{abroadfull}</Col></Row> : null}
            {rows ? <><Divider/><Row><Col>Kulu</Col><Col>Summa</Col></Row></> : null}
            {rows ? rows.map((row, i) => (
              <Row key={i}><Col>{row.description}</Col><Col>{row.sum} €</Col></Row>
            )): null}
          </ModalBody>
          <ModalFooter>
          <Button color="secondary" onClick={this.toggleExpenseModal} style={{ marginRight: '10px' }}>Close</Button>{' '}
        </ModalFooter>
        </Modal>
      </div>
    )
  }


  render() {
    // const { hours, loading } = this.state;
    moment.locale('fi')
    return (
      <>
        <div className='content'>
          <DemoAlert demolocation='workacceptance' {...this.props} />
          <Row>
            <Col md='12'>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg='3' md='3'>
                      <h4 style={{ marginTop: '0' }}>{(this.props.employee ? this.props.employee.firstname + ' ' + this.props.employee.lastname : 'Matkalaskujen hyväksyntä')}</h4>
                    </Col>
                    <Col lg='9' md='9'>
                      <ButtonGroup className='flex-wrap'>
                        <Button
                          className='btn-round'
                          color='info'
                          outline
                          type='button'
                          onClick={() => this.updateFilter('nothandled')}
                          active={this.state.statusFilter === 'nothandled'}
                        >
                          Hyväksyttävät
                        </Button>
                        <Button
                          className='btn-round'
                          color='info'
                          outline
                          type='button'
                          onClick={() => this.updateFilter('notpaid')}
                          active={this.state.statusFilter === 'notpaid'}
                        >
                          Maksettavat
                        </Button>
                        <Button
                          className='btn-round'
                          color='info'
                          outline
                          type='button'
                          onClick={() => this.updateFilter('accepted')}
                          active={this.state.statusFilter === 'accepted'}
                        >
                          Hyväksytyt
                        </Button>
                        <Button
                          className='btn-round'
                          color='info'
                          outline
                          type='button'
                          onClick={() => this.updateFilter('rejected')}
                          active={this.state.statusFilter === 'rejected'}
                        >
                          Hylätyt
                        </Button>
                        <Button
                          className='btn-round'
                          color='info'
                          outline
                          type='button'
                          onClick={() => this.updateFilter('all')}
                          active={this.state.statusFilter === 'all'}
                        >
                          Kaikki
                        </Button>
                      </ButtonGroup>
                      <DemonstrationData
                        plan={this.props.profile.customer.plan}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ReactTable
                        data={this.state.expenses}
                        filterable
                        ref={this.reactTable}
                        onFilteredChange={this.onTableFilterChange}
                        columns={this.getColumns()}
                        defaultPageSize={5}
                        showPaginationTop
                        showPaginationBottom={false}
                        /*
                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                        */
                        className='-striped -highlight primary-pagination'
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.selectedExpense ? this.expenseModal() : <></>}
        </div>
      </>
    )
  }
}

export default withFirebase(TravelExpensesAcceptance)

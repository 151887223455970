import React from "react";
import DemonstrationData from '../components/DemonstrationData'
import moment from "moment";
import { Bar } from "react-chartjs-2";
import equal from 'fast-deep-equal'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  //  CardFooter,
  //  Table,
  //  Row,
  //  Col
} from "reactstrap";

class HoursChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
  }


  newDateString = (days) => {
    return moment().add(days, 'd').format('DD/MM/YYYY');
  }
  randomHourFactor = () => {
    return Math.round(Math.random() * 8)
  }

  getUserChart = () => {
    // aggregate
    let values = {}
    const MAX = 31
    let day = moment().subtract(MAX, 'days')
    for (let i = 0; i < MAX; i++) {
      values[day.format('DD/MM/YYYY')] = { check: 0, accepted: 0, payment: 0, rejected: 0, unchecked: 0 }
      day.add(1,'days')
    }
    
    this.props.data.map((d) => {
      console.log(d.duration)
      if (!d.duration) {
        if (d.start && d.end) {
          d.duration = moment(d.end).diff(moment(d.start), 'hours', true)
        } else {
          d.duration = 0
        }
      }
      let date = moment(d.start).format('DD/MM/YYYY')
      if (!values[date]) values[date] = { check: 0, accepted: 0, rejected: 0, payment: 0, unchecked: 0  }
      if (d.payment === 'done') {
        values[date].payment = values[date].payment + d.duration
      } else if (d.status === 'accepted') {
        values[date].accepted = values[date].accepted + d.duration
      } else if (d.status === 'rejected') {
        values[date].rejected = values[date].rejected + d.duration
      } else if (d.checked) {
        values[date].check = values[date].check + d.duration
      } else {
        values[date].unchecked = values[date].unchecked + d.duration
      }
      return d
    })

    console.log(values)
    let _l = Object.keys(values)
    let _v = Object.values(values)
    let _check = _v.map((i) => i.check )
    let _accepted = _v.map((i) => i.accepted )
    let _rejected = _v.map((i) => i.rejected )
    let _payment = _v.map((i) => i.payment )
    let _unchecked = _v.map((i) => i.unchecked )

    return {
      data: {
        labels: _l,
        datasets: [
          {
            type: 'bar',
            label: 'Maksettu',
            backgroundColor: '#21ac07',
            borderColor: '#21ac07',
            yAxisID: 'hours',
            data: _payment,
          },
          {
            type: 'bar',
            label: 'Hyväksytty',
            backgroundColor: '#03fac9',
            borderColor: '#03fac9',
            yAxisID: 'hours',
            data: _accepted,
          },
          {
            type: 'bar',
            label: 'Kuitattu',
            backgroundColor: '#e424dc',
            borderColor: '#e424dc',
            yAxisID: 'hours',
            data: _check,
          },
          {
            type: 'bar',
            label: 'Kuittaamatta',
            backgroundColor: '#e2cfe1',
            borderColor: '#e2cfe1',
            yAxisID: 'hours',
            data: _unchecked,
          },
          {
            type: 'bar',
            label: 'Hylätty',
            backgroundColor: '#fa3503',
            borderColor: '#fa3503',
            yAxisID: 'hours',
            data: _rejected,
          }

        ]
      },
      options: {
        title: {
          text: ''
        },
        scales: {
          xAxes: [{
            type: 'time',
            display: true,
            time: {
              format: 'DD/MM/YYYY HH:mm',
              round: 'day'
            },
            stacked: true
          }],
          yAxes: [{
            type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
            display: true,
            position: 'right',
            id: 'hours',
            ticks: {
              stepSize: 0.5,
              suggestedMax: 8
            },
            stacked: true
          }]
        },
      }
    }

  }

  getDemoChart = (init) => {
    // let fInit = init
    return {
      data: {
        labels: Array(30).fill(0).map((e, i) => this.newDateString(i)),
        datasets: [
          {
            type: 'bar',
            label: 'Tunnit',
            backgroundColor: '#55acee',
            borderColor: '#55acee',
            yAxisID: 'hours',
            data: Array(30).fill(0).map((e, i) => this.randomHourFactor()),
          }
        ]
      },
      options: {
        title: {
          text: ''
        },
        scales: {
          xAxes: [{
            type: 'time',
            display: true,
            time: {
              format: 'DD/MM/YYYY HH:mm',
              // round: 'day'
            }
          }],
          yAxes: [{
            type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
            display: true,
            position: 'right',
            id: 'hours',
            ticks: {
              stepSize: 1,
              suggestedMax: 8
            }
          }]
        },
      }
    }
  }

  componentDidMount() {
    if (this.props.profile.customer.plan !== 'free') {
      let userData = this.getUserChart()
      this.setState({ chart: userData })
    } else {
      let randomData = this.getDemoChart()
      console.log(randomData)
      this.setState({ chart: randomData })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data) {
      if(!equal(this.props.data, prevProps.data)) {
        let userData = this.getUserChart()
        this.setState({ chart: userData })
      }
    }
  }


  render() {
    if (this.state.chart) {
      return (<>
        <Card>
          <CardHeader>
            <CardTitle tag="h5"><DemonstrationData plan={this.props.plan} /></CardTitle>
            <p className="card-category"></p>
          </CardHeader>
          <CardBody>
            <Bar
              data={this.state.chart.data}
              options={this.state.chart.options}
              width={400}
              height={100}
            />
          </CardBody>
        </Card>
      </>)
    } else {
      return (<Card>
        <CardHeader>
          <CardTitle tag="h5"></CardTitle>
          <p className="card-category"></p>
        </CardHeader>
        <CardBody>Loading ... </CardBody></Card>)
    }



  }
}
export default HoursChart;
/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// react component for creating dynamic tables
import { withFirebase } from '../../components/Firebase'
import ReactTable from 'react-table'
// import Switch from "react-bootstrap-switch";
import moment from 'moment'
import 'moment/locale/fi'
import DemonstrationData from '../components/DemonstrationData'
import DemoAlert from '../components/DemoAlert'
import equal from 'fast-deep-equal'
import { CSVLink } from "react-csv"
// reactstrap components
import { Button, Card, CardBody, ButtonGroup, Row, Col } from 'reactstrap'
import { getWorkData } from 'variables/mockup-data'

class WorkAcceptance extends React.Component {
  constructor(props) {
    super(props)
    this.reactTable = React.createRef()
    // this.handleSwitch = this.handleSwitch.bind(this)
    this.state = {
      data: null,
      loading: true,
      hours: [],
      empNames: [],
      statusFilter: 'nothandled',
      filtered: [],
      dataToDownload: []
    }
  }

  getDemoData = () => {
    const _data = getWorkData()
    this.setState({ data: _data }, this.filterData)
  }

  updateFilter = (status) => {
    this.setState({ statusFilter: status }, this.filterData)
  }

  handleClick(elem, state, docid) {
    if (this.props.profile.customer.plan !== 'free') {
      this.props.firebase
        .acceptHours(docid)
        .set({ status: state }, { merge: true })
        .then((data) => {
          console.log(data)
        })
        .catch((error) => {
          alert(error.message)
        })
    } else {
      let _hs = [...this.state.data]
      let _hi = _hs.findIndex((h) => h.id === docid)
      let _h = { ..._hs[_hi] }
      _h.status = state
      _hs[_hi] = _h
      this.setState({ data: _hs }, this.filterData)
    }
  }

  handlePayment(elem, state, docid) {
    if (this.props.profile.customer.plan !== 'free') {
      this.props.firebase
        .acceptHours(docid)
        .set({ payment: state }, { merge: true })
        .then((data) => {
          console.log(data)
        })
        .catch((error) => {
          alert(error.message)
        })
    } else {
      let _hs = [...this.state.data]
      let _hi = _hs.findIndex((h) => h.id === docid)
      let _h = { ..._hs[_hi] }
      _h.payment = state
      _hs[_hi] = _h
      this.setState({ data: _hs }, this.filterData)
    }
  }

  filterData = () => {
    if (this.state.data) {
      let data = this.state.data
      let empNames = []
      let _tHours = []
      let hours = []
      for (let i = 0; i < data.length; i++) {
        let rawData = data[i]
        // TODO: import yhteensopivuus trackerin kanssa
        if (rawData.start) rawData.starttime = { seconds: moment(rawData.start).unix() }
        if (rawData.end) rawData.endtime = { seconds: moment(rawData.end).unix() }
        if (rawData.task) rawData.description = rawData.task
        if (!rawData.duration) {
          if(rawData.start && rawData.end) {
            rawData.duration = moment(rawData.end).diff(moment(rawData.start), 'hours', true)
          } else {
            rawData.duration = 0
          }
        }
        if(rawData.checked) {
          rawData.check = 'Kuitattu'
        } else {
          if (rawData.away) {
            rawData.check = 'Poissa'
          } else {
            rawData.check = 'Ei kuitattu'
          }
        }

        if (this.state.statusFilter === 'nothandled') {
          if (!rawData.status && (rawData.checked || rawData.away)) {
            // only to be accepted selected
            _tHours.push(rawData)
          }
        } else if (this.state.statusFilter === 'nothandled-all') {
          if (!rawData.status) {
            // only to be accepted selected
            _tHours.push(rawData)
          }
        } else if (this.state.statusFilter === 'accepted') {
          if (rawData.status === 'accepted') {
            // only to be accepted selected
            _tHours.push(rawData)
          }
        } else if (this.state.statusFilter === 'rejected') {
          if (rawData.status === 'rejected') {
            // only to be accepted selected
            _tHours.push(rawData)
          }
        } else if (this.state.statusFilter === 'notpaid') {
          if (rawData.status === 'accepted' && rawData.payment !== 'done') {
            // only to be accepted selected
            _tHours.push(rawData)
          }
        } else {
          _tHours.push(rawData)
        }
      }
      for (let k = 0; k < _tHours.length; k++) {
        if (_tHours[k].locked) {
          hours.push({
            ..._tHours[k],
            actions: (
              <>
                <div className='actions-right'>Lukittu</div>
              </>
            ),
          })
        } else {
          if (
            this.props.profile.userprofile.role &&
            this.props.profile.userprofile.role === 'ADMIN'
          ) {
            hours.push({
              ..._tHours[k],
              actions: (
                <>
                  <div className='actions-right'>
                    {}
                    <Button
                      disabled={_tHours[k].status === 'accepted'}
                      color='success'
                      size='sm'
                      onClick={(el) => {
                        let obj = this.state.hours.find(
                          (o) => o.id === _tHours[k].id
                        )
                        this.handleClick(el, 'accepted', obj.id)
                      }}
                    >
                      <span className='btn-label'>
                        <i className='nc-icon nc-check-2' />
                      </span>
                    </Button>
                    <Button
                      disabled={_tHours[k].status === 'rejected'}
                      color='danger'
                      size='sm'
                      onClick={(el) => {
                        let obj = this.state.hours.find(
                          (o) => o.id === _tHours[k].id
                        )
                        this.handleClick(el, 'rejected', obj.id)
                      }}
                    >
                      <span className='btn-label'>
                        <i className='nc-icon nc-simple-remove' />
                      </span>
                    </Button>
                    {}
                    {''}
                  </div>
                </>
              ),
              paymentactions: (
                <>
                  <div className='actions-right'>
                    {}
                    <Button
                      disabled={_tHours[k].payment === 'done'}
                      color='success'
                      size='sm'
                      onClick={(el) => {
                        let obj = this.state.hours.find(
                          (o) => o.id === _tHours[k].id
                        )
                        this.handlePayment(el, 'done', obj.id)
                      }}
                    >
                      <span className='btn-label'>
                        <i className='nc-icon nc-check-2' />
                      </span>
                    </Button>
                    <Button
                      disabled={_tHours[k].payment === 'rejected'}
                      color='danger'
                      size='sm'
                      onClick={(el) => {
                        let obj = this.state.hours.find(
                          (o) => o.id === _tHours[k].id
                        )
                        this.handlePayment(el, 'rejected', obj.id)
                      }}
                    >
                      <span className='btn-label'>
                        <i className='nc-icon nc-simple-remove' />
                      </span>
                    </Button>
                    {}
                    {''}
                  </div>
                </>
              ),
            })
          } else {
            hours.push({
              ..._tHours[k],
              actions: (
                <>
                  <div className='actions-right'>
                    {}
                    <Button
                      disabled={_tHours[k].status === 'accepted'}
                      color='success'
                      size='sm'
                      onClick={(el) => {
                        let obj = this.state.hours.find(
                          (o) => o.id === _tHours[k].id
                        )
                        this.handleClick(el, 'accepted', obj.id)
                      }}
                    >
                      <span className='btn-label'>
                        <i className='nc-icon nc-check-2' />
                      </span>
                    </Button>
                    <Button
                      disabled={_tHours[k].status === 'rejected'}
                      color='danger'
                      size='sm'
                      onClick={(el) => {
                        let obj = this.state.hours.find(
                          (o) => o.id === _tHours[k].id
                        )
                        this.handleClick(el, 'rejected', obj.id)
                      }}
                    >
                      <span className='btn-label'>
                        <i className='nc-icon nc-simple-remove' />
                      </span>
                    </Button>
                    {}
                    {''}
                  </div>
                </>
              ),
            })
          }
        }
        // console.log(_tHours[k])
        if (empNames.indexOf(_tHours[k].employee) === -1) {
          if(_tHours[k].employee && _tHours[k].employee.length > 1) {
            empNames.push(_tHours[k].employee)
          }
        }
      }
      const dataToDownload = hours.map((x) => {
        const r = JSON.parse(JSON.stringify(x))
        delete r.actions
        delete r.log
        delete r.paymentactions
        r.endtime = moment.unix(x.endtime.seconds).format('DD.MM HH:mm') 
        r.starttime = moment.unix(x.starttime.seconds).format('DD.MM HH:mm') 
        return r
      })
      this.setState({
        hours: hours,
        loading: false,
        empNames: empNames,
        dataToDownload
      })
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    if (this.props.profile.customer.plan !== 'free') {
      if(this.props.employeedata) {
        this.setState({ data: this.props.employeedata, employee: this.props.employee }, this.filterData)
      } else {
      this.unsubscribe = this.props.firebase
        .hours()
        .onSnapshot((snapshot) => {
          let rawData = []
          snapshot.forEach((doc) => {
            let _d = { ...doc.data(), id: doc.id }
            rawData.push(_d)
          })
          this.setState({ data: rawData }, this.filterData)
        })
      }
    } else {
      this.getDemoData()
    }
  }
  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.employeedata) {
      if(!equal(this.props.employeedata, prevProps.employeedata)) {
        this.setState({ data: this.props.employeedata }, this.filterData)
      }
    }
  }

  onTableFilterChange = () => {
    const current = this.reactTable.current
    const allData = current.getResolvedState().sortedData
    this.setState( { filtered: allData })
  }

  getColumns = () => {
    let isAdmin = false
    if (
      this.props.profile.userprofile.role &&
      this.props.profile.userprofile.role === 'ADMIN'
    ) isAdmin = true

    let _cols =
    [
      {
        Header: 'Työntekijä',
        accessor: 'employee',
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) =>
              onChange(event.target.value)
            }
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value=''>Kaikki</option>
            {this.state.empNames.map((n) => (
              <option key={n} value={n}>
                {n}
              </option>
            ))}
          </select>
        ),
      },
      {
        id: 'starttime',
        Header: 'Alkuaika',
        // render: rowInfo => <span>{rowInfo.row.firstName} - {rowInfo.row.lastName}</span>,
        // render: ({hour}) => <span>{hour.starttime.toDate().toLocaleDateString("fi-FI", dateoptions)}</span>,
        accessor: (row) =>
          moment
            .unix(row.starttime.seconds)
            .format('DD.MM.YY HH:mm'),
        sortMethod: (a, b) => {
          var a1 = moment(a, 'DD.MM.YY HH:mm')
          var b1 = moment(b, 'DD.MM.YY HH:mm')
          if (a1 < b1) return 1
          else if (a1 > b1) return -1
          else return 0
        },
      },
      {
        id: 'endtime',
        Header: 'Loppuaika',
        // render: rowInfo => <span>{rowInfo.row.firstName} - {rowInfo.row.lastName}</span>,
        // render: ({hour}) => <span>{hour.starttime.toDate().toLocaleDateString("fi-FI", dateoptions)}</span>,
        accessor: (row) =>
          moment
            .unix(row.endtime.seconds)
            .format('DD.MM.YY HH:mm'),
        sortMethod: (a, b) => {
          var a1 = moment(a, 'DD.MM.YY HH:mm')
          var b1 = moment(b, 'DD.MM.YY HH:mm')
          if (a1 < b1) return 1
          else if (a1 > b1) return -1
          else return 0
        },
      },
      {
        Header: 'Kuvaus',
        accessor: 'description',
      },
      {
        Header: 'Tyyppi',
        accessor: 'type',
      },
      {
        Header: 'Viesti',
        accessor: 'message',
      },
      {
        Header: 'Kesto',
        accessor: 'duration',
        Footer: (
          <span>{
            // Get the total of the price
            this.state.filtered.reduce((total, { duration }) => total += duration, 0)
          }</span>
        )
      },
      {
        Header: 'Kuitattu',
        accessor: 'check',
      },
      {
        Header: 'Hyväksyntä',
        accessor: 'status',
      },
      {
        Header: 'Maksuntila',
        accessor: 'payment',
      },
      {
        Header: 'Hyväksy',
        accessor: 'actions',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Maksa',
        accessor: 'paymentactions',
        sortable: false,
        filterable: false,
      },
    ]
    if (!isAdmin) {
      _cols.splice((_cols.length-1), 1)
    }
    if (this.props.personal) {
      _cols.splice(0, 1)
      delete _cols[3].Footer
    }
    return _cols
  }


  render() {
    // const { hours, loading } = this.state;
    moment.locale('fi')
    return (
      <>
        <div className='content'>
          <DemoAlert demolocation='workacceptance' {...this.props} />
          <Row>
            <Col md='12'>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg='2' md='2'>
                      <h4 style={{ marginTop: '0' }}>{(this.props.employee ? this.props.employee.firstname + ' ' + this.props.employee.lastname : 'Työtuntien hyväksyntä')}</h4>
                    </Col>
                    <Col lg='8' md='8'>
                      <ButtonGroup className='flex-wrap'>
                        <Button
                          className='btn-round'
                          color='info'
                          outline
                          type='button'
                          onClick={() => this.updateFilter('nothandled')}
                          active={this.state.statusFilter === 'nothandled'}
                        >
                          Hyväksyttävät
                        </Button>
                        <Button
                          className='btn-round'
                          color='info'
                          outline
                          type='button'
                          onClick={() => this.updateFilter('nothandled-all')}
                          active={this.state.statusFilter === 'nothandled-all'}
                        >
                          Hyväksyttävät kaikki
                        </Button>
                        <Button
                          className='btn-round'
                          color='info'
                          outline
                          type='button'
                          onClick={() => this.updateFilter('notpaid')}
                          active={this.state.statusFilter === 'notpaid'}
                        >
                          Maksettavat
                        </Button>
                        <Button
                          className='btn-round'
                          color='info'
                          outline
                          type='button'
                          onClick={() => this.updateFilter('accepted')}
                          active={this.state.statusFilter === 'accepted'}
                        >
                          Hyväksytyt
                        </Button>
                        <Button
                          className='btn-round'
                          color='info'
                          outline
                          type='button'
                          onClick={() => this.updateFilter('rejected')}
                          active={this.state.statusFilter === 'rejected'}
                        >
                          Hylätyt
                        </Button>
                        <Button
                          className='btn-round'
                          color='info'
                          outline
                          type='button'
                          onClick={() => this.updateFilter('all')}
                          active={this.state.statusFilter === 'all'}
                        >
                          Kaikki
                        </Button>
                      </ButtonGroup>
                      <DemonstrationData
                        plan={this.props.profile.customer.plan}
                      />
                    </Col>
                    <Col lg='2' md='2'>
                    <CSVLink data={this.state.dataToDownload} filename="tunnit.csv"
                        className="hidden">Lataa näkymä exceliin</CSVLink>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ReactTable
                        data={this.state.hours}
                        filterable
                        ref={this.reactTable}
                        onFilteredChange={this.onTableFilterChange}
                        columns={this.getColumns()}
                        defaultPageSize={50}
                        showPaginationTop
                        showPaginationBottom={false}
                        /*
                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                        */
                        className='-striped -highlight primary-pagination'
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default withFirebase(WorkAcceptance)

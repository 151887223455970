/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import Widget from "./charts/communication/Widget"
import NetworkTimeline from "./charts/communication/NetworkTimeline"
import DemoAlert from './components/DemoAlert'

// reactstrap components
import {
  Row,
  Col
} from "reactstrap";

class CommunicationDash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectednetwork: 'twitter',
      followers: { twitter: 399, facebook: 500, instagram: 630, youtube: 440, mobile: 600 }
    };
  }

  onSelect = (network) => {
    this.setState({ selectednetwork: network })
  }

  render() {
    if (this.props.summary) {
      return (<>
        <Col md="3">
          <Widget followers={this.state.followers.twitter} posts={34} network="twitter" onSelect={() => this.onSelect('twitter')} />
        </Col>
        <Col md="3">
          <Widget followers={this.state.followers.facebook} posts={5} network="facebook" onSelect={() => this.onSelect('facebook')} />
        </Col>
        <Col md="3">
          <Widget followers={this.state.followers.instagram} posts={55} network="instagram" onSelect={() => this.onSelect('instagram')} />
        </Col>
        <Col md="3">
          <Widget followers={this.state.followers.mobile} posts={55} network="mobile" onSelect={() => this.onSelect('mobile')} />
        </Col>
      </>)
    } else {
      return (
        <>
          <div className="content">
          <DemoAlert demolocation="communication" {...this.props} />
            <Row>
              <Col md="2">
                <Widget followers={this.state.followers.twitter} posts={34} network="twitter" onSelect={() => this.onSelect('twitter')} />
              </Col>
              <Col md="2">
                <Widget followers={this.state.followers.facebook} posts={5} network="facebook" onSelect={() => this.onSelect('facebook')} />
              </Col>
              <Col md="2">
                <Widget followers={this.state.followers.instagram} posts={55} network="instagram" onSelect={() => this.onSelect('instagram')} />
              </Col>
              <Col md="2">
                <Widget followers={this.state.followers.youtube} posts={55} network="youtube" onSelect={() => this.onSelect('youtube')} />
              </Col>
              <Col md="2">
                <Widget followers={this.state.followers.mobile} posts={55} network="mobile" onSelect={() => this.onSelect('mobile')} />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <NetworkTimeline network={this.state.selectednetwork} followers={this.state.followers[this.state.selectednetwork]} plan={this.props.profile.customer.plan} />
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default CommunicationDash;

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react'
import NotificationAlert from 'react-notification-alert'

const iframeStyle = {
  border: '0px #ffffff none',
}

class EmceAcceptance extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fileurl: '',
      modalNotice: false,
      reload: false,
      addnewbutton: '',
    }
  }

  componentDidMount() {}

  demoNotify = () => {
    let options = {}
    options = {
      place: 'tr',
      message: (
        <div>
          <div>Tämä toiminto ei ole käytössä demo versiossa.</div>
        </div>
      ),
      type: 'warning',
      icon: 'now-ui-icons ui-1_bell-53',
      autoDismiss: 4,
    }
    this.refs.notificationAlert.notificationAlert(options)
  }

  render() {
    return (
      <>
        <NotificationAlert ref='notificationAlert' />
        <div className='content'>
          <iframe
            title='emcelogin'
            src='https://365.emce.fi/ui/login'
            style={iframeStyle}
            name='myiFrame'
            scrolling='yes'
            frameBorder='1'
            marginHeight='0px'
            marginWidth='0px'
            height='800px'
            width='100%'
          ></iframe>
        </div>
      </>
    )
  }
}

export default EmceAcceptance

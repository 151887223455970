import React from 'react'
// import DemoAlert from 'views/components/DemoAlert'
import moment from 'moment'
import 'moment/locale/fi'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
  Table,
} from 'reactstrap'
import NotificationAlert from 'react-notification-alert'
import ReactDatetime from 'react-datetime'

class Cources extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      courserows: [],
      loading: true,
      saved: true,
      coursesEdit: false,
      updated: false,
    }
  }

  componentDidMount() {
    if (this.props.profile.customer.plan !== 'free') {
      this.props.firebase
        .getEmployeeCourses(
          this.props.person.id
        )
        .onSnapshot((snapshot) => {
          let courserows = []
          snapshot.forEach((doc) => {
            let _d = { ...doc.data(), id: doc.id }
            courserows.push(_d)
          })
          this.setState({
            courserows: courserows,
            loading: false,
          })
        })
    } else {
      this.getDemoData()
    }
  }
  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  // onChange = (event) => {
  //   console.log(event)
  //   let index = Number.parseInt(event.props.name.split('.')[1])
  //   let _a = this.state.courserows
  //   _a[index].enabled = event.state.value
  //   this.setState({ courserows: _a, saved: false })
  // }

  setName = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.courserows
    _a[index].name = event.target.value
    this.setState({ courserows: _a, updated: false })
  }

  setGraduationday = (event, name) => {
    let index = Number.parseInt(name.split('.')[1])
    let _a = this.state.courserows
    let _s = event.format('YYYY-MM-DD')
    _a[index].graduationday = _s
    this.setState({ courserows: _a, updated: true })
  }

  setRenewalday = (event, name) => {
    let index = Number.parseInt(name.split('.')[1])
    let _a = this.state.courserows
    let _s = event.format('YYYY-MM-DD')
    _a[index].renewalday = _s
    this.setState({ courserows: _a, updated: true })
  }


  addCourseRow = () => {
    let j = this.state.courserows
    j.push({ empty: true, enabled: true })
    this.setState({ courserows: j })
  }

  onSave = () => {
    if (this.state.updated) {
      console.log('Save')
      let _rows = this.state.courserows.map((r) => {
        delete r.empty
        return r
      })
      console.log(_rows)
      this.props.firebase
        .addEmployeeCourses(
          this.props.person.id,
          _rows)
        .then((dataSnapshot) => {
          this.setState({ coursesEdit: !this.state.coursesEdit, updated: false })
        })
    } else {
      this.setState({ coursesEdit: !this.state.coursesEdit })
    }
  }

  courseEditrows = () => {
    if (this.state.courserows) {
      return this.state.courserows.map((row, index) => {
        let i = index
        return (
          <Row key={i} style={{ border: '1px solid rgba(0, 0, 0, 0.10)' }}>
            <Col sm='6' md='6' xs='6'>
              <FormGroup>
                <Label for={'label.' + i}>Nimi</Label>
                <Input
                  type='text'
                  name={'label.' + i}
                  id={'label.' + i}
                  onChange={this.setName}
                  onKeyDown={this.handleKeyDown}
                  value={
                    this.state.courserows[i].name
                      ? this.state.courserows[i].name
                      : ''
                  }
                />
              </FormGroup>
            </Col>
            <Col sm='3' md='3' xs='3'>
              <Label for={'graduationday.' + i}>Suoritettu</Label>
              <ReactDatetime
                name={'graduationday.' + i}
                value={
                  this.state.courserows[i].graduationday
                    ? moment(
                        this.state.courserows[i].graduationday,
                        'YYYY-MM-DD'
                      )
                    : undefined
                }
                className={
                  this.state.coursesEdit ? 'person-edit' : 'person-noedit'
                }
                onChange={(e) => this.setGraduationday(e, 'graduationday.' + i)}
                inputProps={{
                  className:
                    (this.state.coursesEdit ? 'person-edit' : 'person-noedit') +
                    ' form-control',
                  disabled: !this.state.coursesEdit,
                }}
                timeFormat={false}
              />
            </Col>
            <Col sm='3' md='3' xs='3'>
              <Label for={'renewalday.' + i}>Vanhenee</Label>
              <ReactDatetime
                name={'renewalday.' + i}
                value={
                  this.state.courserows[i].renewalday
                    ? moment(this.state.courserows[i].renewalday, 'YYYY-MM-DD')
                    : undefined
                }
                className={
                  this.state.coursesEdit ? 'person-edit' : 'person-noedit'
                }
                onChange={(e) => this.setRenewalday(e, 'renewalday.' + i)}
                inputProps={{
                  className:
                    (this.state.coursesEdit ? 'person-edit' : 'person-noedit') +
                    ' form-control',
                  disabled: !this.state.coursesEdit,
                }}
                timeFormat={false}
              />
            </Col>
          </Row>
        )
      })
    }
  }

  editView = () => {
    return (
      <>
        {this.courseEditrows()}
        <Row>
          <Col style={{ marginTop: '20px' }}>
            <Button
              className='btn-round btn-icon'
              color='primary'
              size='sm'
              onClick={() => {
                this.addCourseRow()
              }}
            >
              <i className='fa fa-plus' />
            </Button>{' '}
            Lisää koulutus
          </Col>
        </Row>
      </>
    )
  }

  listView = () => {
    return (
      <Table responsive>
        <thead className='text-primary'>
          <tr>
            <th>Koulutus</th>
            <th>Suorituspäivä</th>
            <th>Vanhenemispäivä</th>
          </tr>
        </thead>
        <tbody>{this.courseListrows()}</tbody>
      </Table>
    )
  }

  courseListrows = () => {
    if (this.state.courserows) {
      return this.state.courserows.map((row, index) => {
        let i = index
        return (
          <tr key={i}>
            <td>{row.name}</td>
            <td>{moment(row.graduationday).format('DD.MM.YYYY')}</td>
            <td>{row.renewalday ? moment(row.renewalday).format('DD.MM.YYYY') : ''}</td>
          </tr>
        )
      })
    }
  }

  toggleCoursesEdit = () => {
    if (this.state.coursesEdit) {
      this.onSave()
    } else {
      this.setState({ coursesEdit: !this.state.coursesEdit })
    }
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <NotificationAlert ref='notificationAlert' />
          <div className='content'>
            <Row>
              <Col md='12'>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col>
                        <CardTitle tag='h5'>Koulutukset</CardTitle>
                      </Col>
                      <Col>
                        <Button
                          className='btn-icon btn-neutral float-md-right'
                          onClick={() => this.toggleCoursesEdit()}
                        >
                          <i
                            className={
                              this.state.coursesEdit
                                ? 'fa fa-cloud-upload'
                                : 'fa fa-pencil'
                            }
                          />
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.coursesEdit ? this.editView() : this.listView()}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }
}

export default Cources

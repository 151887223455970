import React from 'react'
import { withFirebase } from '../../components/Firebase'
// import Select from 'react-select'
import { Table } from 'reactstrap'
class DocumentList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: true,
      size: 'normal',
      documents: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reload !== this.props.reload) {
      this.updateFileList()
    }
  }

  updateFileList = async () => {
    if (this.props.folder) {
      let documents = []
      const storageRef = this.props.folder + '/' + this.props.profile.customer.id
      const allDocs = await this.props.firebase.storage.ref(`${storageRef}`).listAll()
      for (let i = 0; i < allDocs.items.length; i++) {
        let itemRef = allDocs.items[i]
        let itemMetadata = await itemRef.getMetadata()
        let downloadUrl = await itemRef.getDownloadURL()
        let _doc = {
          dateString: itemMetadata.customMetadata.dateString,
          name: itemMetadata.customMetadata.nameString,
          downloadUrl: downloadUrl
        }
        documents.push(_doc)
        // console.log(_doc)
      }
      this.setState({ documents: documents })
    }
  }

  componentDidMount() {
    this.updateFileList()
  }

  render() {
    if (this.state.documents) {
      return (
        <Table responsive>
          <thead>
            <tr>
              <th>Dokumentti</th>
              <th>Kokous pvm.</th>
              {/* <th>Allekirjoitukset</th> */}
              <th className='text-right'>Lataa</th>
            </tr>
          </thead>
          <tbody>
            {this.state.documents.map((doc, i) => (
                <tr key={i}>
                  <td>{doc.name}</td>
                  <td>{doc.dateString}</td>
                <td className='text-right'>
                  <a href={doc.downloadUrl} download target='_blank' rel='noopener noreferrer'>
                    <i className='nc-icon nc-cloud-download-93' />
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )
    } else {
      return <div />
    }
  }
}

export default withFirebase(DocumentList)

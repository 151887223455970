/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
// import { Line, Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
// import { VectorMap } from "react-jvectormap";
import { withFirebase } from '../components/Firebase'
import Ratings from './charts/memberstats/Ratings'
import Clubrankings from './charts/memberstats/Clubrankings'
import Clublicenses from './charts/memberstats/Clublicenses'
import Members from './charts/memberstats/Members'
import Licenses from './charts/memberstats/Licenses'
import TopFour from './charts/memberstats/TopFour'
import CoachingAges from "./charts/coaching/CoachingAges"

// import { withAuthentication } from '../components/Session';

// reactstrap components
import Select from "react-select";

import {
  //  Badge,
  //  Button,
  Card,
  //  CardHeader,
  CardBody,
  //  CardFooter,
  //  CardTitle,
  //  Label,
  //  FormGroup,
  //  Input,
  //  Table,
  Row,
  Col,
  //  UncontrolledTooltip
} from "reactstrap";

/*
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample5,
  chartExample6,
  chartExample7,
  chartExample8
} from "variables/charts.jsx";
*/

// import { createJsxAttributes } from "typescript";

/*
var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920
};
*/

class Membersdashboard extends React.Component {
  constructor(props) {
    super(props);
    this.timeChange = this.timeChange.bind(this)
    this.updateData = this.updateData.bind(this)
    let defaultSlot = this.getTimeSlots()[0]
    this.state = {
      timeslot: defaultSlot.value,
      members: { M: { j: 0, a: 0 }, F: { j: 0, a: 0 }, S: { j: 0, a: 0 }, A: 0 },
      licenses: {
        M:
        {
          adults: 0,
          onetimeyte: 0,
          seniors: 0,
          u10: 0,
          u18: 0
        },
        F: {
          adults: 0,
          onetimeyte: 0,
          seniors: 0,
          u10: 0,
          u18: 0
        },
        S: {
          adults: 0,
          onetimeyte: 0,
          seniors: 0,
          u10: 0,
          u18: 0
        },
        A: 0
      },
      rankings: {}
    }
  }

  getDefaultLisences = () => ({
    M:
    {
      adults: 0,
      onetimeyte: 0,
      seniors: 0,
      u10: 0,
      u18: 0
    },
    F: {
      adults: 0,
      onetimeyte: 0,
      seniors: 0,
      u10: 0,
      u18: 0
    },
    S: {
      adults: 0,
      onetimeyte: 0,
      seniors: 0,
      u10: 0,
      u18: 0
    },
    A: 0
  })

  groupBy = (objectArray, property) => {
    return objectArray.reduce(function (acc, obj) {
      let key = obj[property]
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})
  }

  getSums = (docs, leaf, attrs, doSum, max) => {
    let _default = {}
    for (let i = 0; i < attrs.length; i++) {
      _default[attrs[i]] = 0
    }
    //    let _male = docs.filter((d) => { return d.data()[leaf] && (d.data().gender === 'M') } ).map(d => { return d.data()[leaf]} ).reduce((p, c) => {
    let _male = docs.filter((d) => { return d[leaf] && (d.gender === 'M') }).map(d => { return d[leaf] }).reduce((p, c) => {
      let _k = {}
      for (let i = 0; i < attrs.length; i++) {
        if (max) {
          if (c[attrs[i]] > p[attrs[i]]) {
            _k[attrs[i]] = c[attrs[i]]
          } else {
            _k[attrs[i]] = p[attrs[i]]
          }
        } else {
          _k[attrs[i]] = p[attrs[i]] + c[attrs[i]]
        }
      }
      return _k
    }, _default)
    let _female = docs.filter((d) => { return d[leaf] && (d.gender === 'L') }).map(d => d[leaf]).reduce((p, c) => {
      //      let _female = docs.filter((d) => { return d.data()[leaf] && (d.data().gender === 'L') } ).map(d => d.data()[leaf]).reduce((p, c) => {
      let _k = {}
      for (let i = 0; i < attrs.length; i++) {
        if (max) {
          if (c[attrs[i]] > p[attrs[i]]) {
            _k[attrs[i]] = c[attrs[i]]
          } else {
            _k[attrs[i]] = p[attrs[i]]
          }
        } else {
          _k[attrs[i]] = p[attrs[i]] + c[attrs[i]]
        }
      }
      return _k
    }, _default)
    if (doSum) {
      let _sum = [_male, _female].reduce((p, c) => {
        let _k = {}
        for (let i = 0; i < attrs.length; i++) {
          _k[attrs[i]] = p[attrs[i]] + c[attrs[i]]
        }
        return _k
      })
      let _a = Object.keys(_sum).map(key => _sum[key]).reduce((p, c) => c + p)
      return { M: _male, F: _female, A: _a }
    }
    return { M: _male, F: _female }
  }

  getMembers = (docs) => {
    // console.log(docs)
    let _male = docs.filter((d) => { return d.members && (d.gender === 'M') }).map(d => d.members).reduce((p, c) => {
      //      let _male = docs.filter((d) => { return d.data().members && (d.data().gender === 'M') } ).map(d => d.data().members).reduce((p, c) => {
      return {
        adults: p.adults + c.adults,
        juniors: p.juniors + c.juniors
      }
    }, { adults: 0, juniors: 0 })
    _male.a = _male.adults
    _male.j = _male.juniors
    // console.log(_male)
    let _female = docs.filter((d) => { return d.members && (d.gender === 'L') }).map(d => d.members).reduce((p, c) => {
      //      let _female = docs.filter((d) => { return d.data().members && (d.data().gender === 'L') } ).map(d => d.data().members).reduce((p, c) => {
      return {
        adults: p.adults + c.adults,
        juniors: p.juniors + c.juniors
      }
    }, { adults: 0, juniors: 0 })
    _female.a = _female.adults
    _female.j = _female.juniors

    let _sum = [_male, _female].reduce((p, c) => { return { a: p.a + c.a, j: p.j + c.j } })
    // console.log(_female)
    return { M: _male, F: _female, S: _sum, A: (_sum.a + _sum.j) }
  }

  getLicenseOwners = (docs) => {
    let _male = docs.filter((d) => { return d.licenses && (d.gender === 'M') }).map(d => d.licenses).reduce((p, c) => {
      //      let _male = docs.filter((d) => { return d.data().licenses && (d.data().gender === 'M') } ).map(d => d.data().licenses).reduce((p, c) => {
      return {
        adults: p.adults + c.adults,
        onetimeyte: p.onetimeyte + c.onetimeyte,
        seniors: p.seniors + c.seniors,
        u10: p.u10 + c.u10,
        u18: p.u18 + c.u18
      }
    }, this.getDefaultLisences().M)
    let _female = docs.filter((d) => { return d.licenses && (d.gender === 'L') }).map(d => d.licenses).reduce((p, c) => {
      //      let _female = docs.filter((d) => { return d.data().licenses && (d.data().gender === 'L') } ).map(d => d.data().licenses).reduce((p, c) => {
      return {
        adults: p.adults + c.adults,
        onetimeyte: p.onetimeyte + c.onetimeyte,
        seniors: p.seniors + c.seniors,
        u10: p.u10 + c.u10,
        u18: p.u18 + c.u18
      }
    }, this.getDefaultLisences().F)
    let _sum = [_male, _female].reduce((p, c) => {
      return {
        adults: p.adults + c.adults,
        onetimeyte: p.onetimeyte + c.onetimeyte,
        seniors: p.seniors + c.seniors,
        u10: p.u10 + c.u10,
        u18: p.u18 + c.u18
      }
    }, this.getDefaultLisences().S)
    let _a = Object.keys(_sum).map(key => _sum[key]).reduce((p, c) => c + p)
    return { M: _male, F: _female, S: _sum, A: _a }
  }


  async updateData(timeslot) {
    let times = timeslot.split(';')
    let members = await this.props.firebase.membersCount(this.props.profile.clubdata.tswClubid, times[0], times[1])
    let _rtdbSnapshotMembers = members.val()
    if (!_rtdbSnapshotMembers) {
      _rtdbSnapshotMembers = {}
    }

    let docs = Object.keys(_rtdbSnapshotMembers).map(key => _rtdbSnapshotMembers[key]).filter(m => m.tswclubid === this.props.profile.clubdata.tswClubid)
    // let data = await members.data()
    // let docs = await members.docs

    let topFours = await this.props.firebase.topFours(times[0], times[1])
    // let data = await members.data()
    let _rtdbSnapshotTopFours = topFours.val()
    if (!_rtdbSnapshotTopFours) _rtdbSnapshotTopFours = []
    let tops = Object.keys(_rtdbSnapshotTopFours).map(key => _rtdbSnapshotTopFours[key]).filter(m => !!m.rankings) // await topFours.docs
    let _lics = Object.keys(_rtdbSnapshotMembers).map(key => _rtdbSnapshotMembers[key]).filter(m => !!m.licenses)
    let _mems = Object.keys(_rtdbSnapshotMembers).map(key => _rtdbSnapshotMembers[key]).filter(m => !!m.members)

    // let tops = _tops.map((d) => { return d.data() } )
    let _rankings = tops.map((d) => { let i = {}; i.name = d.name; i.topfour = d.rankings.topfour; i.threeoffour = d.rankings.threeoffour; i.gender = d.gender; return i })
    let _licenses = _lics.map((d) => { let i = {}; i.name = d.name; i.adults = d.licenses.adults; i.juniors = d.licenses.u18 + d.licenses.u10; i.gender = d.gender; return i })
    let _members = _mems.map((d) => { let i = {}; i.name = d.name; i.adults = d.members.adults; i.juniors = d.members.juniors; i.gender = d.gender; return i })

    this.setState({
      members: this.getMembers(docs),
      licenses: this.getLicenseOwners(docs),
      rankings: this.getSums(docs, 'rankings', ['threeoffour', 'topfour'], false, true),
      ratings: this.getSums(docs, 'ratings', ['A1', 'A2', 'A3', 'B1', 'B2', 'B3', 'C1', 'C2', 'C3', 'D1', 'D2', 'E1',], false, true),
      clubRankings: _rankings,
      clubLicenses: _licenses,
      clubMembers: _members,
      timeslot: timeslot
    })
    /*
    for (let doc of docs) {
      console.log(`Document found : ${JSON.stringify(doc)}`)
    }
    */
  }

  timeChange(value) {
    if (this.state.timeslot === value.value) {
      console.log('do nothing')
    } else {
      console.log('update')
      this.updateData(value.value)
    }
  }

  componentDidMount() {
    this.updateData(this.state.timeslot)
  }

  getTimeSlots() {
    const slot = new Date()
    let options = []
    let y = slot.getFullYear()
    for (let m = slot.getMonth() + 1; m > -1; m--) {
      if (m === 9) options.push({ value: y + '-09-01;' + y + '-12-31', label: '09-12 ' + y })
      if (m === 5) options.push({ value: y + '-05-01;' + y + '-08-31', label: '05-08 ' + y })
      if (m === 1) options.push({ value: y + '-01-01;' + y + '-04-30', label: '01-04 ' + y })
    }
    for (let k = y - 1; k > y - 2; k--) {
      options.push({ value: k + '-09-01;' + k + '-12-31', label: '09-12 ' + k })
      options.push({ value: k + '-05-01;' + k + '-08-31', label: '05-08 ' + k })
      options.push({ value: k + '-01-01;' + k + '-04-30', label: '01-04 ' + k })
    }
    return options
  }

  render() {
    const tss = this.getTimeSlots()
    const { timeValue } = this.state.timeslot
    if (this.props.summary) {
      return (<><Members members={this.state.members} /></>)
    } else {
      if (this.props.profile.customer.federations[0] === 'tennisfin') {
        return (
          <>
            <div className="content">
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg="4">
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="timeslot"
                            value={timeValue}
                            defaultValue={tss[0]}
                            onChange={this.timeChange.bind(this)}
                            options={tss}
                            placeholder="Aika"
                          />
                        </Col>
                        <Col lg="6" md="6">Data perustuu Tennisclubin ja Tennisässän tietoihin sekä seuran omiin ilmoituksiin.</Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="6" sm="6">
                  <Members members={this.state.members} />
                </Col>
                <Col lg="6" md="6" sm="6">
                  <Licenses licenses={this.state.licenses} members={this.state.members} />
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="6" sm="6">
                  <TopFour
                    men={this.state.rankings.M ? this.state.rankings.M.topfour : 0}
                    female={this.state.rankings.F ? this.state.rankings.F.topfour : 0}
                    clubrankings={this.state.clubRankings}
                    clubname={this.props.profile.clubdata.tswName}
                  />
                </Col>
                <Col lg="6" md="6" sm="6">
                  <Ratings data={this.state.ratings} />
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="6" sm="6">
                  <Clubrankings data={this.state.clubRankings} clubname={this.props.profile.clubdata.tswName} gender="L" />
                </Col>
                <Col lg="6" md="12" sm="12">
                  <Clubrankings data={this.state.clubRankings} clubname={this.props.profile.clubdata.tswName} gender="M" />
                </Col>
              </Row>
              <Row>
                <Col lg="12" md="12" sm="12">
                  <Clublicenses licenses={this.state.clubLicenses} members={this.state.clubMembers} clubname={this.props.profile.clubdata.tswName} />
                </Col>
              </Row>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="content">
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg="4">
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="timeslot"
                            value={timeValue}
                            defaultValue={tss[0]}
                            onChange={this.timeChange.bind(this)}
                            options={tss}
                            placeholder="Aika"
                          />
                        </Col>
                        <Col lg="6" md="6">Data perustuu Suomisportin tietoihin sekä seuran omiin ilmoituksiin.</Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="6" sm="6">
                  <Members members={this.state.members} />
                </Col>
                <Col lg="6" md="6" sm="6">
                  <Licenses licenses={this.state.licenses} members={this.state.members} />
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="6" sm="6">
                  <CoachingAges {...this.props} plan={this.props.profile.customer.plan}/>
                </Col>
                <Col lg="6" md="6" sm="6">
                </Col>
              </Row>
            </div>
          </>
        );
      }
    }
  }
}

export default withFirebase(Membersdashboard)
import React from "react";
import { withFirebase } from '../../components/Firebase';

class Logout extends React.Component {
/*
  constructor(props) {
    super(props)
  }
*/

  componentDidMount() {
    this.props.firebase.doSignOut()
  }

  render() {
    // return (<Redirect to="/auth/login" />);
    return (<></>)
  }
}

export default withFirebase(Logout);

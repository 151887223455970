import Person from "views/personel/Person"
import Hours from "views/personel/Hours"
// import Register from "views/pages/Register";
import Login from "views/pages/Login"
import Logout from "views/pages/Logout"

var subroutes = [
  {
    path: "/person/:id",
    name: "Henkilö",
    icon: "nc-icon nc-layout-11",
    component: Person,
    layout: "/admin"
  },
  {
    path: "/employee/hours/:eid",
    name: "Tunnit",
    icon: "nc-icon nc-layout-11",
    component: Hours,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "nc-icon nc-layout-11",
    component: Login,
    layout: "/auth"
  },
  {
    path: '/logout',
    name: 'Kirjaudu ulos',
    icon: 'nc-icon nc-button-power',
    component: Logout,
    layout: '/admin',
  },
  /*
  {
    path: "/register",
    name: "Register",
    icon: "nc-icon nc-layout-11",
    component: Register,
    layout: "/auth"
  }
  */
];
export default subroutes;

/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import { Link } from 'react-router-dom'
// import DemoAlert from '../components/DemoAlert'
import moment from 'moment'
import 'moment/locale/fi'
// import { v4 as uuidv4 } from 'uuid'
// import { withFirebase } from 'components/Firebase'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Input,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  ModalHeader,
  Label
} from 'reactstrap'

class PersonelList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      events: null,
      alert: null,
      loading: true,
      // formvalidation
      titleState: '',
      seatsState: '',
      courtsState: '',
      canlogin: false,
      createuser: false
    }
  }

  componentDidMount() {
    if (this.props.raw) {
      this.setState({
        raw: this.props.raw,
      })
    } else {
    this.props.firebase
      .getEmployees()
      .onSnapshot((snapshot) => {
        let rawData = []
        snapshot.forEach((doc) => {
          let _d = { ...doc.data(), id: doc.id }
          rawData.push(_d)
        })
        this.setState({
          raw: rawData,
        })
      })
    }
    this.unsubscribe = this.props.firebase
    .getAuthSettings()
    .onSnapshot((doc) => {
      if (doc.exists) {
        this.setState({
          authsettings: doc.data(),
        })
      }
    })
    if (
      this.props.profile.userprofile.role &&
      this.props.profile.userprofile.role === 'ADMIN'
    ) {
      const addButton = (
        <Button
          color='info'
          className='float-right'
          onClick={() => this.togglePersonelModal()}
        >
          <span className='btn-label'>
            <i className='nc-icon nc-simple-add' />
          </span>
          Lisää uusi
        </Button>
      )
      this.setState({ addnewbutton: addButton })
    }
  }

  togglePersonelModal = () => {
    this.setState({ personelmodal: !this.state.personelmodal })
  }

  setFirstname = (event) => {
    this.setState({ firstname: event.target.value })
  }

  setLastname = (event) => {
    this.setState({ lastname: event.target.value })
  }

  setRole = (event) => {
    this.setState({ role: event.target.value })
  }

  setTitle = (event) => {
    this.setState({ title: event.target.value })
  }

  setSalarymodel = (event) => {
    this.setState({ salarymodel: event.target.value })
  }

  setEmail = (event) => {
    this.setState({ email: event.target.value })
  }

  setPhone = (event) => {
    this.setState({ phone: event.target.value })
  }

  setCanLogin = (event) => {
    let _o = { canlogin: !this.state.canlogin }
    this.setState(_o)
  }

  setCreateUser = (event) => {
    let _o = { createuser: !this.state.createuser }
    this.setState(_o)
  }

  clearState = () => {
    this.setState({
      firstname: null,
      lastname: null,
      role: null,
      title: null,
      email: null,
      phone: null,
    })
  }

  onSave = (event) => {
    event.preventDefault()
    console.log('Save')
    // const key = uuidv4()
    const now = moment().unix()
    const employee = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      log: {},
      role: this.state.role.toUpperCase(),
      title: this.state.title,
      email: this.state.email,
      phone: this.state.phone,
      salarymodel: this.state.salarymodel,
      createuser: this.state.createuser,
      canlogin: this.state.canlogin,
      customerIds: [this.props.profile.customer.id],
    }
    employee.log[now] = {
      timestamp: now,
      type: 'created',
      user: this.props.user.email,
    }
    this.props.firebase
      .addEmployee(employee)
      .then((result) => {
        this.togglePersonelModal()
        this.clearState()
        console.log(result.id)
      })
  }

  employeeModal = () => {
    return (
      <div>
        <Modal
          isOpen={this.state.personelmodal}
          toggle={this.togglePersonelModal}
        >
          <ModalHeader toggle={this.togglePersonelModal}>
            Uusi työntekijä
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <label>Etunimi *</label>
                <Input
                  type='text'
                  name='firstname'
                  id='firstname'
                  placeholder='Etunimi'
                  onChange={this.setFirstname}
                />
              </FormGroup>
              <FormGroup>
                <label>Sukunimi *</label>
                <Input
                  type='text'
                  name='lastname'
                  id='lastname'
                  placeholder='Sukunimi'
                  onChange={this.setLastname}
                />
              </FormGroup>
              <FormGroup>
                <label>Titteli *</label>
                <Input
                  type='text'
                  name='title'
                  id='title'
                  placeholder='Titteli'
                  onChange={this.setTitle}
                />
              </FormGroup>
              <FormGroup>
                <label>Email *</label>
                <Input
                  type='text'
                  name='email'
                  id='email'
                  placeholder='Sähköposti'
                  onChange={this.setEmail}
                />{' '}
              </FormGroup>
              <FormGroup>
                <label>Puhelin *</label>
                <Input
                  type='text'
                  name='phone'
                  id='phone'
                  placeholder='Puhelin'
                  onChange={this.setPhone}
                />{' '}
              </FormGroup>
              <FormGroup>
                <label>Palkkamalli *</label>
                <Input
                  type='select'
                  name='salarymodel'
                  id='salarymodelSelect'
                  onChange={this.setSalarymodel}
                >
                  <option value=''>Valitse ...</option>
                  <option value='monthly'>Kuukausipalkka</option>
                  <option value='hourly'>Tuntipalkka</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <label>Rooli *</label>
                <Input
                  type='select'
                  name='role'
                  id='roleSelect'
                  onChange={this.setRole}
                >
                  <option value=''>Valitse ...</option>
                  <option value='employee'>Työntekijä</option>
                  <option value='manager'>Esimies</option>
                </Input>
              </FormGroup>
              {this.state.authsettings && this.state.authsettings.usefederation ?
                (<FormGroup check>
                  <Label check>
                    <Input
                      type='checkbox'
                      name='canlogin'
                      id='checked'
                      onChange={this.setCanLogin}
                      checked={this.state.canlogin}
                    />
                    Käyttäjä saa logata sisään Feeliin
                    <span className='form-check-sign'>
                    </span>
                  </Label>
                </FormGroup>)
                : 
                (<FormGroup check>
                  <Label check>
                    <Input
                      type='checkbox'
                      name='createuser'
                      id='checked'
                      onChange={this.setCreateUser}
                      checked={this.state.createuser}
                    />
                    Käyttäjälle luodaan tunnus
                    <span className='form-check-sign'>
                    </span>
                  </Label>
                </FormGroup>)
              }

              <Button color='success' onClick={this.onSave}>
                Tallenna
              </Button>
            </Form>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    )
  }

  render() {
    return (
      <>
        <Card>
          <CardHeader>
            <Row>
              <Col lg='10' md='5' sm='5'>
                <CardTitle tag='h5'>Henkilöstö</CardTitle>
              </Col>
              <Col lg='2' md='1' sm='1'>
                {this.state.addnewbutton}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table responsive>
              <thead className='text-primary'>
                <tr>
                  <th>Nimi</th>
                  <th>Tehtävä</th>
                  <th>Puhelin</th>
                  <th>Tunnit</th>
                </tr>
              </thead>
              <tbody>
                {this.state.raw ? (
                  this.state.raw.map((person) => (
                    <tr key={person.id}>
                      <td>
                        <Link to={{ pathname: 'person/' + person.id }}>{person.firstname} {person.lastname}</Link>
                      </td>
                      <td>{person.title}</td>
                      <td>{person.phone}</td>
                      <td>
                        <Link to={{ pathname: 'employee/hours/' + person.id  }}>
                          Tunnit
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}

              </tbody>
            </Table>
          </CardBody>
        </Card>
        {this.employeeModal()}
      </>
    )
  }
}

// export default withFirebase(PersonelList)
export default PersonelList

/*
                <tr>
                  <td>
                    <Link to={{ pathname: 'person/00001' }}>Sini Paljakka</Link>
                  </td>
                  <td>U10 vastaava</td>
                  <td>40044004</td>
                  <td>
                    <Link to={{ pathname: `employee/hours/00001` }}>
                      Tunnit
                    </Link>
                  </td>
                </tr>
*/
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import TeamSelectDemo from '../../components/TeamSelectDemo'
import { withFirebase } from '../../../components/Firebase'

import DemonstrationData from '../../components/DemonstrationData'
import { Bar } from "react-chartjs-2";
import moment from 'moment'
import 'moment/locale/fi'
import { ledgerChart } from 'variables/charts.jsx'

const colors1 = ['#CC9900', '#FFCC00', '#FFFF00', '#FFFF33', '#FFFF66']
// const colors2 = ['#990000', '#CC0000', '#FF0000', '#CC3333', '#FF6666']
const colors3 = ['#006600', '#009900', '#00CC00', '#00FF00', '#66FF66']

const chartLabels = [
  "Erääntynyt yli 12kk",
  "Erääntynyt 6-12kk",
  "Erääntynyt 3-6kk",
  "Erääntynyt 1-3kk",
  "Erääntynyt 0-1kk",
  "Erääntyy 0-1kk",
  "Erääntyy 1-3kk",
]

const chartOptions =  {
  legend: {
    display: true,
    position: "right",
    labels: {
      fontSize: 10,
      boxWidth: 15
    }
  },
  scales: {
    xAxes: [{
      stacked: true,
    }],
    yAxes: [{
      stacked: true
    }]
  }
}

const BUCKET_AMOUNT = 7

const naming = {
  coaching: 'Valmennusmaksut',
  manual: 'Manuaalilaskutus',
  membership: 'Jäsenmaksut'
}



class Ledger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updated: '',
      chartData: null
    }
  }

  getDemoData = () => {
    // const lastUpdate = new Date()
    // const updateString = ' - ' + lastUpdate.getDate() + '.' + (lastUpdate.getMonth() + 1) + '.'
    this.setState({ chartData: ledgerChart /*, updated: updateString */ })
  }

  createDatasets = (agg) => {
    let datasets = []
    let _l = {}
    let _a = {}
    agg.map((bucket) => {
      for (let t in bucket.incomes) {
        if (!_l[t]) _l[t] = Array(BUCKET_AMOUNT).fill(0)
        if(bucket.index < 5) {
          _l[t][bucket.index] = bucket.incomes[t].balance / 100
        }
        if (!_a[t]) _a[t] = Array(BUCKET_AMOUNT).fill(0)
        if(bucket.index > 4) {
          _a[t][bucket.index] = bucket.incomes[t].balance / 100
        }
      }
      return null
    })
    let i = 0
    for (let type in naming) {
      const _d_a = {
        data: [..._a[type]],
        categoryPercentage: 1.0,
        barPercentage: 0.5,
        barThickness: 6,
        maxBarThickness: 6,
        minBarLength: 2,
        borderColor: colors3[i],
        backgroundColor: colors3[i],
        type: 'bar',
        label: naming[type],
        metakey: 'upcoming-' + naming[type]
      }
      datasets.push(_d_a)
      const _d_l = {
        data: [..._l[type]],
        categoryPercentage: 1.0,
        barPercentage: 0.5,
        barThickness: 6,
        maxBarThickness: 8,
        minBarLength: 2,
        borderColor: colors1[i],
        backgroundColor: colors1[i],
        type: 'bar',
        label: naming[type],
        metakey: 'due-' + naming[type]
      }
      datasets.push(_d_l)
      i++
    }
    return datasets
  }

  aggregateData = () => {
    const buckets = [
      {
        min: -36,
        max: -12,
        label: "Erääntyneet yli 12kk",
        index: 0
      },
      {
        min: -11,
        max: -6,
        label: "Erääntyneet 6-12kk",
        index: 1
      },
      {
        min: -6,
        max: -3,
        label: "Erääntyneet 3-5kk",
        index: 2
      },
      {
        min: -3,
        max: -1,
        label: "Erääntyneet 1-2kk",
        index: 3
      },
      {
        min: -1,
        max: 0,
        label: "Erääntyneet 1kk",
        index: 4
      },
      {
        min: 0,
        max: 1,
        label: "Erääntyy 1kk",
        index: 5
      },
      {
        min: 2,
        max: 30,
        label: "Erääntyy 3kk",
        index: 6
      }
    ]

    if (this.state.ledgeraggregate) {
      let _b = buckets.map((b) => {
        if (b.min < 0) {
          b.min = moment().subtract('months', -(b.min))
        } else {
          b.min = moment().add('months', b.min)
        }
        if (b.max < 0) {
          b.max = moment().subtract('months', -(b.max))
        } else {
          b.max = moment().add('months', b.max)
        }
        return b
      })

      for (let year in this.state.ledgeraggregate) {
        for (let month in this.state.ledgeraggregate[year]) {
          let _obsr = moment().year(year).month(month).date(2)
          for (let k = 0; k < _b.length; k++) {
            let b = _b[k]
            if (_obsr.isBetween(b.min, b.max)) {
              for (let t in this.state.ledgeraggregate[year][month]) {
                if (!_b[k]['incomes']) _b[k]['incomes'] = {}
                if (!_b[k]['incomes'][t]) {
                  _b[k]['incomes'][t] = { balance: 0, debt: 0, sum: 0 }
                }
                if (year === moment().year().toString() && month === moment().month().toString()) {
                  if (this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].upcoming && this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].due) {
                    let _uk = k + 1
                    if (!_b[_uk]['incomes']) _b[_uk]['incomes'] = {}
                    if (!_b[_uk]['incomes'][t]) {
                      _b[_uk]['incomes'][t] = { balance: 0, debt: 0, sum: 0 }
                    }
                    let _dk = k
                    let _us = this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].upcoming.sum
                    if (isNaN(_us)) _us = 0
                    _b[_uk]['incomes'][t].balance = _b[_uk]['incomes'][t].balance + this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].upcoming.balance
                    _b[_uk]['incomes'][t].sum = _b[_uk]['incomes'][t].sum + _us
                    _b[_uk]['incomes'][t].debt = _b[_uk]['incomes'][t].debt + this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].upcoming.debt

                    let _ds = this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].due.sum
                    if (isNaN(_ds)) _ds = 0
                    _b[_dk]['incomes'][t].balance = _b[_dk]['incomes'][t].balance + this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].due.balance
                    _b[_dk]['incomes'][t].sum = _b[_dk]['incomes'][t].sum + _ds
                    _b[_dk]['incomes'][t].debt = _b[_dk]['incomes'][t].debt + this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].due.debt
                  } else {
                    let _s = this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].sum
                    if (isNaN(_s)) _s = 0
                    _b[k]['incomes'][t].balance = _b[k]['incomes'][t].balance + this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].balance
                    _b[k]['incomes'][t].sum = _b[k]['incomes'][t].sum + _s
                    _b[k]['incomes'][t].debt = _b[k]['incomes'][t].debt + this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].debt

                  }
                } else {
                  let _s = this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].sum
                  let _ba = this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].balance
                  let _d = this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].debt
                  if (isNaN(_s)) _s = 0
                  if (isNaN(_ba)) _ba = 0
                  if (isNaN(_d)) _d = 0
                  _b[k]['incomes'][t].balance = _b[k]['incomes'][t].balance + _ba
                  _b[k]['incomes'][t].sum = _b[k]['incomes'][t].sum + _s
                  _b[k]['incomes'][t].debt = _b[k]['incomes'][t].debt + _d

                }
              }
            }
          }
        }
      }
      let chartData = { labels: chartLabels }
      chartData.datasets = this.createDatasets(_b)
      // const updateString = '- Päivitetty ' + lastUpdate.getDate() + '.' + (lastUpdate.getMonth() + 1) + '.' + lastUpdate.getFullYear()
      this.setState({ chartAgg: _b, /* data: chart,*/ chartData: chartData /*, updated: updateString */ })
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    // check account type
    if (this.props.profile.customer.plan !== 'free') {
      this.props.firebase.getledgeraggregate().get().then(doc => {
        if (doc.exists) {
          this.setState({
            ledgeraggregate: doc.data()
          }, this.aggregateData)
        }
      })
    } else {
      this.getDemoData()
    }
  }

  metakeyAsKey = (dataset) => {
    return dataset.metakey
  }

  render() {
    let _badgeSize = 'normal'
    if (this.props.summary) {
      chartOptions.legend.display = false
      _badgeSize = 'small'
    }
    if (this.state.chartData) {
      return (
        <Card>
          <CardHeader>
            <Row>
              <Col lg="4" md="4" sm="3"><CardTitle tag="h5">Avoimet saamiset <small className="headerupdate">{this.state.updated}</small></CardTitle></Col>
              <Col lg="5" md="5" sm="2"><TeamSelectDemo identity={this.props.profile.customer.identity} /></Col>
              <Col lg="3" md="3" sm="1"><DemonstrationData plan={this.props.profile.customer.plan} size={_badgeSize} /></Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Bar
              data={this.state.chartData}
              options={chartOptions}
              width={400}
              height={180}
              datasetKeyProvider={this.metakeyAsKey}
            />
          </CardBody>
        </Card>
      )
    } else {
      return (<Card>
        <CardHeader>
          <CardTitle tag="h5">Saamiset</CardTitle>
          <p className="card-category"></p>
        </CardHeader>
        <CardBody>loading ... </CardBody></Card>)
    }

  }
}
export default withFirebase(Ledger)

import React, { Component } from 'react';
import { withFirebase } from '../Firebase'
import {
  Button,
  Input,
  Progress
} from "reactstrap";
import ReactDatetime from "react-datetime"
import { uuid } from 'uuidv4'

class DocumentUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentFile: null,
      sendenable: false
    }

    this.handleChange = this
      .handleChange
      .bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }
  handleChange = e => {
    if (e.target.files[0]) {
      const documentFile = e.target.files[0];
      this.setState({ documentFile }, this.validateInput);
    }
    this.validateInput()
  }

  validateInput = () => {
    let mandatories = ['documentname'].map((e) => this.state[e] ? true : false).every(x => x === true)
    if (mandatories) {
      this.setState({ sendenable: true })
    } else {
      this.setState({ sendenable: false })
    }
  }

  handleInfoChange = event => {
    this.setState({ [event.target.name]: event.target.value }, this.validateInput)
  }

  handleDueChange = documentdate => this.setState({ documentdate: documentdate.format('DD.MM.YYYY') }, this.validateInput)

  handleUpload = () => {
    const { documentFile } = this.state
    const id = uuid()
    const _date = new Date()
    const metadata = {
      customMetadata: {
        'dateString': this.state.documentdate,
        'nameString': this.state.documentname
      }
    }
    if (this.props.forcedownload) {
      metadata.contentDisposition = 'attachment; filename=' + documentFile.name
    }

    const storageRef = this.props.folder + '/' + this.props.profile.customer.id + '/' + _date.getFullYear() + '-' + (_date.getMonth() + 1) + '-' + id + '-' + documentFile.name
    const uploadTask = this.props.firebase.storage.ref(`${storageRef}`).put(documentFile, metadata);
    uploadTask.on('state_changed',
      (snapshot) => {
        // progrss function ....
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        this.setState({ progress });
      },
      (error) => {
        // error function ....
        console.log(error);
      },
      () => {
        this.props.onDone()
      });
  }


  render() {
    const style = {
      // height: '100vh',
      // display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      // marginBottom: 10
    };
    return (
      <div style={style}>
        <Progress max="100" value={this.state.progress} />
        <br />
        <Input className="documentupload" name="documentname" placeholder="Tiedoston nimi" type="text" onChange={this.handleInfoChange} />
        <ReactDatetime
          inputProps={{
            className: "form-control documentupload",
            placeholder: "Päivämäärä",
            name: "documentdate",
          }}
          timeFormat={false}
          onChange={this.handleDueChange}
        />
        <Input className="documentupload" placeholder="Document" name="documentFile" type="file" onChange={this.handleChange} />
        <Button onClick={this.handleUpload} disabled={!this.state.sendenable}>Lähetä</Button>
      </div>
    )
  }
}

export default withFirebase(DocumentUpload)
import React from 'react'
import moment from 'moment'
import 'moment/locale/fi'
import { v4 as uuidv4 } from 'uuid'
import {
  Alert,
  Button,
  Input,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ButtonGroup,
  // Nav,
  // NavItem,
  // NavLink,
} from 'reactstrap'
import { withFirebase } from '../../components/Firebase'
// import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import BigCalendar from 'react-big-calendar'
// import events from './assets/demo/events';
import TextField from '@material-ui/core/TextField'
import 'react-big-calendar/lib/css/react-big-calendar.css'
// import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined'
// import ReactGA from 'react-ga';
// ReactGA.initialize('G-1W6X2ZGSGP');

const TIMEFORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

const localizer = BigCalendar.momentLocalizer(moment)
// let allViews = Object.keys(Views).map((k) => Views[k]);

function MyAgendaEvent({ event }) {
  console.log(event)
  return (
    <span>
      <div>
        {event.title} {event.employeename} - {event.message}
      </div>
    </span>
  )
}

function Event({ event }) {
  // console.log(event);
  // console.log('getting state');
  let title = event.employeename
  if (event.data.type !== 'coaching') {
    title = event.employeename + ' - ' + event.title
  }
  return (
    <span>
      <div>
        {title} {event.away ? '[+]' : ''}
      </div>
    </span>
  )
}

class Rota extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    // this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      events: null,
      alert: false,
      rotaconfig: { types: ['coaching', 'other'] }
    }
  }

  componentDidMount() {
    this.unsubscribeemp = this.props.firebase
      .getEmployees()
      .onSnapshot((snapshot) => {
        let employees = []
        let empmap = {}
        snapshot.forEach((doc) => {
          console.log(doc.data())
          let _d = { ...doc.data(), id: doc.id }
          employees.push(_d)
          empmap[doc.id] = doc.data()
        })
        this.setState(
          {
            employees: employees,
            empmap: empmap,
          },
          this.filterData
        )
      })

    this.props.firebase
      .getCustomerWorks()
      .onSnapshot((snapshot) => {
        let rawData = []
        snapshot.forEach((doc) => {
          let _d = { ...doc.data(), id: doc.id }
          rawData.push(_d)
        })
        let _es = []
        for (let k = 0; k < rawData.length; k++) {
          let d = rawData[k]
          let _e = {}
          _e.employeeid = d.employeeid
          _e.id = d.id
          if (d.clockifyid) {
            _e.title = d.type
          } else {
            _e.title = d.task
          }
          if (d.start) {
            _e.start = moment(d.start).toDate()
          } else if (d.starttime) {
            _e.start = d.starttime.toDate()
          }
          if (d.end) {
            _e.end = moment(d.end).toDate()
          } else if (d.endtime) {
            _e.end = d.endtime.toDate()
          }
          _e.type = d.type
          _e.checked = d.checked
          _e.message = d.message
          _e.away = d.away
          _e.log = d.log
          _e.data = d
          _es.push(_e)
        }
        this.setState(
          {
            // events: _es,
            // data: rawData,
            raw: _es
          },
          this.filterData
        )
      })
  }

  filterData = () => {
    if (this.state.raw && this.state.empmap) {
      let _ess = this.state.raw.map((e) => {
        if (e.employeeid) {
          e.employeename =
            this.state.empmap[e.employeeid].firstname +
            ' ' +
            this.state.empmap[e.employeeid].lastname
        } else if (e.data.employee) {
          e.employeename = e.data.employee
        } else {
          e.employeename = 'Unknown'
        }
        return e
      }).filter((e) => { 
        if (this.state.typeFilter && this.state.typeFilter !== 'all') {
          return this.state.typeFilter === e.type
        }
        return true
      })
      this.setState({
        events: _ess,
      })
    }
  }

  toggleModal = () => this.setState({ modal: !this.state.modal })
  toggleAlert = () => this.setState({ alert: !this.state.alert })

  setEndtime = (event) => {
    console.log(event)
    let p = event.target.value.split(':')
    if (this.state.selectedevent) {
      let et = this.state.selectedevent.end
      let _e = moment(et).hour(p[0]).minute(p[1])
      this.setWorkProperty('end', moment(_e).format(TIMEFORMAT))
    }
  }

  setWorkProperty = (property, value) => {
    console.log(property + ' => ' + value)
    let w = this.state.selectedevent
      ? this.state.selectedevent
      : {
          checked: false,
          end: null,
          start: null,
          log: {},
          task: null,
          type: null,
          message: null,
        }
    w[property] = value
    console.log(w)
    this.setState({ selectedevent: w })
    console.log('set property')
  }

  setStarttime = (event) => {
    let p = event.target.value.split(':')
    if (this.state.selectedevent) {
      let et = this.state.selectedevent.end
      let _e = moment(et).hour(p[0]).minute(p[1])
      this.setWorkProperty('start', moment(_e).format(TIMEFORMAT))
    }
  }

  setTitle = (event) => {
    this.setWorkProperty('title', event.target.value)
  }

  setType = (event) => {
    if (event.target.value === 'add') {
      const type = window.prompt('Uusi tyyppi')
      if (type) {
        this.setState({ type: type })
      }
    } else {
      this.setWorkProperty('type', event.target.value)
    }
  }

  setDescription = (event) => {
    this.setWorkProperty('message', event.target.value)
  }

  setEmployee = (event) => {
    this.setWorkProperty('employeeid', event.target.value)

    //    this.setState({ employee: event.target.value });
  }

  updateView = (view) => {}

  clearState = () => {
    this.setState({
      newstart: null,
      newend: null,
      title: null,
      description: null,
      employee: null,
      type: null,
      selectedevent: null,
    })
  }

  onSave = (event) => {
    event.preventDefault()

    const key = this.state.selectedevent.id
      ? this.state.selectedevent.id
      : uuidv4()
    const now = moment().unix()
    const work = this.state.selectedevent
    work.task = work.title
    work.employee =
      this.state.empmap[this.state.selectedevent.employeeid].firstname +
      ' ' +
      this.state.empmap[this.state.selectedevent.employeeid].lastname
    delete work.title
    work.log[now] = {
      timestamp: now,
      type: 'created',
      user: this.props.user.uid,
    }
    delete work.data
    this.props.firebase
      .addWorkToEmployee(
        this.state.selectedevent.employeeid,
        work,
        key
      )
      .then((dataSnapshot) => {
        // this.props.history.push('/user/main');
        this.toggleModal()
        this.clearState()
      })
  }

  handleSelect = ({ start, end }) => {
    this.setWorkProperty('start', moment(start).format(TIMEFORMAT))
    this.setWorkProperty('end', moment(end).format(TIMEFORMAT))
    this.toggleModal()
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    // var backgroundColor = 'pink'; // + event.hexColor;
    let s = {
      //      borderRadius: '1px',
      //      opacity: 0.8,
      color: 'black',
      fontSize: 'xx-small',
      //      border: '1px',
      //      display: 'block',
      //      width: '8px',
    }

    if (event.type === 'other') {
      s.backgroundColor = event.checked ? '#b9c928' : '#ebb134'
    } else if (event.type === 'coaching') {
      s.backgroundColor = event.checked ? '#baffea' : '#bc31eb'
    }
    if (this.myRef.current && this.myRef.current.props.view === 'agenda') {
      delete s.display
    }
    if (this.myRef.current && this.myRef.current.props.view === 'month') {
      return {}
    }
    return {
      style: s,
    }
  }

  employeeSelectOptions = () => {
    return this.state.employees.map((e) => {
      return (
        <option key={e.id} value={e.id}>
          {e.firstname} {e.lastname}
        </option>
      )
    })
  }

  typeSelectOptions = () => {
    return ['coaching', 'other'].map((e) => {
      return (
        <option key={e} value={e}>
          {e}
        </option>
      )
    })
  }

  handleSelectEvent = (event) => {
    if ((event.checked || event.away) && moment(event.data.end).isBefore()) {
      this.toggleAlert()
    } else {
      this.setState(
        { selectedevent: JSON.parse(JSON.stringify(event)) },
        this.toggleModal()
      )
    }
  }

  eventModal = () => {
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Uusi työ</ModalHeader>
          <ModalBody>
            <Input
              type='text'
              name='title'
              id='title'
              placeholder='Otsikko'
              onChange={this.setTitle}
              value={
                this.state.selectedevent ? this.state.selectedevent.title : ''
              }
            />
            <TextField
              id='starttime'
              label='Alkuaika'
              type='time'
              onChange={this.setStarttime}
              defaultValue={
                this.state.selectedevent
                  ? moment(this.state.selectedevent.start).format('HH:mm')
                  : moment(this.state.newstart).format('HH:mm')
              }
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 600, // 5 min
              }}
            />

            <TextField
              id='endtime'
              label='Loppuaika'
              type='time'
              onChange={this.setEndtime}
              defaultValue={
                this.state.selectedevent
                  ? moment(this.state.selectedevent.end).format('HH:mm')
                  : moment(this.state.newend).format('HH:mm')
              }
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 600, // 5 min
              }}
            />

            <Input
              type='select'
              name='employee'
              id='employeeSelect'
              onChange={this.setEmployee}
              defaultValue={
                this.state.selectedevent && this.state.selectedevent.employeeid
                  ? this.state.selectedevent.employeeid
                  : ''
              }
            >
              <option>Työntekijä ...</option>
              {this.employeeSelectOptions()}
            </Input>

            <Input
              type='select'
              name='type'
              id='typeSelect'
              onChange={this.setType}
              defaultValue={
                this.state.selectedevent && this.state.selectedevent.type
                  ? this.state.selectedevent.type
                  : ''
              }
            >
              <option>Työtyyppi ...</option>
              {this.typeSelectOptions()}
              <option value='add'>Lisää ...</option>
            </Input>
            <Input
              type='text'
              name='description'
              id='description'
              placeholder='Kuvaus'
              onChange={this.setDescription}
              value={
                this.state.selectedevent ? this.state.selectedevent.message : ''
              }
            />

            <Button color='success' onClick={this.onSave}>
              Tallenna
            </Button>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    )
  }

  updateFilter = (filter, status) => {
    let fss = { }
    if (filter === 'type') {
      fss = { typeFilter: status }
    }
    this.setState(fss, this.filterData)
  }


  getTypeFilters = () => {
    if (this.state.rotaconfig && this.state.rotaconfig.types) {
      let _c = this.state.rotaconfig.types
        .map((e) => {
          return (
            <Button
              key={e}
            className='btn-round'
            color='info'
            outline
            type='button'
            onClick={() => this.updateFilter('type', e)}
            active={this.state.typeFilter === e}
          >
            {e}
          </Button>
          )
        })
      _c.push(
        <Button
          className='btn-round'
          key='typeall'
        color='info'
        outline
        type='button'
        onClick={() => this.updateFilter('type', 'all')}
        active={(!this.state.typeFilter || this.state.typeFilter === 'all')}
      >
        Kaikki
      </Button>
      )
      return _c
    } else {
      return [
        <Button
        key='typeall'
        className='btn-round'
        color='info'
        outline
        type='button'
        onClick={() => this.updateFilter('type', 'all')}
        active={(!this.state.typeFilter || this.state.typeFilter === 'all')}
      >
        Kaikki
      </Button>
      ]
    }
  }


  alertNotification = () => {
    return (
      <Alert
        color='danger'
        isOpen={this.state.alert}
        toggle={this.toggleAlert}
        fade={true}
      >
        Kuitattua tai poissaoloksi merkattua työtä ei voi muuttaa.
      </Alert>
    )
  }

  render() {
    const today = new Date()
    if (this.state.employees && this.state.events && this.state.empmap) {
      return (
        <>
          {this.alertNotification()}
          {/* <div className='rota' style={{ height: window.innerHeight * 0.9 }}>
            <Nav>
              <NavItem>
                <NavLink href='/user/main'>Main</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='/admin/employees'>Henkilökunta</NavLink>
              </NavItem>
            </Nav> */}
          <div className='content'>
          <ButtonGroup className='flex-wrap'>
                        {this.getTypeFilters()}
                      </ButtonGroup>
            <BigCalendar
              ref={this.myRef}
              selectable
              localizer={localizer}
              events={this.state.events}
              // defaultView={Views.WEEK}
              scrollToTime={new Date(1970, 1, 1, 6)}
              defaultDate={today}
              onSelectEvent={(event) => this.handleSelectEvent(event)}
              onSelectSlot={this.handleSelect}
              onView={this.updateView}
              components={{
                event: Event,
                agenda: {
                  event: MyAgendaEvent,
                },
              }}
              eventPropGetter={this.eventStyleGetter}
            />
          </div>
          {/* </div> */}
          {this.eventModal()}
        </>
      )
    } else {
      return (
        <>
          <div className='rota' style={{ height: window.innerHeight * 0.9 }}>
            <Spinner size='sm' color='secondary' /> Loading...
          </div>
        </>
      )
    }
  }
}

export default withFirebase(Rota)

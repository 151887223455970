/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

import Select from "react-select"
import { withFirebase } from 'components/Firebase'


class ChangeAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCustomer: null,
      customersArray: null,
    };
  }

  getCustomersArray() {
    this.props.firebase.customers().get().then((customers) => {
      let doc = customers.data()
      let _cc = null
      for(let i = 0; i < doc.idarray.length; i++) {
        let _v = doc.idarray[i]
        if(_v.value === this.props.profile.userprofile.customerIds[0]) {
          _cc = _v
        }
      }
      this.setState({ customersArray: doc['idarray'], currentCustomer: _cc  })
    })
  }

  refreshPage() {
    window.location.reload(false);
  }

  updateData(newCustomerId) {
    this.props.firebase.setActiveCustomer(this.props.user.uid, newCustomerId).then((doc) => {
      this.refreshPage()
    })
  }

  customerChange(value) {
    if (this.state.currentCustomer === value.value) {
      console.log('do nothing')
    } else {
      // console.log('update: ' + JSON.stringify(value, null, 2))
      this.updateData(value.value)
    }
  }

  componentDidMount() {
    this.getCustomersArray()
  }


  render() {
    if (this.props.profile.userprofile.role && this.props.profile.userprofile.role === 'ADMIN' && this.state.customersArray) {
      return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5">Vaihda asiakasta</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="customerid"
                      // value={customerValue}
                      defaultValue={this.state.currentCustomer}
                      onChange={this.customerChange.bind(this)}
                      options={this.state.customersArray}
                      // value={this.state.currentCustomer}
                      placeholder="Asiakas"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return (<></>)
    }
  }
}

export default withFirebase(ChangeAccount);

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import DemoAlert from '../../components/DemoAlert'
import DocumentSelect from '../../components/DocumentSelect'
import DocumentUpload from '../../../components/DocumentUpload/DocumentUpload'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Modal
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

const folder = "incomestatements"

class IncomeStatement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileurl: '',
      modalNotice: false,
      reload: false,
      addnewbutton: ''
    };
  }

  componentDidMount() {
    if (this.props.profile.userprofile.role && this.props.profile.userprofile.role === 'ADMIN') {
      const addButton = (<Button color="info" className="float-right" onClick={() => this.addNew()}>
        <span className="btn-label">
          <i className="nc-icon nc-simple-add" />
        </span>
      Lisää uusi
      </Button>)
      this.setState({ addnewbutton: addButton })
    }
  }

  demoNotify = () => {
    let options = {}
    options = {
      place: 'tr',
      message: (
        <div><div>Tämä toiminto ei ole käytössä demo versiossa.</div></div>
      ),
      type: "warning",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 4,
    };
    this.refs.notificationAlert.notificationAlert(options)
  }

  addNew = () => {
    if (this.props.profile.customer.plan === 'free') {
      this.demoNotify()
    } else {
      this.setState({
        modalNotice: !this.state.modalNotice
      });
    }
  }

  updateFile = (url) => {
    this.setState({ fileurl: url.value })
  }

  uploadDone = () => {
    this.toggleModalNotice()
    this.setState({ reload: true })
  }

  toggleModalNotice = () => {
    this.setState({
      modalNotice: !this.state.modalNotice
    });
  };


  render() {
    return (
      <>
        <NotificationAlert ref="notificationAlert" />
        <div className="content">
          <DemoAlert demolocation="incomestatement" {...this.props} />
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col lg="10" md="5" sm="5"><CardTitle tag="h5">Tuloslaskelma <small className="headerupdate">{this.state.updated}</small></CardTitle></Col>
                    <Col lg="2" md="1" sm="1">
                      {this.state.addnewbutton}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" md="6" sm="6"><DocumentSelect {...this.props} folder={folder} update={this.updateFile.bind(this)} reload={this.state.reload} /></Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <iframe title="File" src={this.state.fileurl} width="100%" height="800px"></iframe>
                </CardBody>
              </Card>
              <Modal
                isOpen={this.state.modalNotice}
                toggle={this.toggleModalNotice}
              >
                <div className="modal-header">
                  <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={this.toggleModalNotice}
                  >
                    <i className="nc-icon nc-simple-remove" />
                  </button>
                  <h5 className="modal-title" id="myModalLabel">Lisää uusi tuloslaskelma</h5>
                </div>
                <div className="modal-body">
                  <div className="instruction">
                    <Row>
                      <Col md="12">
                        <DocumentUpload {...this.props} onDone={this.uploadDone} folder={folder} />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Modal>

            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default IncomeStatement;

import React from 'react'
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap'

import { Bar } from 'react-chartjs-2'

class BarChart extends React.Component {
  render() {
    return (
      <>
        <Card>
          <CardHeader>
            <Row>
              <Col lg='5' md='5' sm='3'>
                <CardTitle tag='h5'>
                  {this.props.title}{' '}
                  <small className='headerupdate'>{this.props.chartdata.updated}</small>
                </CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Bar
              data={this.props.chartdata.data}
              options={this.props.chartdata.options}
              width={400}
              height={100}
            />
          </CardBody>
        </Card>
      </>
    )
  }
}

export default BarChart

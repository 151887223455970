import React from 'react';

import { AuthUserContext, UserProfileContext } from '../Session';
import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";

const WhereToGo = (props) => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>(
        <UserProfileContext.Consumer>
          {profile => 
              authUser ? <AdminLayout {...props} user={authUser} profile={profile} /> :  <AuthLayout {...props} />
          } 
        </UserProfileContext.Consumer>
      )}
    </AuthUserContext.Consumer>
  </div>
);

export { WhereToGo }

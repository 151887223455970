/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Redirect, Route, Switch } from "react-router-dom";
// import { withAuthentication } from '../components/Session';

import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import getProfileRoutes from "routes.js";
import subroutes from "subroutes.js";

var ps;

function FadingRoute({ component: Component, ...rest }) {
  let { user } = rest
  return (
    <Route
      {...rest}
      render={routeProps => user ?
        (<Component {...routeProps} {...rest} />)
        : 
        (<Redirect to='/auth/login' />)
      }
    />
  );
}

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false, 
      routes: getProfileRoutes(this.props.profile.customer.federations[0], this.props.profile.userprofile.role)
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  getCompProps = (type, properties) => {
    var Type = type
    return <Type {...properties} />;
  }

  getComp = (type) => {
    var Type = type
    return <Type />;
  }

  getSubRoutes = subroutes => {
    return subroutes.map((prop, key) => {
      return (
        <FadingRoute
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
          {...this.props}
        />
      )
    })
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        // var c = this.getComp(prop.component)
        // console.log(c)
        // console.log('Adding FadingRoute ' + prop.component)
        return (
          <FadingRoute
          path={prop.layout + prop.path}
          component={prop.component}
            key={key}
          extra={prop.extra}
          {...this.props}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  render() {
    // console.log(this.props)
    const profileRoutes = this.state.routes
    if(profileRoutes) {
      // console.log(profileRoutes)
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={profileRoutes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref="mainPanel">
          <AdminNavbar {...this.props} routes={profileRoutes} handleMiniClick={this.handleMiniClick} />
          <Switch {...this.props} >
          {this.getRoutes(profileRoutes)}
            {this.getSubRoutes(subroutes)}
            {!this.props.authUser ? <Redirect to='/auth/login' /> : null}
          </Switch>
          {// we don't want the Footer to be rendered on full screen maps page
          this.props.location.pathname.indexOf("full-screen-map") !==
          -1 ? null : (
            <Footer fluid />
          )}
        </div>
         {// we don't want the Footer to be rendered on full screen maps page
          this.props.location.pathname === '/' ? this.props.location.pathname = '/admin/dashboard' : null
         }
      </div>
    );
  }
  else {
    return (<></>)
  }
  }
}

export default Admin;

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import Acceptance from "./charts/accounting/EmceAcceptance"
// import Acceptance from "./charts/accounting/Acceptance"
import DemoAlert from './components/DemoAlert'

// reactstrap components
import {
  Row,
  Col
} from "reactstrap";

class PurchaseInvoices extends React.Component {
  render() {
    return (
      <>
        <div className="content">
        <DemoAlert demolocation="purchaseinvoices" {...this.props} />
          <Row>
            <Col md="12">
              <Acceptance plan={this.props.profile.customer.plan} {...this.props} />
              </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default PurchaseInvoices;

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import DemoAlert from 'views/components/DemoAlert'
// import DocumentSelect from 'views/components/DocumentSelect'
// import DocumentUpload from 'components/DocumentUpload/DocumentUpload'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
  Button,
  Modal,
  ListGroup,
  ListGroupItem,
  Alert,
} from 'reactstrap'
import NotificationAlert from 'react-notification-alert'
import ExcelImport from 'components/DocumentUpload/ExcelImport'
import XLSX from 'xlsx'

// const folder = "tcmemberimport"

const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
  return o
}

const MANDATORIES = ['firstname', 'lastname', 'email_1', 'phone', 'streetaddress', 'zipcode', 'city', 'dayofbirth_1', 'dayofbirth_2', 'dayofbirth_3', 'skilllevel']

class TennisClubMemberImport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fileurl: '',
      modalNotice: false,
      reload: false,
      addnewbutton: '',
    }
    this.handleFile = this.handleFile.bind(this)
  }

  componentDidMount() {
    const addButton = (
      <Button
        color='info'
        className='float-right'
        onClick={() => this.addNew()}
      >
        <span className='btn-label'>
          <i className='nc-icon nc-simple-add' />
        </span>
        Lataa tiedosto
      </Button>
    )
    this.setState({ addnewbutton: addButton })
  }

  demoNotify = () => {
    let options = {}
    options = {
      place: 'tr',
      message: (
        <div>
          <div>Tämä toiminto ei ole käytössä demo versiossa.</div>
        </div>
      ),
      type: 'warning',
      icon: 'now-ui-icons ui-1_bell-53',
      autoDismiss: 4,
    }
    this.refs.notificationAlert.notificationAlert(options)
  }

  addNew = () => {
    if (this.props.profile.customer.plan === 'free') {
      this.demoNotify()
    } else {
      this.setState({
        modalNotice: !this.state.modalNotice,
      })
    }
  }

  updateFile = (url) => {
    this.setState({ fileurl: url.value })
  }

  uploadDone = () => {
    this.toggleModalNotice()
    this.setState({ reload: true })
  }

  toggleModalNotice = () => {
    this.setState({
      modalNotice: !this.state.modalNotice,
    })
  }

  handleFile(file /*:File*/) {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' })
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws)
      /* Update state */
      this.setState(
        { xls: data, cols: make_cols(ws['!ref']) },
        this.checkMembers
      )
    }
    if (rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)
  }

  checkMembers = () => {
    let errors = false
    const checked = this.state.xls.map((m, i) => {
      let error = ''
      let statuscolor = 'success'
      let mandatories = MANDATORIES.filter((e) => m[e] ? false : true)
      if (mandatories.length > 0) {
        error = 'Missing mandatory field(s): ' + mandatories
        statuscolor = 'danger'
        errors = true
      } else {
        if (!m.phone.startsWith('+358')) {
          error = 'Phone not at correct format (+358....)'
          statuscolor = 'danger'
          errors = true
        } 
        if (m.zipcode.length !== 5) {
          error += 'Zipcode incorrectly formatted: ' + m.zipcode
          statuscolor = 'danger'
          errors = true
        }
      }
      return (<ListGroupItem key={i} color={statuscolor}>{m.firstname} {m.lastname} {error}</ListGroupItem>)
    })
    let _importalert = null
    if (errors) _importalert = <Alert color="danger">Korjaa alla olevat ongelma ennen latausta.</Alert>
    this.setState(
      { data: checked, importalert: _importalert },
      this.toggleModalNotice
    )
  }

  render() {
    return (
      <>
        <NotificationAlert ref='notificationAlert' />
        <div className='content'>
          <DemoAlert demolocation='tools' {...this.props} />
          <Row>
            <Col md='12'>
              <Card>
                <CardHeader>
                  <Row>
                    <Col lg='10' md='5' sm='5'>
                      <CardTitle tag='h5'>Members excel import</CardTitle>
                      <CardSubtitle>Ensimmäisellä riviillä tulee olla otsikkona seuraavat: {MANDATORIES.join(', ')}</CardSubtitle>
                    </Col>
                    <Col lg='2' md='1' sm='1'>
                      {this.state.addnewbutton}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                {this.state.importalert}
                <ListGroup>
                    {this.state.data}
                    </ListGroup>
                </CardBody>
              </Card>
              <Modal
                isOpen={this.state.modalNotice}
                toggle={this.toggleModalNotice}
              >
                <div className='modal-header'>
                  <button
                    aria-hidden={true}
                    className='close'
                    data-dismiss='modal'
                    type='button'
                    onClick={this.toggleModalNotice}
                  >
                    <i className='nc-icon nc-simple-remove' />
                  </button>
                  <h5 className='modal-title' id='myModalLabel'>
                    Lisää uusia jäseniä
                  </h5>
                </div>
                <div className='modal-body'>
                  <div className='instruction'>
                    <Row>
                      <Col md='12'>
                        <ExcelImport handleFile={this.handleFile} />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default TennisClubMemberImport

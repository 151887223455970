import React from "react";
// import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Select from "react-select";

class TeamSelectDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      size: 'normal'
    };
  }

  componentDidMount() {
    if (this.props.identity === 'Classic') {
      this.setState({ visible: true })
    }
  }

  handleChange = (value) => {
    if(this.props.update) {
      this.props.update(value)
    }
  }

  render() {
    if (this.state.visible) {
      // let _s = (this.state.size === 'normal' ? '50px' : '25px')
      return (<Select
        className="react-select primary"
        classNamePrefix="react-select"
        name="singleSelect"
        value={this.state.singleSelect}
        onChange={value => {
          this.setState({ singleSelect: value })
          this.handleChange(value)
        }

        }
        options={[
          {
            value: "all",
            label: "Seura",
            isDisabled: false
          },
          { value: "LiigaMiehet", label: "Liiga Miehet" },
          { value: "Liiganaiset", label: "Liiga Naiset" },
          { value: "A-TYTÖT", label: "A-TYTÖT" },
          { value: "AKATEMIA TYTÖT", label: "AKATEMIA TYTÖT" },
          { value: "B-TYTÖT", label: "B-TYTÖT" },
          { value: "C-TYTÖT", label: "C-TYTÖT" },
          { value: "D-TYTÖT", label: "D-TYTÖT" },
          { value: "E-TYTÖT", label: "E-TYTÖT" },
          { value: "F-TYTÖT", label: "F-TYTÖT" },
          { value: "A-POJAT", label: "A-POJAT" },
          { value: "B-POJAT", label: "B-POJAT" },
          { value: "C1 04-POJAT", label: "C1 04-POJAT" },
          { value: "C1 04-POJAT RED", label: "C1 04-POJAT RED" },
          { value: "C2 05-POJAT", label: "C2 05-POJAT" },
          { value: "D1 06-POJAT", label: "D1 06-POJAT" },
          { value: "D2 07-POJAT", label: "D2 07-POJAT" },
          { value: "E1 08-POJAT", label: "E1 08-POJAT" },
          { value: "E2 09-POJAT", label: "E2 09-POJAT" },
          { value: "F1/ 10-POJAT", label: "F1/ 10-POJAT" },
          { value: "F11-POJAT", label: "F11-POJAT" },
          { value: "F12-POJAT", label: "F12-POJAT" },
          { value: "G13-POJAT", label: "G13-POJAT" },
          { value: "G14-POJAT", label: "G14-POJAT" },
          { value: "H15 -POJAT", label: "H15 -POJAT" },]}
        placeholder="Joukkue"
      />)
    } else {
      return (<div />);
    }
  }
}

export default TeamSelectDemo;
/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// react component used to create a calendar with events on it
import BigCalendar from 'react-big-calendar'
// dependency plugin for react-big-calendar
import moment from 'moment'
// react component used to create alerts
import SweetAlert from 'react-bootstrap-sweetalert'
import { withFirebase } from 'components/Firebase'
import Switch from 'react-bootstrap-switch'
import ReactDatetime from 'react-datetime'

// reactstrap components
import {
  Alert,
  Card,
  CardBody,
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Button,
  Form,
  FormGroup,
  ButtonGroup,
  Label,
} from 'reactstrap'

import { demoevents } from 'variables/general.jsx'

const localizer = BigCalendar.momentLocalizer(moment)
const TIMEFORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

const CATEGORIES = {
  U10: { color: 'orange' },
  Tikrujengi: { color: 'azure' },
  U18: { color: 'yellow' },
  'TEAM+': { color: 'orange' },
  'HVS CLUB': { color: 'gray' },
  'U10 Esikilpa': { color: 'green' },
  Haastajat: { color: 'green' },
  Liiga: { color: 'green' },
  Kilpa: { color: 'green' },
  Muut: { color: 'green' },
}

function MyAgendaEvent(data) {
  console.log(data)
  let event = data.event
  let title = (event.cancelled ? 'PERUTTU -' : '') + event.type + ' - ' + event.title + ' - ' + (event.location ? event.location : 'Ei paikkaa')  + ' - ' + event.courts + ' kenttää - ' + event.seats + ' osallistujaa.'
  return (
    <span>
      <div onClick={() => event.onSelect(event)}>
        {title} {event.courtsreserved ? ' - Kentät varattu' : ''} {event.setonsale ? ' - Lisätty kauppaan' : ''}  {event.organizer ? ' - Tapahtumavastaava: ' + event.organizer : ''}
      </div>
    </span>
  )
}


function Event({ event }) {
  let title = event.type + ' - ' + event.title + ' - ' + event.courts + ' k'
  return (
    <span>
      <div>
        {title} {event.courtsreserved ? <i className="nc-icon nc-layout-11" /> : ''} {event.setonsale ? <i className="nc-icon nc-cart-simple" /> : ''}
      </div>
    </span>
  )
}

class EventCalendar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      events: null,
      alert: null,
      loading: true,
      // formvalidation
      titleState: '',
      seatsState: '',
      courtsState: '',
    }
  }

  

  toggleModal = () => this.setState({ modal: !this.state.modal })
  toggleAlert = () => this.setState({ alert: !this.state.alert });

  filterData = () => {
    if (this.state.raw) {
      let _es = this.state.raw.map((d) => {
        let _e = {}
        _e.id = d.id
        _e.title = d.task
        _e.start = moment(d.start).toDate()
        _e.end = moment(d.end).toDate()
        _e.type = d.type
        _e.category = d.category
        _e.checked = d.checked
        _e.message = d.message
        _e.courts = d.courts
        _e.location = d.location
        _e.organizer = d.organizer
        _e.seats = d.seats
        _e.cancelled = d.cancelled
        _e.setonsale = d.setonsale
        _e.courtsreserved = d.courtsreserved
        _e.log = d.log
        _e.data = d
        _e.onSelect = this.handleSelectEvent
        let typeColor = (d.type && CATEGORIES[d.type]? CATEGORIES[d.type].color : 'gray')
        _e.color = (d.cancelled ? 'red' : typeColor)
        return _e
      }).filter((e) => {
        if (this.state.typeFilter && this.state.typeFilter !== 'all') {
          return this.state.typeFilter === e.type
        }
        return true
      }).filter((e) => {
        if (this.state.categoryFilter && this.state.categoryFilter !== 'all') {
          return this.state.categoryFilter === e.category
        }
        return true
      }).filter((e) => {
        if (this.state.locationFilter && this.state.locationFilter !== 'all') {
          return this.state.locationFilter === e.location
        }
        return true
      }).filter((e) => {
        if (typeof (this.state.setOnSaleFilter) === 'undefined') {
          return true
        } else if (!this.state.setOnSaleFilter) {
          return true
        } else {
          if (typeof (e.setonsale) !== 'undefined' && e.setonsale) {
            return false
          } else {
            return true
          }
        }
      }).filter((e) => { 
        if (typeof (this.state.courtsReservedFilter) === 'undefined') {
          return true
        } else if (!this.state.courtsReservedFilter) {
          return true
        } else {
          if (typeof (e.courtsreserved) !== 'undefined' && e.courtsreserved) {
            return false
          } else {
            return true
          }
        }
      }).filter((e) => { 
        if (typeof (this.state.cancelledFilter) === 'undefined') {
          return true
        } else if (!this.state.cancelledFilter) {
          return true
        } else {
          if (typeof (e.cancelled) !== 'undefined' && e.cancelled) {
            return true
          } else {
            return false
          }
        }
      })

      this.setState({ events: _es, loading: false })
    } else {
      this.setState({ loading: false })
    }
  }

  getDemoData = () => {
    this.setState({ events: demoevents }, this.filterData)
  }

  componentDidMount() {
    if (this.props.profile.customer.plan !== 'free') {
      this.unsubscribeEvents = this.props.firebase
        .getCalendarEvents()
        .onSnapshot((snapshot) => {
          let rawData = []
          snapshot.forEach((doc) => {
            let _d = { ...doc.data(), id: doc.id }
            rawData.push(_d)
          })
          this.setState(
            {
              raw: rawData,
            },
            this.filterData
          )
        })
      this.unsubscribeConfig = this.props.firebase
        .getCalendarEventConfig()
        .onSnapshot((doc) => {
          if (doc.exists) {
            this.setState({
              eventcalendarconfig: doc.data()
            })
          } else {
            this.setState({
              eventcalendarconfig: { categories: [], types: [] }
            })
          }
        })
    } else {
      this.getDemoData()
    }
  }

  componentWillUnmount() {
    if (this.props.profile.customer.plan !== 'free') {
      this.unsubscribeEvents()
      this.unsubscribeConfig()
    }
  }

  setEventProperty = (property, value) => {
    console.log(property + ' => ' + value)
    let w = this.state.selectedevent
      ? this.state.selectedevent
      : {
          checked: false,
          end: null,
          start: null,
          log: {},
          task: null,
          category: null,
          type: null,
          message: null,
          location: null,
          organizer: null,
          setonsale: false,
          courtsreserved: false,
          cancelled: false
        }
    w[property] = value
    console.log(w)
    this.setState({ selectedevent: w })
    console.log('set property')
  }

  setStarttime = (event) => {
//    console.log(event)
//    let p = event.target.value.split(':')
//    if (this.state.selectedevent) {
//      let et = this.state.selectedevent.end
//      let _e = moment(et).hour(p[0]).minute(p[1])
      this.setEventProperty('start', event.format(TIMEFORMAT))
 //   }
  }

  setEndtime = (event) => {
    // console.log(event)
    // let p = event.target.value.split(':')
    // if(this.state.selectedevent) {
    //  let et = this.state.selectedevent.end
    //  let _e = moment(et).hour(p[0]).minute(p[1])
      this.setEventProperty('end', event.format(TIMEFORMAT))
    //}
  }

  setTitle = (event) => {
    this.setEventProperty('title', event.target.value)
  }

  setType = (event) => {
    if (event.target.value === 'add') {
      const type = window.prompt('Uusi tyyppi')
      if (type) {
        this.props.firebase.addCalendarEventType(type)
      }
    } else {
      this.setEventProperty('type', event.target.value)
    }
  }

  setLocation = (event) => {
    this.setEventProperty('location', event.target.value)
  }

  setOrganizer = (event) => {
    this.setEventProperty('organizer', event.target.value)
  }

  setCategory = (event) => {
    if (event.target.value === 'add') {
      const category = window.prompt('Uusi kategoria')
      if (category) {
        this.props.firebase.addCalendarEventCategory(category)
      }
    } else {
      this.setEventProperty('category', event.target.value)
    }
  }

  setDescription = (event) => {
    this.setEventProperty('message', event.target.value)
  }

  clearState = () => {
    this.setState({
      newstart: null,
      newend: null,
      title: null,
      description: null,
      employee: null,
      type: null,
      category: null,
      selectedevent: null,
    })
  }

  onSave = (event) => {
    event.preventDefault()
    if (this.props.profile.customer.plan !== 'free') {
      const now = moment().unix()
      const calendarevent = this.state.selectedevent
      calendarevent.task = calendarevent.title
      delete calendarevent.title
      calendarevent.log[now] = {
        timestamp: now,
        type: 'created',
        user: this.props.user.uid,
      }
      console.log(calendarevent)
      delete calendarevent.data
      this.props.firebase
        .addCalendarEvent(calendarevent)
        .then((dataSnapshot) => {
          // this.props.history.push('/user/main');
          this.toggleModal()
          this.clearState()
        })
    } else {
      this.toggleModal()
      this.clearState()
    }
  }

  handleSelect = ({ start, end }) => {
    console.log('handle select')
    this.setEventProperty('start', moment(start).format(TIMEFORMAT))
    this.setEventProperty('end', moment(end).format(TIMEFORMAT))
    this.toggleModal()
  }

  handleSwitch = (elem, switchstate) => {
    // console.log('handleSwitch. elem:', elem);
    // console.log('name:', elem.props.name);
    // console.log('new state:', state);
    this.setEventProperty(elem.props.name, switchstate)
  }

  handleSelectEvent = (event) => {
    if(moment(event.data.end).isBefore()) {
      this.toggleAlert()
    } else {
      this.setState({selectedevent: JSON.parse(JSON.stringify(event))}, this.toggleModal())
    }
  }

  addNewEventAlert = (slotInfo) => {
    this.setState({
      alert: (
        <SweetAlert
          input
          showCancel
          style={{ display: 'block', marginTop: '-100px' }}
          title='Input something'
          onConfirm={(e) => this.addNewDemoEvent(e, slotInfo)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle='info'
          cancelBtnBsStyle='danger'
        />
      ),
    })
  }

  addNewDemoEvent = (e, slotInfo) => {
    var newEvents = this.state.events
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end,
    })
    this.setState({
      alert: null,
      events: newEvents,
    })
  }
  
  hideAlert = () => {
    this.setState({
      alert: null,
    })
  }

  eventColors = (event, start, end, isSelected) => {
    // var backgroundColor = 'event-'
    let s = { style: {}}
    if(event.color) {
      // s.className = backgroundColor + event.color
    } else {
      // s.className = backgroundColor + 'default'
    }
    s.style.color = 'black'
    // if(event.cancelled) {
    //   s.style.color = 'red'
    // }
    s.style.fontSize = 'small'
    s.style.border = '1px solid black'
    s.style.borderRadius = '3px'
    s.style.backgroundColor = event.color
    return s 
  }

  locationSelectOptions = () => {
    if (this.state.eventcalendarconfig && this.state.eventcalendarconfig.locations) {
      let _c = this.state.eventcalendarconfig.locations
        .map((e) => {
          return (
            <option key={e} value={e}>
              {e}
            </option>
          )
        })
      return _c
    } else {
      return [
        <option key='add' value='add'></option>
      ]
    }
  }

  typeSelectOptions = () => {
    if (this.state.eventcalendarconfig && this.state.eventcalendarconfig.types) {
      let _c = this.state.eventcalendarconfig.types
        .map((e) => {
          return (
            <option key={e} value={e}>
              {e}
            </option>
          )
        })
        _c.push(
          <option key='add' value='add'>
            Lisää uusi ...
          </option>
        )
      return _c
    } else {
      return [
          <option key='add' value='add'>
            Lisää uusi ...
          </option>
      ]
    }
  }

  categorySelectOptions = () => {
    if (this.state.eventcalendarconfig && this.state.eventcalendarconfig.categories) {
      let _c = this.state.eventcalendarconfig.categories
        .map((e) => {
          return (
            <option key={e} value={e}>
              {e}
            </option>
          )
        })
      _c.push(
          <option key='add' value='add'>
            Lisää uusi ...
          </option>
      )
      return _c
    } else {
      return [
          <option key='add' value='add'>
            Lisää uusi ...
          </option>
      ]
    }
  }

  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true
    }
    return false
  }

  verifyNumber = (value) => {
    var numberRex = new RegExp('^[0-9]+$')
    if (numberRex.test(value)) {
      return true
    }
    return false
  }

  validate = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + 'State']: 'has-success' })
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' })
        }
        break
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + 'State']: 'has-success' })
        } else {
          this.setState({ [stateName + 'State']: 'has-danger' })
        }
        break
      default:
        break
    }
    this.setEventProperty(stateName, event.target.value)
  }

  alertNotification = () => {
    return(<Alert color="danger" isOpen={this.state.alert} toggle={this.toggleAlert} fade={true}>
      Vanhaa tapahtumaa ei voi muuttaa.
    </Alert>)
  }

  eventModal = () => {
    let { titleState, seatsState, courtsState } = this.state
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Uusi tapahtuma</ModalHeader>
          <ModalBody>
            <Form id='CalendarEventValidation'>
              <Row>
                <Col>
                  <FormGroup className={`has-label ${titleState}`}>
                    <label>Otsikko *</label>
                    <Input
                      name='fullname'
                      type='text'
                      onChange={(e) => this.validate(e, 'title', 'length', 5)}
                      defaultValue={
                        this.state.selectedevent
                          ? this.state.selectedevent.title
                          : ''
                      }
                    />
                    {this.state.titleState === 'has-danger' ? (
                      <label className='error'>This field is required.</label>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                  <label>Alkuaika *</label>
                    <ReactDatetime
                    onChange={this.setStarttime}
                    defaultValue={
                      this.state.selectedevent
                        ? moment(this.state.selectedevent.start).format('DD.MM.YYYY HH:mm')
                        : moment(this.state.newstart).format('DD.MM.YYYY HH:mm')
                    }
                      inputProps={{
                        className: "form-control",
                        placeholder: "Alkuaika"
                      }}
                      dateFormat='DD.MM.YYYY'
                      timeFormat='HH:mm'
                    />
                  </FormGroup>
                  <FormGroup>
                  <label>Loppuaika *</label>
                    <ReactDatetime
                    onChange={this.setEndtime}
                    defaultValue={
                      this.state.selectedevent
                        ? moment(this.state.selectedevent.end).format('DD.MM.YYYY HH:mm')
                        : moment(this.state.newend).format('DD.MM.YYYY HH:mm')
                    }
                    inputProps={{
                        className: "form-control",
                        placeholder: "Loppuaika"
                      }}
                      dateFormat='DD.MM.YYYY'
                      timeFormat='HH:mm'
                    />
                  </FormGroup>

                  <FormGroup>
                    <label>Tapahtumakategoria *</label>
                    <Input
                      type='select'
                      name='type'
                      id='categorySelect'
                      onChange={this.setCategory}
                      defaultValue={
                        this.state.selectedevent &&
                        this.state.selectedevent.category
                          ? this.state.selectedevent.category
                          : ''
                      }
                    >
                      <option>Kategoria ...</option>
                      {this.categorySelectOptions()}
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <label>Tapahtumaluokka *</label>
                    <Input
                      type='select'
                      name='type'
                      id='typeSelect'
                      onChange={this.setType}
                      defaultValue={
                        this.state.selectedevent &&
                        this.state.selectedevent.type
                          ? this.state.selectedevent.type
                          : ''
                      }
                    >
                      <option>Luokka ...</option>
                      {this.typeSelectOptions()}
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <label>Tapahtumapaikka *</label>
                    <Input
                      type='select'
                      name='location'
                      id='locationSelect'
                      onChange={this.setLocation}
                      defaultValue={
                        this.state.selectedevent &&
                        this.state.selectedevent.location
                          ? this.state.selectedevent.location
                          : ''
                      }
                    >
                      <option>Paikka ...</option>
                      {this.locationSelectOptions()}
                    </Input>
                  </FormGroup>

                  
                  <FormGroup className={`has-label ${courtsState}`}>
                    <label>Tapahtuma vastaava *</label>

                    <Input
                      type='text'
                      name='organizer'
                      id='organizer'
                      placeholder='Kuka on vastuuhenkilö'
                      onChange={this.setOrganizer}
                      defaultValue={
                        this.state.selectedevent
                          ? this.state.selectedevent.organizer
                          : ''
                      }
                    />
                    {this.state.courtsState === 'has-danger' ? (
                      <label className='error'>This field is required.</label>
                    ) : null}
                  </FormGroup>
                  <FormGroup className={`has-label ${courtsState}`}>
                    <label>Kentät *</label>

                    <Input
                      type='text'
                      name='courts'
                      id='courts'
                      placeholder='Montako kenttää'
                      onChange={(e) => this.validate(e, 'courts', 'number')}
                      defaultValue={
                        this.state.selectedevent
                          ? this.state.selectedevent.courts
                          : ''
                      }
                    />
                    {this.state.courtsState === 'has-danger' ? (
                      <label className='error'>This field is required.</label>
                    ) : null}
                  </FormGroup>
                  <FormGroup className={`has-label ${seatsState}`}>
                    <label>Paikat *</label>

                    <Input
                      type='text'
                      name='seats'
                      id='seats'
                      placeholder='Montako paikkaa'
                      onChange={(e) => this.validate(e, 'seats', 'number')}
                      defaultValue={
                        this.state.selectedevent
                          ? this.state.selectedevent.seats
                          : ''
                      }
                    />
                    {this.state.seatsState === 'has-danger' ? (
                      <label className='error'>This field is required.</label>
                    ) : null}
                  </FormGroup>

                  <FormGroup>
                    <label>Kuvaus *</label>
                    <Input
                      type='textarea'
                      name='description'
                      id='description'
                      placeholder='Kuvaus'
                      onChange={this.setDescription}
                      defaultValue={
                        this.state.selectedevent
                          ? this.state.selectedevent.message
                          : ''
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='category'>Kentät varattu</p>
                  <Switch
                      defaultValue={
                        this.state.selectedevent
                          ? this.state.selectedevent.courtsreserved
                          : false
                      }
                    name='courtsreserved'
                    offColor='success'
                    offText={<i className='nc-icon nc-simple-remove' />}
                    onColor='success'
                    onText={<i className='nc-icon nc-layout-11' />}
                    onChange={(el, state) => this.handleSwitch(el, state)}
                  />
                </Col>
                <Col>
                  <p className='category'>Laitettu kauppaan</p>
                  <Switch
                      defaultValue={
                        this.state.selectedevent
                          ? this.state.selectedevent.setonsale
                          : false
                      }
                    name='setonsale'
                    offColor='success'
                    offText={<i className='nc-icon nc-simple-remove' />}
                    onColor='success'
                    onText={<i className='nc-icon nc-cart-simple' />}
                    onChange={(el, state) => this.handleSwitch(el, state)}
                  />{' '}
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='category'>Peruttu</p>
                  <Switch
                      defaultValue={
                        this.state.selectedevent
                          ? this.state.selectedevent.cancelled
                          : false
                      }
                    name='cancelled'
                    offColor='success'
                    offText={<i className='nc-icon nc-simple-remove' />}
                    onColor='danger'
                    onText={<i className='nc-icon nc-check-2' />}
                    onChange={(el, state) => this.handleSwitch(el, state)}
                  />{' '}
                </Col>
              </Row>
              <Row>
                <Col>
                  {' '}
                  <Button color='success' onClick={this.onSave}>
                    Tallenna
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    )
  }

  updateFilter = (filter, status) => {
    let fss = { }
    if (filter === 'type') {
      fss = { typeFilter: status }
    } else if (filter === 'location') {
        fss = { locationFilter: status }
    } else {
      fss = { categoryFilter: status }
    }
    this.setState(fss, this.filterData)
  }

  updateBooleanFilter = (filter, switchstate) => {
    console.log(filter + ' ' + switchstate)
    if (filter === 'setonsale') {
      this.setState({ setOnSaleFilter: switchstate }, this.filterData)
    } else if (filter === 'courtsreserved') {
      this.setState({ courtsReservedFilter: switchstate }, this.filterData)
    } else {
      this.setState({ cancelledFilter: switchstate }, this.filterData)
    }
  }

  getBooleanFilters = () => {
    return (
      <>
        <FormGroup check inline>
        <Label check>
        <Input
              key='setonsale'
              color='info'
              type='checkbox'
              onClick={() => this.updateBooleanFilter('setonsale', !this.state.setOnSaleFilter)}
              active={(typeof(this.state.setOnSaleFilter) !== 'undefined' ? this.state.setOnSaleFilter.toString() : 'false')}
            />{' '}
            Ei ole kaupassa
            <span className="form-check-sign">
            <span className="check"></span>
          </span>
        </Label>
        </FormGroup>
        <FormGroup check inline>
        <Label check>
        <Input
              key='courtsreserved'
              color='info'
              type='checkbox'
              onClick={() => this.updateBooleanFilter('courtsreserved', !this.state.courtsReservedFilter)}
              active={(typeof(this.state.courtsReservedFilter) !== 'undefined' ? this.state.courtsReservedFilter.toString() : 'false')}
            />
            Kentät varaamatta
          <span className="form-check-sign">
            <span className="check"></span>
          </span>
        </Label>
        </FormGroup>
          <FormGroup check inline>
        <Label check>
          <Input
              key='cancelled'
              color='info'
              type='checkbox'
              onClick={() => this.updateBooleanFilter('cancelled', !this.state.cancelledFilter)}
              active={(typeof(this.state.cancelledFilter) !== 'undefined' ? this.state.cancelledFilter.toString() : 'false')}
            />
            Peruttu
            <span className="form-check-sign">
            <span className="check"></span>
          </span>
        </Label>
        </FormGroup>
      </>
    )
  }

  getTypeFilters = () => {
    if (this.state.eventcalendarconfig && this.state.eventcalendarconfig.types) {
      let _c = this.state.eventcalendarconfig.types
        .map((e) => {
          return (
            <Button
              key={e}
              className='btn-round'
              color='info'
              outline
              type='button'
              onClick={() => this.updateFilter('type', e)}
              active={this.state.typeFilter === e}
            >
            {e}
          </Button>
          )
        })
      _c.push(
        <Button
          className='btn-round'
          key='typeall'
        color='info'
        outline
        type='button'
        onClick={() => this.updateFilter('type', 'all')}
        active={(!this.state.typeFilter || this.state.typeFilter === 'all')}
      >
        Kaikki
      </Button>
      )
      return _c
    } else {
      return [
        <Button
        key='typeall'
        className='btn-round'
        color='info'
        outline
        type='button'
        onClick={() => this.updateFilter('type', 'all')}
        active={(!this.state.typeFilter || this.state.typeFilter === 'all')}
      >
        Kaikki
      </Button>
      ]
    }
  }

  getLocationFilters = () => {
    if (this.state.eventcalendarconfig && this.state.eventcalendarconfig.locations) {
      let _c = this.state.eventcalendarconfig.locations
        .map((e) => {
          return (
          <Button
            key={e}
            className='btn-round'
            color='info'
            outline
            type='button'
            onClick={() => this.updateFilter('location', e)}
            active={this.state.locationFilter === e}
          >
            {e}
          </Button>          )
        })
        _c.push(
          <Button
          key='locationall'
          className='btn-round'
          color='info'
          outline
          type='button'
          onClick={() => this.updateFilter('location', 'all')}
          active={(!this.state.locationFilter || this.state.locationFilter === 'all')}
        >
          Kaikki
        </Button>)
  
      return _c
    } else {
      return [
        <Button
        key='locationall'
        className='btn-round'
        color='info'
        outline
        type='button'
        onClick={() => this.updateFilter('location', 'all')}
        active={(!this.state.locationFilter || this.state.locationFilter === 'all')}
      >
        Kaikki
      </Button>
      ]
    }

  }

  getCategoryFilters = () => {
    if (this.state.eventcalendarconfig && this.state.eventcalendarconfig.categories) {
      let _c = this.state.eventcalendarconfig.categories
        .map((e) => {
          return (
            <Button
            key={e}
            className='btn-round'
            color='info'
            outline
            type='button'
            onClick={() => this.updateFilter('category', e)}
            active={this.state.categoryFilter === e}
          >
            {e}
          </Button>
          )
        })
      _c.push(
        <Button
        key='categoryall'
        className='btn-round'
        color='info'
        outline
        type='button'
        onClick={() => this.updateFilter('category', 'all')}
        active={(!this.state.categoryFilter || this.state.categoryFilter === 'all')}
      >
        Kaikki
      </Button>
      )
      return _c
    } else {
      return [
        <Button
        key='categoryall'
        className='btn-round'
        color='info'
        outline
        type='button'
        onClick={() => this.updateFilter('category', 'all')}
        active={(!this.state.categoryFilter || this.state.categoryFilter === 'all')}
      >
        Kaikki
      </Button>
      ]
    }
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <div className='content'>
           {this.alertNotification()}
            <Row>
              <Col className='ml-auto mr-auto' md='12'>
                <Card className='card-calendar'>
                  <CardBody>
                    <Row>
                    <Col lg='6' md='6'>
                        <ButtonGroup className='flex-wrap'>
                        {this.getCategoryFilters()}
                      </ButtonGroup>
                  </Col>
                  <Col lg='6' md='6'>
                        <ButtonGroup className='flex-wrap'>
                        {this.getTypeFilters()}
                      </ButtonGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg='6' md='6'>
                        <ButtonGroup className='flex-wrap'>
                        {this.getLocationFilters()}
                      </ButtonGroup>
                  </Col>
                  <Col lg='6' md='6'>
                          {this.getBooleanFilters()}
                    </Col>
                    </Row>
                    <Row>
                    <Col lg='12' md='12'>
                    <BigCalendar
                      selectable
                      localizer={localizer}
                      events={this.state.events}
                      defaultView='month'
                      scrollToTime={new Date(1970, 1, 1, 6)}
                      defaultDate={new Date()}
                      onSelectEvent={(event) => this.handleSelectEvent(event)}
                      onSelectSlot={this.handleSelect}
                      eventPropGetter={this.eventColors}
                      components={{
                        event: Event,
                        agenda: {
                          event: MyAgendaEvent,
                        },
                      }}
                        />
                        </Col>
                      </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {this.eventModal()}
        </>
      )
    } else {
      return (
        <>
          <div className='rota' style={{ height: window.innerHeight * 0.9 }}>
            <Spinner size='sm' color='secondary' /> Loading...
          </div>
        </>
      )
    }
  }
}

export default withFirebase(EventCalendar)

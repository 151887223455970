import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Modal
} from "reactstrap";
import { withFirebase } from '../../../components/Firebase'

import { Bar } from "react-chartjs-2";
import DemonstrationData from '../../components/DemonstrationData'
import TeamSelectDemo from '../../components/TeamSelectDemo'
import DateCashflow from 'components/ManualInsertions/DateCashflow'
import moment from 'moment'
import 'moment/locale/fi'

const cashFlowChart = {
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    datasets: [
      {
        data: [18877.39, 14000, 15000],
        barPercentage: 0.5,
        barThickness: 3,
        maxBarThickness: 3,
        minBarLength: 2,
        borderColor: "#51CACF",
        backgroundColor: "transparent",
        type: 'line',
        lineTension: 0,
        label: 'Saldo 2020',
        yAxisID: 'saldo'
      },
      {
        data: [2804.33, 500, 9000],
        barPercentage: 0.5,
        barThickness: 3,
        maxBarThickness: 3,
        minBarLength: 2,
        borderColor: "#6bd098",
        backgroundColor: "#6bd098",
        type: 'bar',
        label: 'Tulot 2020',
        yAxisID: 'default'
      },
      {
        data: [-10354.23, -5000, -8000],
        barPercentage: 0.5,
        barThickness: 6,
        maxBarThickness: 8,
        minBarLength: 2,
        borderColor: "#f17e5d",
        backgroundColor: "#f17e5d",
        type: 'bar',
        label: 'Menot 2020',
        yAxisID: 'default'
      },
      {
        data: [13248.12,
          7089.45,
          31537.16,
          33327.09,
          47865.44,
          38950.73,
          30965.68,
          28727.31,
          28759.03,
          45854.16,
          31949.61,
          26427.29
        ],
        barPercentage: 1,
        barThickness: 3,
        maxBarThickness: 3,
        minBarLength: 2,
        borderColor: "#cdf6f7",
        backgroundColor: "transparent",
        type: 'line',
        label: 'Saldo 2019',
        lineTension: 0,
        yAxisID: 'saldo'
      },
      {
        data: [10999.72,
          5420.77,
          34758.84,
          16625.64,
          25027.29,
          5640.42,
          3266.50,
          3426.29,
          10140.99,
          29165.53,
          6469.10,
          12245.10
        ],
        barPercentage: 1.0,
        barThickness: 3,
        maxBarThickness: 3,
        minBarLength: 2,
        borderColor: "#bdfcd9",
        backgroundColor: "#bdfcd9",
        type: 'bar',
        label: 'Tulot 2019',
        yAxisID: 'default'
      },
      {
        data: [-10733.61,
        -11579.44,
        -10311.13,
        -14835.71,
        -10488.94,
        -14555.13,
        -11251.55,
        -5664.66,
        -10109.27,
        -12070.40,
        -20373.65,
        -17767.42
        ],
        barPercentage: 0.5,
        barThickness: 6,
        maxBarThickness: 8,
        minBarLength: 2,
        borderColor: "#ffcaba",
        backgroundColor: "#ffcaba",
        type: 'bar',
        label: 'Menot 2019',
        yAxisID: 'default'
      }


    ]
  },
  options: {
    legend: {
      display: true,
      position: "right",
      labels: {
        fontSize: 10,
        boxWidth: 15
      }
    },
    scales: {
      yAxes: [{
        id: 'default',
        type: 'linear',
        position: 'left',
        ticks: {
          max: 50000,
          min: -30000
        }
      }, {
        id: 'saldo',
        type: 'linear',
        position: 'right',
        ticks: {
          max: 50000,
          min: -30000
        }
      }]
    }
  }
}

class Cashflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: false,
      cashflow: null,
      updated: '',
      addnewbutton: '',
      reload: false,
      modalNotice: false,
    }
  }

  insertDone = () => {
    this.toggleModalNotice()
    this.setState({ reload: true })
  }

  toggleModalNotice = () => {
    this.setState({
      modalNotice: !this.state.modalNotice
    })
  }

  getDemoData = () => {
    const lastUpdate = new Date()
    const updateString = ' - ' + lastUpdate.getDate() + '.' + (lastUpdate.getMonth() + 1) + '.'
    this.setState({ updated: updateString }, this.randomiseData)
  }

  randomiseData = (value) => {
    let _f = JSON.parse(JSON.stringify(cashFlowChart))
    if (value && value.value !== 'all') {
      let _ds = _f.data.datasets.map((d) => {
        let _da = d.data.map((v) => Math.round(v * (0.6 + (0.4 * Math.random()))))
        d.data = _da
        return d
      })
      _f.data.datasets = _ds
    }
    this.setState({ cashflow: _f })
  }

  fillDataset = () => {
    const currentYear = new Date().getFullYear()
    let _datasets = { months: [], saldo: {}, expenses: {}, incomes: {} }
    for (let i = 2019; i <= currentYear; i++) {
      _datasets.saldo[i] = []
      _datasets.expenses[i] = []
      _datasets.incomes[i] = []
    }
    return _datasets
  }

  aggregateData = () => {
    if (this.state.data) {
      let data = this.state.data
      const currentYear = new Date().getFullYear()
      let _datasets = this.fillDataset()
      let maxSaldo = 0
      let maxCost = 0
      let lastDate = 0
      for (let i = 0; i < data.length; i++) {
        // get year and month
        const year = data[i].date.toDate().getFullYear()
        const month = data[i].date.toDate().getMonth()
        if (data[i].date.toMillis() > lastDate) lastDate = data[i].date.toMillis()
        // let year = 2020
        if (data[i].saldo > maxSaldo) maxSaldo = data[i].saldo
        if (data[i].incomes > maxCost) maxCost = data[i].incomes
        if (-data[i].expenses > maxCost) maxCost = -data[i].expenses
        if (_datasets.saldo[year]) {
          _datasets.saldo[year][month] = data[i].saldo
          _datasets.expenses[year][month] = data[i].expenses
          _datasets.incomes[year][month] = data[i].incomes
        }
      }
      if (this.state.invoiceaggregate && this.state.ledgeraggregate) {
        // const now = moment()
        let _obsr = moment()
        let _c = 0
        while ((this.state.invoiceaggregate[_obsr.year()] && this.state.invoiceaggregate[_obsr.year()][_obsr.month()]) ||
               (this.state.ledgeraggregate[_obsr.year()] && this.state.ledgeraggregate[_obsr.year()][_obsr.month()])) {
                 if(! _datasets.expenses[_obsr.year()]) _datasets.expenses[_obsr.year()] = {}
                 if(! _datasets.incomes[_obsr.year()]) _datasets.incomes[_obsr.year()] = {}
                 if(! _datasets.saldo[_obsr.year()]) _datasets.saldo[_obsr.year()] = {}
          _c = _c + 1
          let _e = 0
          if (this.state.invoiceaggregate[_obsr.year()] && this.state.invoiceaggregate[_obsr.year()][_obsr.month()]) {
            if (_datasets.expenses[_obsr.year()] && _datasets.expenses[_obsr.year()][_obsr.month()]) {
              _e = _datasets.expenses[_obsr.year()][_obsr.month()]
            }
            _e = _e - (this.state.invoiceaggregate[_obsr.year()][_obsr.month()].cost / 100)
            _datasets.expenses[_obsr.year()][_obsr.month()] = _e
          }

          let _i = 0
          if (this.state.ledgeraggregate[_obsr.year()] && this.state.ledgeraggregate[_obsr.year()][_obsr.month()]) {
            if (_datasets.incomes[_obsr.year()] && _datasets.incomes[_obsr.year()][_obsr.month()]) {
              _i = _datasets.incomes[_obsr.year()][_obsr.month()]
            }
            let _pre = 0
            for (let t in this.state.ledgeraggregate[_obsr.year()][_obsr.month()]) {
              _pre = _pre + this.state.ledgeraggregate[_obsr.year()][_obsr.month()][t].prediction
            }
            _i = _i + (_pre / 100)
            _datasets.incomes[_obsr.year()][_obsr.month()] = _i
          }
          if (_i > 0 || _e > 0) {
            _datasets.saldo[_obsr.year()][_obsr.month()] = _datasets.saldo[_obsr.year()][_obsr.subtract(1, 'months').month()] + (_i + _e)
          }
          if (_datasets.saldo[_obsr.year()][_obsr.month()] > maxSaldo) maxSaldo = _datasets.saldo[_obsr.year()][_obsr.month()]
          if (_datasets.incomes[_obsr.year()][_obsr.month()] > maxCost) maxCost = _datasets.incomes[_obsr.year()][_obsr.month()]
          if (-_datasets.expenses[_obsr.year()][_obsr.month()] > maxCost) maxCost = -_datasets.expenses[_obsr.year()][_obsr.month()]

          _obsr = moment().add(_c, 'months')

        }
      }

      let _cashFlow = Object.assign({}, cashFlowChart)
      _cashFlow.data.datasets[0].data = _datasets.saldo[currentYear]
      _cashFlow.data.datasets[0].label = 'Saldo ' + currentYear
      _cashFlow.data.datasets[1].data = _datasets.incomes[currentYear]
      _cashFlow.data.datasets[1].label = 'Tulot ' + currentYear
      _cashFlow.data.datasets[2].data = _datasets.expenses[currentYear]
      _cashFlow.data.datasets[2].label = 'Menot ' + currentYear
      _cashFlow.data.datasets[3].data = _datasets.saldo[currentYear - 1]
      _cashFlow.data.datasets[3].label = 'Saldo ' + (currentYear - 1)
      _cashFlow.data.datasets[4].data = _datasets.incomes[currentYear - 1]
      _cashFlow.data.datasets[4].label = 'Tulot ' + (currentYear - 1)
      _cashFlow.data.datasets[5].data = _datasets.expenses[currentYear - 1]
      _cashFlow.data.datasets[5].label = 'Menot ' + (currentYear - 1)
      _cashFlow.options.scales.yAxes[0].ticks.min = Math.round(- maxCost * 1.1)
      _cashFlow.options.scales.yAxes[0].ticks.max = Math.round(maxCost * 1.1)
      _cashFlow.options.scales.yAxes[1].ticks.min = Math.round(- maxSaldo * 1.1)
      _cashFlow.options.scales.yAxes[1].ticks.max = Math.round(maxSaldo * 1.1)
      const lastUpdate = new Date(lastDate)
      const updateString = '- Päivitetty ' + lastUpdate.getDate() + '.' + (lastUpdate.getMonth() + 1) + '.' + lastUpdate.getFullYear()
      this.setState({ cashflow: _cashFlow, updated: updateString })
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    // check account type
    if (this.props.profile.customer.plan !== 'free') {
      this.unsubscribe = this.props.firebase.cashflow()
        .onSnapshot(snapshot => {
          let rawData = []
          snapshot.forEach(doc => {
            let _d = { ...doc.data(), id: doc.id }
            rawData.push(_d)
          });
          this.setState({
            data: rawData
          }, this.aggregateData)

        });
      this.props.firebase.getinvoicesaggregate().get().then(doc => {
        if (doc.exists) {
          this.setState({
            invoiceaggregate: doc.data()
          }, this.aggregateData)
        }
      })
      this.props.firebase.getledgeraggregate().get().then(doc => {
        if (doc.exists) {
          this.setState({
            ledgeraggregate: doc.data()
          }, this.aggregateData)
        }
      })

    } else {
      this.getDemoData()
    }
    if (this.props.profile.userprofile.role && this.props.profile.userprofile.role === 'ADMIN') {
      const addButton = (<Button color="info" className="float-right" onClick={() => this.addNew()}>
        <span className="btn-label">
          <i className="nc-icon nc-simple-add" />
        </span>
      Lisää
      </Button>)
      this.setState({ addnewbutton: addButton })
    }
  }

  componentWillUnmount() {
    if (this.props.profile.customer.plan !== 'free') {
      this.unsubscribe()
    }
  }

  addNew = () => {
    this.setState({
      modalNotice: !this.state.modalNotice
    });
  }


  render() {
    let _badgeSize = 'normal'
    if (this.props.summary) {
      cashFlowChart.options.legend.display = false
      _badgeSize = 'small'
    }
    if (this.state.cashflow) {
      return (
        <>
          <Card>
            <CardHeader>
              <Row>
                <Col lg="5" md="5" sm="3"><CardTitle tag="h5">Kassavirta <small className="headerupdate">{this.state.updated}</small></CardTitle></Col>
                <Col lg="5" md="5" sm="2"><TeamSelectDemo identity={this.props.profile.customer.identity} update={this.randomiseData.bind(this)} /></Col>
                <Col lg="2" md="1" sm="1">{this.state.addnewbutton}<DemonstrationData plan={this.props.profile.customer.plan} size={_badgeSize} /></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Bar
                data={this.state.cashflow.data}
                options={this.state.cashflow.options}
                width={400}
                height={180}
              />
            </CardBody>
          </Card>
          <Modal
            isOpen={this.state.modalNotice}
            toggle={this.toggleModalNotice}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.toggleModalNotice}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <h5 className="modal-title" id="myModalLabel">Lisää päivän tilanne</h5>
            </div>
            <div className="modal-body">
              <div className="instruction">
                <Row>
                  <Col md="12">
                    <DateCashflow {...this.props} onDone={this.insertDone} />
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        </>
      )
    } else {
      return (<Card>
        <CardHeader>
          <CardTitle tag="h5">Kassavirta</CardTitle>
          <p className="card-category"></p>
        </CardHeader>
        <CardBody>loading ... </CardBody></Card>)
    }
  }
}

export default withFirebase(Cashflow)
import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table
} from "reactstrap";

class Licenses extends React.Component {

  render() {
    const juniorsM = this.props.licenses.M.u18 + this.props.licenses.M.u10
    const juniorsF = this.props.licenses.F.u18 + this.props.licenses.F.u10

    const adultMP = (this.props.members.M.a ? Math.floor((this.props.licenses.M.adults / this.props.members.M.a) * 100) : 0)
    const adultFP = (this.props.members.F.a ? Math.floor((this.props.licenses.F.adults / this.props.members.F.a) * 100) : 0)
    const juniorMP = (this.props.members.M.j ? Math.floor((juniorsM / this.props.members.M.j) * 100) : 0)
    const juniorFP = (this.props.members.F.j ? Math.floor((juniorsF / this.props.members.F.j) * 100) : 0)


    return (
      <Card className="card-stats">
        <CardBody>
          <Row>
            <Col lg="2" md="2" xs="2">
              <div className="icon-big text-center icon-warning">
                <i className="nc-icon nc-trophy text-danger" />
              </div>
            </Col>
            <Col lg="10" md="10" xs="10">
              <div className="numbers">
                <p className="card-category">Kilpailulisenssejä</p>
                <CardTitle tag="p">{this.props.licenses.A}</CardTitle>
                <p />
              </div>
             </Col>
          </Row>
          <Row>
            <Col lg="1" md="1" xs="1"></Col>
            <Col lg="11" md="11" xs="11">
          <Table responsive borderless>
            <thead>
              <tr>
                <th></th>
                <th><p className="h5">Miehet</p></th>
                <th><p className="h5">Naiset</p></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><p className="h5">Aikuiset</p></td>
                <td><p className="h5">{this.props.licenses.M.adults} / <small>{adultMP}%</small></p></td>
                <td><p className="h5">{this.props.licenses.F.adults} / <small>{adultFP}%</small></p></td>
              </tr>
              <tr>
                <td><p className="h5">Juniorit</p></td>
                <td><p className="h5">{this.props.licenses.M.u18 + this.props.licenses.M.u10} / <small>{juniorMP}%</small></p></td>
                <td><p className="h5">{this.props.licenses.F.u18 + this.props.licenses.F.u10} / <small>{juniorFP}%</small></p></td>
              </tr>
            </tbody>
          </Table>
          </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}

export default Licenses
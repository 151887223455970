import React from "react";
import demo from "assets/img/demo.png";

class DemonstrationData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      size: 'normal'
    };
  }

  componentDidMount() {
    if (this.props.plan === 'free') {
      this.setState({ visible: true })
    }
    if (this.props.size) {
      this.setState({ size: this.props.size })
    }
  }

  render() {
    if (this.state.visible) {
      let _s = (this.state.size === 'normal' ? '50px' : '25px')
      return (<><div style={{float:'right'}}><img style={{height:_s}} src={demo} alt="Demo" /></div></>);
    } else {
      return (<div/>);
    }
  }
}

export default DemonstrationData;
import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Spinner,
  Button,
  ButtonGroup,
  Row,
  Col
} from "reactstrap";




class Clublicenses extends React.Component {
  constructor(props) {
    super(props);
    let c = this.getChart()
    this.state = { options: c.options, ownclub: props.clubname, minMembers: 20, genderFilter: 'M', ageFilter: 'a' }
  }

  updateFilter = (gender, age) => {
    this.setState({ genderFilter: gender, ageFilter: age })
    this.setState({ data: this.listsToData(this.props.members, this.props.licenses, gender, age) })
  }

  updateData = (members, licenses) => {
    this.setState({ data: this.listsToData(members, licenses) })
  }

  shouldComponentUpdate(nextProps, nextState) {
    console.log('shouldComponentUpdate')
    /*
    console.log('next.mems')
    console.log(nextProps.members)
    console.log('next.lics')
    console.log(nextProps.licenses)
    */

    if (this.props.members === nextProps.members) {
      console.log('yes - no update')
      // console.log(this.state)
      // console.log(nextState)

      return true;
    } else {
      // console.log('yes')
      // console.log(this.state)
      this.updateData(nextProps.members, nextProps.licenses)
      // console.log(this.state)
      return true
    }
  }

  sortObjects = (a, b) => {
    let c = 0
    if(a.af<b.af) c = 1
    if(b.af<a.af) c = -1
    if(c === 0) {
      if(a.ma<b.ma) c = 1
    if(b.ma<a.ma) c = -1
    }
    return c
  }

  listsToData = (members, licenses, gender, age) => {
    let gFilter = (gender ? gender : this.state.genderFilter)
    let aFilter = (age ? age : this.state.ageFilter)
    let combined = members.map((m) => {
      let _la = licenses.filter((li) => { if (li.name === m.name && li.gender === m.gender) { return true } else { return false} })
      let _l = null
      if(_la.length > 0) {
        _l = _la[0]
      }
      // console.log(_l)
      let _k = { ma: 0, mj: 0, la: 0, lj: 0, aa: 0, aj: 0 }
      if(m.adults < this.state.minMembers) {
        return _k
      }
      _k.mf = (aFilter === 'a' ? m.adults : m.juniors)
      _k.name = m.name
      _k.gender = m.gender
      _k.ma = m.adults
      _k.mj = m.juniors
      if(_l) {
        _k.la = _l.adults
        _k.lj = _l.juniors
        _k.lf = (aFilter === 'a' ? _l.adults : _l.juniors)
        _k.aa = Math.floor((isNaN(_k.la / _k.ma) ? 0 : _k.la / _k.ma) * 100)
        _k.aj = Math.floor((isNaN(_k.lj / _k.mj) ? 0 : _k.lj / _k.mj) * 100)
        _k.af = (aFilter === 'a' ? _k.aa : _k.aj) 
        if(_k.af > 100) {
          _k.af = 0
        }
      }
      return _k
    }).filter((k) => gFilter === k.gender).filter((k) => k.mf >= this.state.minMembers)

    combined.sort(this.sortObjects)
    let clubRank = combined.findIndex(r => r.name === this.state.ownclub)
    let clubValue = ''
    if(combined[clubRank]) {
      clubValue = ' - Lisenssejä ' + combined[clubRank].af + '% jäsenistä (' + combined[clubRank].lf + ' / ' + combined[clubRank].mf + ')' 
    }
    combined.splice(20)
    // rankings.splice(10)

    let data = { datasets:[], labels:[] }
    data.clubrank = { place: clubRank + 1, value: clubValue }
    let i = 0
    let memberds = { data:[] }
    memberds.borderColor = this.getChartColors()[i]
    memberds.backgroundColor = this.getChartColors()[i]
    memberds.hoverBorderColor = this.getChartColors()[i]
    memberds.borderWidth = 1
    memberds.fill = true
    memberds.label = 'Members'
    memberds.xAxisID = 'amount'
    i++
    let licensesds = { data:[] }
    licensesds.borderColor = this.getChartColors()[i]
    licensesds.backgroundColor = this.getChartColors()[i]
    licensesds.hoverBorderColor = this.getChartColors()[i]
    licensesds.borderWidth = 1
    licensesds.fill = true
    licensesds.label = 'Licenses'
    licensesds.xAxisID = 'amount'
    i++
    let percentds = { data:[] }
    percentds.borderColor = this.getChartColors()[i]
    percentds.backgroundColor = this.getChartColors()[i]
    percentds.hoverBorderColor = this.getChartColors()[i]
    percentds.borderWidth = 1
    percentds.fill = true
    percentds.label = 'Prosentti'
    percentds.xAxisID = 'percent'
    

    for (let k = 0; k < combined.length; k++) {
      licensesds.data.push(combined[k].lf)
      memberds.data.push(combined[k].mf)
      percentds.data.push(combined[k].af)
      data.labels.push(combined[k].name)
    }
    data.datasets.push(memberds)
    data.datasets.push(licensesds)
    data.datasets.push(percentds)

    console.log('returning')
    console.log(data)
    return data
  }

  getChartColors = () => ["#fcc468", "#4cbdd7", "#f56f42"]

  getChart = () => ({
    data: {
      labels: [],
      datasets: [
        {
          label: "Data",
          borderColor: "#fcc468",
          fill: true,
          backgroundColor: "#fcc468",
          hoverBorderColor: "#fcc468",
          borderWidth: 8,
          data: []
        },
        {
          label: "Data",
          borderColor: "#4cbdd7",
          fill: true,
          backgroundColor: "#4cbdd7",
          hoverBorderColor: "#4cbdd7",
          borderWidth: 8,
          data: []
        }
      ]
    },
    options: {
      tooltips: {
        tooltipFillColor: "rgba(0,0,0,0.5)",
        tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        tooltipFontSize: 14,
        tooltipFontStyle: "normal",
        tooltipFontColor: "#fff",
        tooltipTitleFontFamily:
          "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        tooltipTitleFontSize: 14,
        tooltipTitleFontStyle: "bold",
        tooltipTitleFontColor: "#fff",
        tooltipYPadding: 6,
        tooltipXPadding: 6,
        tooltipCaretSize: 8,
        tooltipCornerRadius: 6,
        tooltipXOffset: 10
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            id: 'amount',
            ticks: {
              fontColor: "#9f9f9f",
              //fontStyle: "bold",
              beginAtZero: true,
              padding: 2
            },
            gridLines: {
              zeroLineColor: "transparent",
              display: true,
              drawBorder: false,
              color: "#9f9f9f"
            }
          },
          {
            id: 'percent',
            type: 'linear',
            position: 'top',
            ticks: {
              max: 100,
              min: 0
            }
          }
        ],
        yAxes: [
          {
            barPercentage: 0.9,
            gridLines: {
              zeroLineColor: "white",
              display: false,
              drawBorder: false,
              color: "transparent"
            },
            ticks: {
              padding: 2,
              fontColor: "#9f9f9f",
              //fontStyle: "bold"
            }
          }        ]
      }
    }
  })
  

  render() {
    if(this.state.data) {
      return (
        <Card>
        <CardBody>
         <Row>
            <Col lg="3" md="3"><h4 style={{marginTop: '0'}} >Seurojen ranking lisenssejä / jäsen</h4></Col>           
            <Col lg="9" md="9">
            <ButtonGroup>
                <Button className="btn-round" color="info" outline type="button"
                  onClick={() => this.updateFilter('M', 'a')}
                  active={this.state.genderFilter === 'M' && this.state.ageFilter === 'a'}
                >Miehet</Button>
                <Button className="btn-round" color="info" outline type="button"
                  onClick={() => this.updateFilter('L', 'a')}
                  active={this.state.genderFilter === 'L' && this.state.ageFilter === 'a'}
                >Naiset</Button>
                <Button className="btn-round" color="info" outline type="button"
                  onClick={() => this.updateFilter('M', 'j')}
                  active={this.state.genderFilter === 'M' && this.state.ageFilter === 'j'}
                >Pojat</Button>
                <Button className="btn-round" color="info" outline type="button"
                  onClick={() => this.updateFilter('L', 'j')}
                  active={this.state.genderFilter === 'L' && this.state.ageFilter === 'j'}
                >Tytöt</Button>
              </ButtonGroup>
            </Col>           
         </Row>
         <Row>
           <Col lg="12">

          <HorizontalBar 
            data={this.state.data}
            options={this.state.options}
          />
           </Col>
         </Row>
         </CardBody>
        <CardFooter>
          <div className="legend">
            <i className="fa fa-circle text-danger" />{this.state.data.clubrank.place}. {this.state.ownclub} {this.state.data.clubrank.value}
          </div>
        </CardFooter>
      </Card>
      )
    } else {
      return (<Card>
      <CardHeader>
        <CardTitle tag="h4">Seurojen ranking lisenssejä / jäsen</CardTitle>
        <p className="card-category"></p>
      </CardHeader>
      <CardBody><Spinner animation="grow" /></CardBody></Card>)
    }

  }
}
export default Clublicenses
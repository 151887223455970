/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

class PersonelSums extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widget: (this.props.widget ? this.props.widget : 'amounts'),
      amounts: { fulltime: 0, parttime: 0 }
    };
  }

  componentDidMount() {
    if (this.props.raw) {
      let _amounts = this.props.raw.reduce((acc, curr) => {
        if (curr.salarymodel) {
          if(curr.salarymodel === 'monthly') acc.fulltime++
          if(curr.salarymodel === 'hourly') acc.parttime++
        }
        return acc
      }, { fulltime: 0, parttime: 0 })
      this.setState({ amounts: _amounts })
    } else {
      this.props.firebase
        .getEmployees()
        .onSnapshot((snapshot) => {
          let raw = []
          snapshot.forEach((doc) => {
            let _d = { ...doc.data(), id: doc.id }
            raw.push(_d)
          })
          let _amounts = raw.reduce((acc, curr) => {
            if (curr.salarymodel) {
              if(curr.salarymodel === 'monthly') acc.fulltime++
              if(curr.salarymodel === 'hourly') acc.parttime++
            }
            return acc
          }, { fulltime: 0, parttime: 0 })
          this.setState({ amounts: _amounts })
        })
      }
   }

  render() {
    if (this.state.widget === 'amounts') {
      return (
        <>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-single-02 text-warning" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Kuukausipalkkalaiset</p>
                    <CardTitle tag="p">{this.state.amounts.fulltime}</CardTitle>
                    <p />
                    <p className="card-category">Tuntityöntekijät</p>
                    <CardTitle tag="p">{this.state.amounts.parttime}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>)
    } else {
      return (
        <>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-money-coins text-success" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Palkat</p>
                    <CardTitle tag="p">20,345</CardTitle>
                    <p className="card-category">2018</p>
                    <CardTitle tag="p">19,345</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      )
    }
  }
}

export default PersonelSums;

/*
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="far fa-calendar" /> Tammikuu
                  </div>
            </CardFooter>
*/
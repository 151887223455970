import React, { Component } from 'react';
import { withFirebase } from '../Firebase'
import {
//  Button,
  Input,
  Spinner,
  // Progress
} from "reactstrap";
//import ReactDatetime from "react-datetime"
//import { uuid } from 'uuidv4'


class ExcelImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      converting: false
    }

    this.handleChange = this
      .handleChange
      .bind(this);
  }


  handleChange = (e) => {
    this.setState({converting: true})
		const files = e.target.files;
		if(files && files[0]) this.props.handleFile(files[0]);
	}

  /*
  validateInput = () => {
    let mandatories = ['documentname'].map((e) => this.state[e] ? true : false).every(x => x === true)
    if (mandatories) {
      this.setState({ sendenable: true })
    } else {
      this.setState({ sendenable: false })
    }
  }
*/

/*
  handleInfoChange = event => {
    this.setState({ [event.target.name]: event.target.value }, this.validateInput)
  }
*/

//  handleDueChange = documentdate => this.setState({ documentdate: documentdate.format('DD.MM.YYYY') }, this.validateInput)

/*
  handleUpload = () => {
    const { documentFile } = this.state
    const id = uuid()
    const _date = new Date()
    const metadata = {
      customMetadata: {
        'dateString': this.state.documentdate,
        'nameString': this.state.documentname
      }
    }
    const storageRef = this.props.folder + '/' + this.props.profile.customer.id + '/' + _date.getFullYear() + '-' + (_date.getMonth() + 1) + '-' + id + '-' + documentFile.name
    const uploadTask = this.props.firebase.storage.ref(`${storageRef}`).put(documentFile, metadata);
    uploadTask.on('state_changed',
      (snapshot) => {
        // progrss function ....
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        this.setState({ progress });
      },
      (error) => {
        // error function ....
        console.log(error);
      },
      () => {
        this.props.onDone()
      });
  }
*/


  render() {
    const style = {
      // height: '100vh',
      // display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      // marginBottom: 10
    };
    if (!this.state.converting) {
      return (
        <div style={style}>
          <Input className="documentupload" placeholder="Document" name="documentFile" type="file" onChange={this.handleChange} />
        </div>
      )
    } else {
      return (<div style={style}>
             <Spinner size='sm' color='secondary' /> Checking content...
      </div>)
    }
  }
}
// <Progress max="100" value={this.state.progress} /><br />
// <Button onClick={this.handleUpload} disabled={!this.state.sendenable}>Lähetä</Button>


export default withFirebase(ExcelImport)
import React, { Component } from 'react';
import { withFirebase } from '../Firebase'
import {
  Button,
  Input,
  Progress
} from "reactstrap";
import ReactDatetime from "react-datetime"
import { uuid } from 'uuidv4'

class DateCashflow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendenable: false
    }

    this.handleInput = this.handleInput.bind(this)
  }

  validateInput = () => {
    let mandatories = ['documentdate', 'expenses', 'incomes', 'saldo'].map((e) => this.state[e] ? true : false).every(x => x === true)
    if (mandatories) {
      this.setState({ sendenable: true })
    } else {
      this.setState({ sendenable: false })
    }
  }

  handleInfoChange = event => {
    this.setState({ [event.target.name]: Number(event.target.value) }, this.validateInput)
  }

  handleDueChange = documentdate => this.setState({ documentdate: documentdate.format('DD.MM.YYYY'), docprefix: documentdate.format('YYYY-MM') }, this.validateInput)

  handleInput = () => {
    const id = uuid()
    const dd = this.props.firebase.getTimestamp(this.state.documentdate)
    let documentid = this.state.docprefix + '-' + id
    this.props.firebase.addCashFlow(
      {
        date: dd,
        expenses: -(this.state.expenses),
        saldo: this.state.saldo,
        incomes: this.state.incomes,
        increment: false
      },
      documentid
      ).then((set) => { this.props.onDone() })
  }


  render() {
    const style = {
      // height: '100vh',
      // display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      // marginBottom: 10
    };
    return (
      <div style={style}>
        <Progress max="100" value={this.state.progress} />
        <br />
        <Input className="manualinput cashflow" name="incomes" placeholder="Incomes" type="text" onChange={this.handleInfoChange} />
        <Input className="manualinput cashflow" name="expenses" placeholder="Expenses" type="text" onChange={this.handleInfoChange} />
        <Input className="manualinput cashflow" name="saldo" placeholder="Saldo" type="text" onChange={this.handleInfoChange} />
        <ReactDatetime
          inputProps={{
            className: "form-control manualinput",
            placeholder: "Päivämäärä",
            name: "documentdate",
          }}
          timeFormat={false}
          onChange={this.handleDueChange}
        />
        <Button onClick={this.handleInput} disabled={!this.state.sendenable}>Lähetä</Button>
      </div>
    )
  }
}

export default withFirebase(DateCashflow)
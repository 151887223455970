import React from 'react'
// import DemoAlert from 'views/components/DemoAlert'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
} from 'reactstrap'
import NotificationAlert from 'react-notification-alert'
import Switch from 'react-bootstrap-switch'

class KontoPlan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      kontorows: [],
      loading: true,
      saved: true,
    }
  }

  componentDidMount() {
    if (this.props.profile.customer.plan !== 'free') {
      this.unsubscribe = this.props.firebase
        .getKontoPlan()
        .onSnapshot((doc) => {
          if (doc.exists) {
            this.setState({
              kontorows: doc.data().kontos,
              loading: false,
            })
          } else {
            this.setState({
              loading: false,
            })
          }
        })
    } else {
      this.getDemoData()
    }
  }
  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  onChange = (event) => {
    console.log(event)
    let index = Number.parseInt(event.props.name.split('.')[1])
    let _a = this.state.kontorows
    _a[index].enabled = event.state.value
    this.setState({ kontorows: _a, saved: false })
  }


  setLabel = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.kontorows
    _a[index].label = event.target.value
    this.setState({ kontorows: _a, saved: false })
  }

  setNbr = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.kontorows
    let _s = event.target.value
    _a[index].number = _s
    this.setState({ kontorows: _a, saved: false })
  }

  addKontoRow = () => {
    let j = this.state.kontorows
    j.push({ empty: true, enabled: true })
    this.setState({ kontorows: j })
  }

  onSave = (event) => {
    event.preventDefault()
    console.log('Save')
    let _rows = this.state.kontorows.map((r) => {
      r.number = Number.parseInt(r.number)
      delete r.empty
      return r
    })
    console.log(_rows)
    this.props.firebase
      .addKontoPlan({
        kontos: _rows,
      })
      .then((dataSnapshot) => {
        this.setState({ saved: true })
      })
  }

  kontoRows = () => {
    if (this.state.kontorows) {
      return this.state.kontorows.map((row, index) => {
        let i = index
        return (
          <Row key={i} style={{ border: '1px solid rgba(0, 0, 0, 0.10)' }}>
            <Col sm='2' md='2' xs='2'>
              <FormGroup>
                <Label for={'label.' + i}>Kuvaus</Label>
                <Input
                  type='text'
                  name={'label.' + i}
                  id={'label.' + i}
                  onChange={this.setLabel}
                  onKeyDown={this.handleKeyDown}
                  value={
                    this.state.kontorows[i].label
                      ? this.state.kontorows[i].label
                      : ''
                  }
                  placeholder='Aikuisvalmennus'
                />
              </FormGroup>
            </Col>
            <Col md='2' sm='2' xs='2'>
              <FormGroup>
                <Label for={'nbr.' + i}>Numero</Label>
                <Input
                  type='text'
                  name={'nbr.' + i}
                  id={'nbr.' + i}
                  onChange={this.setNbr}
                  placeholder='3100'
                  value={
                    this.state.kontorows[i].number
                      ? this.state.kontorows[i].number
                      : ''
                  }
                  disabled={
                    this.state.kontorows[i].number &&
                    !this.state.kontorows[i].empty
                  }
                />
              </FormGroup>
            </Col>
            <Col>
                <Label for={'enabled.' + i}>Käytössä</Label>{' '}
                <div name={'enabled.' + i}>
                <Switch
                  defaultValue={this.state.kontorows[i].enabled}
                  onColor='primary'
                  offColor='primary'
                  id={'enabled.' + i}
                  name={'enabled.' + i}
                  onChange={this.onChange}
                  />
                  </div>
            </Col>
          </Row>
        )
      })
    }
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <NotificationAlert ref='notificationAlert' />
          <div className='content'>
            <Row>
              <Col md='12'>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col lg='10' md='5' sm='5'>
                        <CardTitle tag='h5'>Kustannuspaikat</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.kontoRows()}
                    <Row>
                      <Col style={{ marginTop: '20px' }}>
                        <Button
                          className='btn-round btn-icon'
                          color='primary'
                          size='sm'
                          onClick={() => {
                            this.addKontoRow()
                          }}
                        >
                          <i className='fa fa-plus' />
                        </Button>{' '}
                        Lisää kustannuspaikka
                      </Col>
                    </Row>
                    <Row>
                      <Col className='ml-auto mr-auto' sm='12'>
                        <Button
                          disabled={this.state.saved}
                          color='primary'
                          onClick={this.onSave}
                        >
                          Tallenna kustannuspaikat
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }
}

export default KontoPlan

import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
  Table
} from "reactstrap";

class CoachingSums extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      horizontalTabs: "home",
      verticalTabs: "info",
      pageTabs: "homePages",
      openedCollapses: ["collapseOne", "collapse1"]
    };
  }

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses
      });
    }
  };


  render() {
    return (
      <Card className="card-stats">
        <CardBody>
        <Row>
        <Col lg="2" md="2" xs="2">
            <div className="icon-big text-center icon-warning">
              <i className="nc-icon nc-favourite-28 text-warning" />
            </div>
          </Col>
          <Col lg="10" md="10" xs="10">
            <div className="numbers">
              <p className="card-category">Valmennuksessa</p>
            </div>
            </Col>
          </Row>
          <Row>
            <Col lg="2" md="2" xs="2"></Col>
            <Col lg="10" md="10" xs="10">
          <Table responsive borderless>
            <thead>
              <tr>
                <th></th>
                <th><p className="h5">Miehet</p></th>
                <th><p className="h5">Naiset</p></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><p className="h5">Aikuiset</p></td>
                <td><p className="h5">{this.props.men}</p></td>
                <td><p className="h5">{this.props.female}</p></td>
              </tr>
              <tr>
                <td><p className="h5">Kilpa</p></td>
                <td><p className="h5">{this.props.cmen}</p></td>
                <td><p className="h5">{this.props.cfemale}</p></td>
              </tr>
              <tr>
                <td><p className="h5">Harr.</p></td>
                <td><p className="h5">{this.props.hmen}</p></td>
                <td><p className="h5">{this.props.hfemale}</p></td>
              </tr>
              <tr>
                <td><p className="h5">U10</p></td>
                <td><p className="h5">{this.props.u10men}</p></td>
                <td><p className="h5">{this.props.u10female}</p></td>
              </tr>
            </tbody>
          </Table>
          </Col>
          </Row>
        </CardBody>
        <CardFooter>
        </CardFooter>
      </Card>
      
    )
  }
}

export default CoachingSums
/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from 'views/Dashboard.jsx'
import Membersdashboard from 'views/Membersdashboard.jsx'
import Teamsdashboard from 'views/Teamsdashboard.jsx'
import ClubTimeline from 'views/pages/ClubTimeline.jsx'
import Logout from 'views/pages/Logout.jsx'
import WorkAcceptance from 'views/personel/WorkAcceptance'
import ExpensesAcceptance from 'views/personel/ExpensesAcceptance'
import TravelExpensesAcceptance from 'views/personel/TravelExpensesAcceptance'
import Personel from 'views/personel/Personel'
import Cash from 'views/Cash'
import PurchaseInvoices from 'views/PurchaseInvoices'
import IncomeStatement from 'views/charts/accounting/IncomeStatement'
import Balance from 'views/charts/accounting/Balance'
import LedgerDash from 'views/LedgerDash'
import Minutes from 'views/governance/Minutes'
import ByLaws from 'views/governance/ByLaws'
// import Proceedings from "views/governance/Proceedings";
// import CoachingDash from 'views/CoachingDash'
// import CoachingJuniorsDash from 'views/CoachingDash'
// import CoachingAdultsDash from 'views/charts/coaching/CoachingAdultsDash'
import CommunicationDash from 'views/CommunicationDash'
import ChangeAccount from 'views/superadmin/ChangeAccount'
import InvoiceImporter from 'views/superadmin/InvoiceImporter'
import EventCalendar from 'views/pages/EventCalendar'
import TennisClubMemberImport from 'views/tools/TennisClubMemberImport'
import Rota from 'views/personel/Rota'
import WorkReport from 'views/personel/WorkReport'
import ConfigDash from 'views/ConfigDash'
import CoachingCharts from 'views/charts/coaching/CoachingCharts'

const ROLES = ['ADMIN', 'COACH', 'USER']
const _commonRoutes = [
  {
    order: 1,
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-layout-11',
    component: Dashboard,
    layout: '/admin',
  },
  {
    order: 7,
    path: '/timeline',
    name: 'Vuosikello',
    mini: 'VK',
    icon: 'nc-icon nc-calendar-60',
    component: ClubTimeline,
    layout: '/admin',
  },
  {
    order: 7,
    path: '/eventcalendar',
    name: 'Tapahtumat',
    mini: 'TK',
    icon: 'nc-icon nc-calendar-60',
    component: EventCalendar,
    layout: '/admin',
  },
  {
    order: 99,
    path: '/config',
    name: 'Asetukset',
    icon: 'nc-icon nc-settings-gear-65',
    component: ConfigDash,
    layout: '/admin',
  },
  {
    order: 100,
    path: '/logout',
    name: 'Kirjaudu ulos',
    icon: 'nc-icon nc-button-power',
    component: Logout,
    layout: '/admin',
  },
]

const _accountingRoutes = [
  {
    order: 3,
    collapse: true,
    path: '/accounting',
    name: 'Talous',
    icon: 'nc-icon nc-bank',
    state: 'accountingCollapse',
    views: [
      {
        path: '/accounting/cashflow',
        name: 'Kassavirta',
        mini: 'K',
        component: Cash,
        layout: '/admin',
      },
      {
        path: '/accounting/ledger',
        name: 'Reskontra / Myynti',
        mini: 'M',
        component: LedgerDash,
        layout: '/admin',
      },
      {
        path: '/accounting/invoices',
        name: 'Ostolaskut',
        mini: 'O',
        component: PurchaseInvoices,
        layout: '/admin',
      },
      {
        path: '/accounting/incomestatement',
        name: 'Tuloslaskelma',
        mini: 'TL',
        component: IncomeStatement,
        layout: '/admin',
      },
      {
        path: '/accounting/balance',
        name: 'Tase',
        mini: 'TS',
        component: Balance,
        layout: '/admin',
      },
    ],
  },
]

const _personelRoutes = [
  {
    order: 4,
    collapse: true,
    name: 'Henkilöstö',
    icon: 'nc-icon nc-single-02',
    state: 'personelCollapse',
    path: '/personel',
    layout: '/admin',
    views: [
      {
        path: '/personel',
        name: 'Lista',
        mini: 'L',
        component: Personel,
        layout: '/admin',
      },
      {
        path: '/works',
        name: 'Tuntihyväksyntä',
        mini: 'TH',
        component: WorkAcceptance,
        layout: '/admin',
      },
      {
        path: '/expenses',
        name: 'Kululaskuje hyväksyntä',
        mini: 'KH',
        component: ExpensesAcceptance,
        layout: '/admin',
      },
      {
        path: '/travel',
        name: 'Matkalaskujen hyväksyntä',
        mini: 'MH',
        component: TravelExpensesAcceptance,
        layout: '/admin',
      },
      {
        path: '/workreport',
        name: 'Kuukausiraportti',
        mini: 'KR',
        component: WorkReport,
        layout: '/admin',
      },
      {
        path: '/rota',
        name: 'Työvuorolista',
        mini: 'TV',
        component: Rota,
        layout: '/admin',
      },
    ],
  },
]

const _communicationRoutes = [
  {
    order: 8,
    path: '/communication',
    name: 'Viestintä',
    icon: 'nc-icon nc-send',
    component: CommunicationDash,
    layout: '/admin',
  },
]

const _toolsRoutes = {
  tennisfin: [
    {
      order: 8,
      path: '/tools',
      name: 'Työkalut',
      icon: 'nc-icon nc-settings',
      component: TennisClubMemberImport,
      layout: '/admin',
    },
  ]
}

const _governanceRoutes = {
  tennisfin: [
    {
      order: 5,
      collapse: true,
      path: '/governance',
      name: 'Hallinto',
      icon: 'nc-icon nc-compass-05',
      state: 'governanceCollapse',
      layout: '/admin',
      views: [
        {
          path: '/governance/minutes',
          name: 'Pöytäkirjat',
          mini: 'P',
          component: Minutes,
          layout: '/admin',
        },
        {
          path: '/governance/bylaws',
          name: 'Säännöt',
          mini: 'S',
          component: ByLaws,
          layout: '/admin',
        },
        /*,
      {
        path: "/governance/proceedings",
        name: "Toimintakertomus",
        mini: "T",
        component: Proceedings,
        layout: "/admin"
      }
*/
      ],
    },
  ],
  salibandy: [
    {
      order: 5,
      collapse: true,
      path: '/governance',
      name: 'Hallinto',
      icon: 'nc-icon nc-compass-05',
      state: 'governanceCollapse',
      layout: '/admin',
      views: [
        {
          path: '/governance/minutes',
          name: 'Pöytäkirjat',
          mini: 'P',
          component: Minutes,
          layout: '/admin',
        },
        {
          path: '/governance/bylaws',
          name: 'Säännöt',
          mini: 'S',
          component: ByLaws,
          layout: '/admin',
        },
        /*,
      {
        path: "/governance/proceedings",
        name: "Toimintakertomus",
        mini: "T",
        component: Proceedings,
        layout: "/admin"
      }
*/
      ],
    },
  ],
  oyt: [
    {
      order: 5,
      collapse: true,
      path: '/governance',
      name: 'Hallinto',
      icon: 'nc-icon nc-compass-05',
      state: 'governanceCollapse',
      layout: '/admin',
      views: [
        {
          path: '/governance/minutes',
          name: 'Pöytäkirjat',
          mini: 'P',
          component: Minutes,
          layout: '/admin',
        },
      ],
    },
  ],
}


const _membershipRoutes = {
  tennisfin: [
    {
      order: 2,
      path: '/stats',
      name: 'Jäsentilastot',
      icon: 'nc-icon nc-chart-bar-32',
      component: Membersdashboard,
      layout: '/admin',
    },
  ],
  salibandy: [
    {
      order: 2,
      collapse: true,
      path: '/stats',
      name: 'Tilastot',
      icon: 'nc-icon nc-chart-bar-32',
      state: 'statsCollapse',
      views: [
        {
          path: '/stats/members',
          name: 'Jäsentilastot',
          mini: 'JÄ',
          component: Membersdashboard,
          layout: '/admin',
        },
        {
          path: '/stats/teams',
          name: 'Joukkuetilastot',
          mini: 'JO',
          component: Teamsdashboard,
          layout: '/admin',
        },
      ],
    },
  ],
  oyt: []
}

const _superAdminRoutes = [
  {
    order: 9,
    path: '/superadmin',
    name: 'Vaihda asiakasta',
    icon: 'nc-icon nc-button-power',
    component: ChangeAccount,
    layout: '/admin',
  },
  {
    order: 10,
    path: '/invoiceimport',
    name: 'Import Emce',
    icon: 'nc-icon nc-button-power',
    component: InvoiceImporter,
    layout: '/admin',
  },
]

const _coachingRoutes = {
  tennisfin: [
    {
      order: 6,
      collapse: true,
      path: '/coaching',
      name: 'Valmennus',
      icon: 'nc-icon nc-user-run',
      state: 'coachingCollapse',
      layout: '/admin',
      views: [
        // {
        //   path: '/coaching/dash',
        //   name: 'Yleiskuva',
        //   mini: 'YK',
        //   component: CoachingDash,
        //   layout: '/admin',
        // },
        {
          path: '/coaching/adults',
          name: 'Aikuiset',
          mini: 'VA',
          component: CoachingCharts,
          layout: '/admin',
          extra: 'adults'
        },
        {
          path: '/coaching/juniors',
          name: 'Juniorit',
          mini: 'VJ',
          component: CoachingCharts,
          layout: '/admin',
          extra: 'juniors'
        },
      ],
    },
  ],
  salibandy: [],
  oyt: [],
}

const getProfileRoutes = (federation, role) => {
  if(ROLES.includes(role)) {
    let _r = _commonRoutes.copyWithin(0, 0)
    if(role === 'ADMIN') {
      _r = _r.concat(_membershipRoutes[federation], _accountingRoutes, _personelRoutes, _governanceRoutes[federation], _coachingRoutes[federation], _communicationRoutes, _toolsRoutes[federation], _superAdminRoutes)
    } else if (role === 'USER') {
      _r = _r.concat(_membershipRoutes[federation], _accountingRoutes, _personelRoutes, _governanceRoutes[federation], _coachingRoutes[federation], _communicationRoutes)
    } else if (role === 'COACH') {
      _r = _r.concat(_membershipRoutes[federation], _coachingRoutes[federation], _toolsRoutes[federation])
    }
    _r.sort(function (a, b) {
      return a.order - b.order
    })
    return _r
  } else {
    return []
  }
  /*
  let _r = _routes[federation] ? _routes[federation] : []
  if (role === 'ADMIN') {
    _r = _r.concat(superAdminRoutes)
  }
  return _r
  */
}

export default getProfileRoutes


/* TURHAT */

// const _tennisfinroutes = [
//   {
//     path: '/stats',
//     name: 'Jäsentilastot',
//     icon: 'nc-icon nc-chart-bar-32',
//     component: Membersdashboard,
//     layout: '/admin',
//   },
//   {
//     path: '/dashboard',
//     name: 'Dashboard',
//     icon: 'nc-icon nc-layout-11',
//     component: Dashboard,
//     layout: '/admin',
//   },
//   {
//     collapse: true,
//     path: '/accounting',
//     name: 'Talous',
//     icon: 'nc-icon nc-bank',
//     state: 'accountingCollapse',
//     views: [
//       {
//         path: '/accounting/cashflow',
//         name: 'Kassavirta',
//         mini: 'K',
//         component: Cash,
//         layout: '/admin',
//       },
//       {
//         path: '/accounting/ledger',
//         name: 'Reskontra / Myynti',
//         mini: 'M',
//         component: LedgerDash,
//         layout: '/admin',
//       },
//       {
//         path: '/accounting/invoices',
//         name: 'Ostolaskut',
//         mini: 'O',
//         component: PurchaseInvoices,
//         layout: '/admin',
//       },
//       {
//         path: '/accounting/incomestatement',
//         name: 'Tuloslaskelma',
//         mini: 'TL',
//         component: IncomeStatement,
//         layout: '/admin',
//       },
//       {
//         path: '/accounting/balance',
//         name: 'Tase',
//         mini: 'TS',
//         component: Balance,
//         layout: '/admin',
//       },
//     ],
//   },
//   {
//     collapse: true,
//     name: 'Henkilöstö',
//     icon: 'nc-icon nc-single-02',
//     state: 'personelCollapse',
//     path: '/personel',
//     layout: '/admin',
//     views: [
//       {
//         path: '/personel',
//         name: 'Yhteenveto',
//         mini: 'Y',
//         component: Personel,
//         layout: '/admin',
//       },
//       {
//         path: '/works',
//         name: 'Työtunnit',
//         mini: 'T',
//         component: WorkAcceptance,
//         layout: '/admin',
//       },
//     ],
//   },
//   {
//     collapse: true,
//     path: '/governance',
//     name: 'Hallinto',
//     icon: 'nc-icon nc-compass-05',
//     state: 'governanceCollapse',
//     layout: '/admin',
//     views: [
//       {
//         path: '/governance/minutes',
//         name: 'Pöytäkirjat',
//         mini: 'P',
//         component: Minutes,
//         layout: '/admin',
//       },
//       {
//         path: '/governance/bylaws',
//         name: 'Säännöt',
//         mini: 'S',
//         component: ByLaws,
//         layout: '/admin',
//       },
//       /*,
//       {
//         path: "/governance/proceedings",
//         name: "Toimintakertomus",
//         mini: "T",
//         component: Proceedings,
//         layout: "/admin"
//       }
// */
//     ],
//   },
//   {
//     path: '/timeline',
//     name: 'Vuosikello',
//     mini: 'VK',
//     icon: 'nc-icon nc-calendar-60',
//     component: ClubTimeline,
//     layout: '/admin',
//   },
//   {
//     collapse: true,
//     path: '/coaching',
//     name: 'Valmennus',
//     icon: 'nc-icon nc-user-run',
//     state: 'coachingCollapse',
//     layout: '/admin',
//     views: [
//       {
//         path: '/coaching/dash',
//         name: 'Yleiskuva',
//         mini: 'YK',
//         component: CoachingDash,
//         layout: '/admin',
//       },
//     ],
//   },
//   {
//     path: '/communication',
//     name: 'Viestintä',
//     icon: 'nc-icon nc-send',
//     component: CommunicationDash,
//     layout: '/admin',
//   },
//   {
//     path: '/logout',
//     name: 'Kirjaudu ulos',
//     icon: 'nc-icon nc-button-power',
//     component: Logout,
//     layout: '/admin',
//   },
// ]

// const _salibandyroutes = [
//   {
//     collapse: true,
//     path: '/stats',
//     name: 'Tilastot',
//     icon: 'nc-icon nc-chart-bar-32',
//     state: 'statsCollapse',
//     views: [
//       {
//         path: '/stats/members',
//         name: 'Jäsentilastot',
//         mini: 'JÄ',
//         component: Membersdashboard,
//         layout: '/admin',
//       },
//       {
//         path: '/stats/teams',
//         name: 'Joukkuetilastot',
//         mini: 'JO',
//         component: Teamsdashboard,
//         layout: '/admin',
//       },
//     ],
//   },
//   {
//     path: '/dashboard',
//     name: 'Dashboard',
//     icon: 'nc-icon nc-layout-11',
//     component: Dashboard,
//     layout: '/admin',
//   },
//   {
//     collapse: true,
//     path: '/governance',
//     name: 'Hallinto',
//     icon: 'nc-icon nc-compass-05',
//     state: 'governanceCollapse',
//     layout: '/admin',
//     views: [
//       {
//         path: '/governance/minutes',
//         name: 'Pöytäkirjat',
//         mini: 'P',
//         component: Minutes,
//         layout: '/admin',
//       },
//       {
//         path: '/governance/bylaws',
//         name: 'Säännöt',
//         mini: 'S',
//         component: ByLaws,
//         layout: '/admin',
//       },
//       /*,
//       {
//         path: "/governance/proceedings",
//         name: "Toimintakertomus",
//         mini: "T",
//         component: Proceedings,
//         layout: "/admin"
//       }
// */
//     ],
//   },
//   {
//     path: '/timeline',
//     name: 'Vuosikello',
//     mini: 'VK',
//     icon: 'nc-icon nc-calendar-60',
//     component: ClubTimeline,
//     layout: '/admin',
//   },
//   /*
//   {
//     collapse: true,
//     path: "/coaching",
//     name: "Valmennus",
//     icon: "nc-icon nc-user-run",
//     state: "coachingCollapse",
//     layout: "/admin",
//     views: [
//       {
//         path: "/coaching/dash",
//         name: "Yleiskuva",
//         mini: "YK",
//         component: CoachingDash,
//         layout: "/admin"
//       }
//       */
//   /*,
//   {
//     path: "/coaching/competition",
//     name: "Kilpavalmennus",
//     mini: "K",
//     component: ByLaws,
//     layout: "/admin"
//   },
//   {
//     path: "/coaching/underten",
//     name: "U10",
//     mini: "U10",
//     component: ByLaws,
//     layout: "/admin"
//   }
// ]
// },
// */

//   /*  {
//       collapse: true,
//       path: "/competition",
//       name: "Kilpaileminen",
//       icon: "nc-icon nc-trophy",
//       component: UserProfile,
//       layout: "/admin",
//       views: [
//         {
//           path: "/competition/dash",
//           name: "Yleiskuva",
//           mini: "YK",
//           component: Minutes,
//           layout: "/admin"
//         },
//         {
//           path: "/competition/valmennus",
//           name: "Säännöt",
//           mini: "S",
//           component: ByLaws,
//           layout: "/admin"
//         }
//       ]
//     },
//     */
//   {
//     path: '/communication',
//     name: 'Viestintä',
//     icon: 'nc-icon nc-send',
//     component: CommunicationDash,
//     layout: '/admin',
//   },
//   {
//     path: '/logout',
//     name: 'Kirjaudu ulos',
//     icon: 'nc-icon nc-button-power',
//     component: Logout,
//     layout: '/admin',
//   },
// ]

// const _oyroutes = [
//   {
//     collapse: true,
//     path: '/stats',
//     name: 'Tilastot',
//     icon: 'nc-icon nc-chart-bar-32',
//     state: 'statsCollapse',
//     views: [
//       {
//         path: '/stats/members',
//         name: 'Varaustilastot',
//         mini: 'V',
//         component: Membersdashboard,
//         layout: '/admin',
//       },
//     ],
//   },
//   {
//     path: '/dashboard',
//     name: 'Dashboard',
//     icon: 'nc-icon nc-layout-11',
//     component: Dashboard,
//     layout: '/admin',
//   },
//   {
//     collapse: true,
//     path: '/accounting',
//     name: 'Talous',
//     icon: 'nc-icon nc-bank',
//     state: 'accountingCollapse',
//     views: [
//       {
//         path: '/accounting/cashflow',
//         name: 'Kassavirta',
//         mini: 'K',
//         component: Cash,
//         layout: '/admin',
//       },
//       {
//         path: '/accounting/ledger',
//         name: 'Reskontra / Myynti',
//         mini: 'M',
//         component: LedgerDash,
//         layout: '/admin',
//       },
//       {
//         path: '/accounting/invoices',
//         name: 'Ostolaskut',
//         mini: 'O',
//         component: PurchaseInvoices,
//         layout: '/admin',
//       },
//       {
//         path: '/accounting/incomestatement',
//         name: 'Tuloslaskelma',
//         mini: 'TL',
//         component: IncomeStatement,
//         layout: '/admin',
//       },
//       {
//         path: '/accounting/balance',
//         name: 'Tase',
//         mini: 'TS',
//         component: Balance,
//         layout: '/admin',
//       },
//     ],
//   },
//   {
//     collapse: true,
//     name: 'Henkilöstö',
//     icon: 'nc-icon nc-single-02',
//     state: 'personelCollapse',
//     path: '/personel',
//     layout: '/admin',
//     views: [
//       {
//         path: '/personel',
//         name: 'Yhteenveto',
//         mini: 'Y',
//         component: Personel,
//         layout: '/admin',
//       },
//       {
//         path: '/works',
//         name: 'Työtunnit',
//         mini: 'T',
//         component: WorkAcceptance,
//         layout: '/admin',
//       },
//     ],
//   },
//   {
//     collapse: true,
//     path: '/governance',
//     name: 'Hallinto',
//     icon: 'nc-icon nc-compass-05',
//     state: 'governanceCollapse',
//     layout: '/admin',
//     views: [
//       {
//         path: '/governance/minutes',
//         name: 'Pöytäkirjat',
//         mini: 'P',
//         component: Minutes,
//         layout: '/admin',
//       },
//     ],
//   },
//   {
//     path: '/timeline',
//     name: 'Vuosikello',
//     mini: 'VK',
//     icon: 'nc-icon nc-calendar-60',
//     component: ClubTimeline,
//     layout: '/admin',
//   },
//   {
//     path: '/communication',
//     name: 'Viestintä',
//     icon: 'nc-icon nc-send',
//     component: CommunicationDash,
//     layout: '/admin',
//   },
//   {
//     path: '/logout',
//     name: 'Kirjaudu ulos',
//     icon: 'nc-icon nc-button-power',
//     component: Logout,
//     layout: '/admin',
//   },
// ]

// const routes = [
//   {
//     collapse: true,
//     path: '/stats',
//     name: 'Tilastot',
//     icon: 'nc-icon nc-chart-bar-32',
//     state: 'statsCollapse',
//     views: [
//       {
//         path: '/stats/members',
//         name: 'Jäsentilastot',
//         mini: 'JÄ',
//         component: Membersdashboard,
//         layout: '/admin',
//       },
//       {
//         path: '/stats/teams',
//         name: 'Joukkuetilastot',
//         mini: 'JO',
//         component: Teamsdashboard,
//         layout: '/admin',
//       },
//     ],
//   },
//   {
//     path: '/dashboard',
//     name: 'Dashboard',
//     icon: 'nc-icon nc-layout-11',
//     component: Dashboard,
//     layout: '/admin',
//   },
//   {
//     collapse: true,
//     path: '/accounting',
//     name: 'Talous',
//     icon: 'nc-icon nc-bank',
//     state: 'accountingCollapse',
//     views: [
//       {
//         path: '/accounting/cashflow',
//         name: 'Kassavirta',
//         mini: 'K',
//         component: Cash,
//         layout: '/admin',
//       },
//       {
//         path: '/accounting/ledger',
//         name: 'Reskontra / Myynti',
//         mini: 'M',
//         component: LedgerDash,
//         layout: '/admin',
//       },
//       {
//         path: '/accounting/invoices',
//         name: 'Ostolaskut',
//         mini: 'O',
//         component: PurchaseInvoices,
//         layout: '/admin',
//       },
//       {
//         path: '/accounting/incomestatement',
//         name: 'Tuloslaskelma',
//         mini: 'TL',
//         component: IncomeStatement,
//         layout: '/admin',
//       },
//       {
//         path: '/accounting/balance',
//         name: 'Tase',
//         mini: 'TS',
//         component: Balance,
//         layout: '/admin',
//       },
//     ],
//   },
//   {
//     collapse: true,
//     name: 'Henkilöstö',
//     icon: 'nc-icon nc-single-02',
//     state: 'personelCollapse',
//     path: '/personel',
//     layout: '/admin',
//     views: [
//       {
//         path: '/personel',
//         name: 'Yhteenveto',
//         mini: 'Y',
//         component: Personel,
//         layout: '/admin',
//       },
//       {
//         path: '/works',
//         name: 'Työtunnit',
//         mini: 'T',
//         component: WorkAcceptance,
//         layout: '/admin',
//       },
//     ],
//   },
//   {
//     collapse: true,
//     path: '/governance',
//     name: 'Hallinto',
//     icon: 'nc-icon nc-compass-05',
//     state: 'governanceCollapse',
//     layout: '/admin',
//     views: [
//       {
//         path: '/governance/minutes',
//         name: 'Pöytäkirjat',
//         mini: 'P',
//         component: Minutes,
//         layout: '/admin',
//       },
//       {
//         path: '/governance/bylaws',
//         name: 'Säännöt',
//         mini: 'S',
//         component: ByLaws,
//         layout: '/admin',
//       },
//       /*,
//       {
//         path: "/governance/proceedings",
//         name: "Toimintakertomus",
//         mini: "T",
//         component: Proceedings,
//         layout: "/admin"
//       }
// */
//     ],
//   },
//   {
//     path: '/timeline',
//     name: 'Vuosikello',
//     mini: 'VK',
//     icon: 'nc-icon nc-calendar-60',
//     component: ClubTimeline,
//     layout: '/admin',
//   },
//   /*
//   {
//     collapse: true,
//     path: "/coaching",
//     name: "Valmennus",
//     icon: "nc-icon nc-user-run",
//     state: "coachingCollapse",
//     layout: "/admin",
//     views: [
//       {
//         path: "/coaching/dash",
//         name: "Yleiskuva",
//         mini: "YK",
//         component: CoachingDash,
//         layout: "/admin"
//       }
//       */
//   /*,
//   {
//     path: "/coaching/competition",
//     name: "Kilpavalmennus",
//     mini: "K",
//     component: ByLaws,
//     layout: "/admin"
//   },
//   {
//     path: "/coaching/underten",
//     name: "U10",
//     mini: "U10",
//     component: ByLaws,
//     layout: "/admin"
//   }
// ]
// },
// */

//   /*  {
//       collapse: true,
//       path: "/competition",
//       name: "Kilpaileminen",
//       icon: "nc-icon nc-trophy",
//       component: UserProfile,
//       layout: "/admin",
//       views: [
//         {
//           path: "/competition/dash",
//           name: "Yleiskuva",
//           mini: "YK",
//           component: Minutes,
//           layout: "/admin"
//         },
//         {
//           path: "/competition/valmennus",
//           name: "Säännöt",
//           mini: "S",
//           component: ByLaws,
//           layout: "/admin"
//         }
//       ]
//     },
//     */
//   {
//     path: '/communication',
//     name: 'Viestintä',
//     icon: 'nc-icon nc-send',
//     component: CommunicationDash,
//     layout: '/admin',
//   },
//   {
//     path: '/logout',
//     name: 'Kirjaudu ulos',
//     icon: 'nc-icon nc-button-power',
//     component: Logout,
//     layout: '/admin',
//   },
// ]

/*
const _routes = {
  tennisfin: _tennisfinroutes,
  salibandy: _salibandyroutes,
  oyt: _oyroutes,
  default: routes,
}
*/

/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// import { Link } from 'react-router-dom';
import DemoAlert from '../components/DemoAlert'

// reactstrap components
import {
  Row,
  Col
} from "reactstrap";

import PersonelSum from './PersonelSums'
import PersonelList from './PersonelList'
import { withFirebase } from 'components/Firebase'

class Personel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      raw: null,
      loading: true
    }
  }

  componentDidMount() {
    this.props.firebase
    .getEmployees()
    .onSnapshot((snapshot) => {
      let rawData = []
      snapshot.forEach((doc) => {
        let _d = { ...doc.data(), id: doc.id }
        rawData.push(_d)
      })
      this.setState({
        raw: rawData,
        loading: false
      })
    })
  }


  render() {
    if (!this.state.loading) {
      return (
        <>
          <div className="content">
            <DemoAlert demolocation="personel" {...this.props} />
            <Row>
              <Col md="12">
                <PersonelList {...this.props} raw={this.state.raw} />
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="3" sm="3">
                <PersonelSum widget="amounts" raw={this.state.raw} />
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return (<></>)
    }
  }
}

export default withFirebase(Personel)

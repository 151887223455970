import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle
} from "reactstrap";




class Clubrankings extends React.Component {
  constructor(props) {
    super(props);
    let c = this.getChart()
    this.state = { options: c.options, data: null, ownclub: props.clubname, gendertitle: (props.gender === 'L' ? 'Naiset' : 'Miehet') }
  }

  updateData = (ratings) => {
    this.setState({ data: this.ratingsToData(ratings) })
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log('shouldComponentUpdate')
    if (this.props.data === nextProps.data) {
      // console.log('yes - no update')
      // console.log(this.state.data)
      // console.log(nextState.data)
      return true;
    } else {
      // console.log('yes')
      // console.log(this.state)
      this.updateData(nextProps.data)
      // console.log(this.state)
      return true
    }
  }

  sortObjects = (a, b) => {
    let c = 0
    if(a.topfour>b.topfour) c = 1
    if(b.topfour>a.topfour) c = -1
    return c
  }

  ratingsToData = (rankingsAll) => {
    
    let rankings = rankingsAll.filter((r) => { return r.gender === this.props.gender })
    // console.log(rankings)
    rankings.sort(this.sortObjects)
    // console.log(rankings)

    let clubRank = rankings.findIndex(r => r.name === this.state.ownclub)
    let clubValue = (clubRank > -1 ? rankings[clubRank].topfour : -1) 
    rankings.splice(10)

    let data = { datasets:[], labels:[] }
    data.clubrank = { place: clubRank + 1, value: clubValue }
    let i = 0
    let ds = { data:[] }


    ds.borderColor = this.getChartColors()[i]
    ds.backgroundColor = this.getChartColors()[i]
    ds.hoverBorderColor = this.getChartColors()[i]
    ds.borderWidth = 1
    ds.fill = true
    ds.label = 'Top 4 k.a.'
    for (let i = 0; i < rankings.length; i++) {
      ds.data.push(rankings[i].topfour)
      data.labels.push(rankings[i].name)
    }
    data.datasets.push(ds)

    // console.log('returning')
    // console.log(data)
    return data
  }

  getChartColors = () => ["#fcc468", "#4cbdd7"]

  getChart = () => ({
    data: {
      labels: [],
      datasets: [
        {
          label: "Data",
          borderColor: "#fcc468",
          fill: true,
          backgroundColor: "#fcc468",
          hoverBorderColor: "#fcc468",
          borderWidth: 8,
          data: []
        },
        {
          label: "Data",
          borderColor: "#4cbdd7",
          fill: true,
          backgroundColor: "#4cbdd7",
          hoverBorderColor: "#4cbdd7",
          borderWidth: 8,
          data: []
        }
      ]
    },
    options: {
      tooltips: {
        tooltipFillColor: "rgba(0,0,0,0.5)",
        tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        tooltipFontSize: 14,
        tooltipFontStyle: "normal",
        tooltipFontColor: "#fff",
        tooltipTitleFontFamily:
          "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        tooltipTitleFontSize: 14,
        tooltipTitleFontStyle: "bold",
        tooltipTitleFontColor: "#fff",
        tooltipYPadding: 6,
        tooltipXPadding: 6,
        tooltipCaretSize: 8,
        tooltipCornerRadius: 6,
        tooltipXOffset: 10
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: "#9f9f9f",
              //fontStyle: "bold",
              beginAtZero: true,
              padding: 2
            },
            gridLines: {
              zeroLineColor: "transparent",
              display: true,
              drawBorder: false,
              color: "#9f9f9f"
            }
          }
        ],
        yAxes: [
          {
            barPercentage: 0.9,
            gridLines: {
              zeroLineColor: "white",
              display: false,
              drawBorder: false,
              color: "transparent"
            },
            ticks: {
              padding: 2,
              fontColor: "#9f9f9f",
              //fontStyle: "bold"
            }
          }
        ]
      }
    }
  })
  

  render() {
    if(this.state.data) {
      return (
        <Card>
        <CardHeader>
          <CardTitle tag="h4">Seurojen ranking {this.state.gendertitle}<br/>Top 4 pelaajat</CardTitle>
          <p className="card-category"></p>
        </CardHeader>
        <CardBody>
          <HorizontalBar 
            data={this.state.data}
            options={this.state.options}
          />
        </CardBody>
        <CardFooter>
          <div className="legend">
            <i className="fa fa-circle text-warning" />{this.state.data.clubrank.place}. {this.state.ownclub} {this.state.data.clubrank.value}
          </div>
        </CardFooter>
      </Card>
      )
    } else {
      return (<Card>
      <CardHeader>
        <CardTitle tag="h4">Seurojen ranking {this.state.gendertitle}<br/>Top 4 pelaajat</CardTitle>
        <p className="card-category"></p>
      </CardHeader>
      <CardBody>loading ... </CardBody></Card>)
    }

  }
}
export default Clubrankings
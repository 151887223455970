/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

import InvoiceImport from 'components/ManualInsertions/InvoiceImport'

const folder = "imports"

class InvoiceImporter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    if (this.props.profile.userprofile.role && this.props.profile.userprofile.role === 'ADMIN') {
      return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5">Import Emce invoices</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <InvoiceImport {...this.props} folder={folder} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return (<></>)
    }
  }
}

export default InvoiceImporter

import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/firebase-firestore'
import moment from 'moment'
import 'moment/locale/fi'

const config = {
  apiKey: "AIzaSyDVtJoiGAIR5sy3_ndkInugdYSR-ke4GSA",
  authDomain: "winter-cf1.firebaseapp.com",
  databaseURL: "https://winter-cf1.firebaseio.com",
  projectId: "winter-cf1",
  storageBucket: "winter-cf1.appspot.com",
  messagingSenderId: "220264158496",
  appId: "1:220264158496:web:1bf440b1cca4396be03345",
  measurementId: "G-6ZF15CFB71"
}

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.database()
    this.firestore = app.firestore()
    this.storage = app.storage()
    this.analytics = app.analytics()
    this.customrid = null
  }


  getCustomerRef = () => {
    return this.firestore
      .collection('customers')
      .doc(this.customerid)
  }

  logEvent = (name, eventparameters) => {
    this.analytics.logEvent(name, eventparameters)
  }

  getRandomId = () => this.firestore.collection('random').doc().id
  getTimestamp = (dateString) =>
    app.firestore.Timestamp.fromDate(moment(dateString, 'DD.MM.YYYY').toDate())
  getToday = () => app.firestore.Timestamp.fromDate(new Date())

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) => {
    this.logEvent('create_user_email')
    return this.auth.createUserWithEmailAndPassword(email, password)
  }

  doSignInWithEmailAndPassword = (email, password) => {
    this.logEvent('signin_email')
    this.auth.signInWithEmailAndPassword(email, password)
  }

  
  doSignInWithMicrosoft = () => {
    this.logEvent('signin_microsoft')
    var provider = new app.auth.OAuthProvider('microsoft.com')
    return this.auth.signInWithRedirect(provider)
  }

  doSignOut = () => this.auth.signOut().then(() => { console.log('out') })

  doPasswordReset = (email) => {
    this.logEvent('password_reset_email')
    return this.auth.sendPasswordResetEmail(email)
  }

  doPasswordUpdate = (password) => {
    this.logEvent('password_reset')
    return this.auth.currentUser.updatePassword(password)
  }

  // *** Admin ***
  customers = () => this.firestore.collection('admin').doc('customers')
  setActiveCustomer = (uid, customerid) => {
    return this.firestore
      .collection('users')
      .doc(uid)
      .set({ customerIds: [customerid] }, { merge: true })
  }

  // *** User API ***
  // user = (uid) => this.firestore.collection('users').doc(uid)

  getUser = () => {
    return this.firestore.collection('users').doc(this.auth.currentUser.uid).get().then((ud) => {
      if (!ud.exists) {
        return Promise.reject(new Error('Käyttäjätunnusta ei ole.'))
      } else {
        return Promise.resolve(ud)
      }
    }).catch((error) => {
      return Promise.reject(error)
    })
  }
  users = () => this.firestore.collection('users')
  customer = () => this.getCustomerRef()

  // *** Hours API ***
  employeeHours = (employeeid) =>
    this.getCustomerRef()
      .collection('hours')
      .where('employeeid', '==', employeeid)
  
  hours = () =>
    this.getCustomerRef().collection('hours')
  
  acceptHours = (docid) =>
    this.getCustomerRef()
      .collection('hours')
      .doc(docid)

      // *** Expenses API ***
  employeeExpenses = (employeeid) =>
    this.getCustomerRef()
      .collection('expenses')
      .where('employeeid', '==', employeeid)
  
  expenses = () =>
    this.getCustomerRef().collection('expenses')
  
  acceptExpenses = (docid) =>
    this.getCustomerRef()
      .collection('expenses')
      .doc(docid)

      // *** TravelExpenses API ***
      employeeTravelExpenses = (employeeid) =>
      this.getCustomerRef()
        .collection('travelexpenses')
        .where('employeeid', '==', employeeid)
    
        travelexpenses = () =>
      this.getCustomerRef().collection('travelexpenses')
    
    acceptTravelExpenses = (docid) =>
      this.getCustomerRef()
        .collection('travelexpenses')
        .doc(docid)
    
  // *** Accounting ***
  cashflow = () =>
    this.getCustomerRef()
      .collection('cashflow')
      .orderBy('date')
  
  addInvoice = (invoice) => {
    return this.getCustomerRef()
      .collection('invoices')
      .add(invoice)
  }
  addCashFlow = (cashflow, documentid) => {
    return this.getCustomerRef()
      .collection('cashflow')
      .doc(documentid)
      .set(cashflow)
  }
  invoices = () =>
    this.getCustomerRef()
      .collection('invoices')
      .orderBy('duedate')
  
  acceptInvoice = (docid) =>
    this.getCustomerRef()
      .collection('invoices')
      .doc(docid)

  getinvoicesaggregate = () =>
    this.getCustomerRef()
      .collection('aggregates')
      .doc('purchaseinvoices')
  
  getledgeraggregate = () =>
    this.getCustomerRef()
      .collection('aggregates')
      .doc('ledger')

  // *** Coaching ***

  getcoachingaggregate = () =>
    this.getCustomerRef()
      .collection('aggregates')
      .doc('coaching')

  
  // *** ClubMetering ***
  membersCount = (clubid, startdate, enddate) => {
    let start = new Date(startdate).getTime()
    let end = new Date(enddate).getTime()
    return this.db
      .ref('clubdata/tennisfin/metering')
      .orderByChild('created')
      .startAt(start)
      .endAt(end)
      .once('value')
  }

  topFours = (startdate, enddate) => {
    // console.log(startdate + ' - ' + enddate)
    const start = new Date(startdate).getTime()
    const end = new Date(enddate).getTime()
    return this.db
      .ref('clubdata/tennisfin/metering')
      .orderByChild('created')
      .startAt(start)
      .endAt(end)
      .once('value')
  }

  // ** EventCalendar **
  addCalendarEvent = (calendarevent) => {
    if (calendarevent.id) {
      this.logEvent('update_calendar_event')
      let docid = calendarevent.id
      delete calendarevent.id
      return this.getCustomerRef()
        .collection('eventcalendar')
        .doc(docid)
        .set(calendarevent)
    } else {
      this.logEvent('create_calendar_event')
      return this.getCustomerRef()
        .collection('eventcalendar')
        .add(calendarevent)
    }
  }

  getCalendarEvents = () => {
    return this.getCustomerRef()
      .collection('eventcalendar')
  }

  addCalendarEventType = (eventtype) => {
    this.logEvent('create_calendar_event_type')
    return this.getCustomerRef()
      .collection('config')
      .doc('eventcalendar')
      .update({
        types: app.firestore.FieldValue.arrayUnion(eventtype)
    })
  }

  addCalendarEventCategory = (eventcategory) => {
    this.logEvent('create_calendar_event_category')
    return this.getCustomerRef()
      .collection('config')
      .doc('eventcalendar')
      .update({
        categories: app.firestore.FieldValue.arrayUnion(eventcategory)
    })
  }


  getCalendarEventConfig = () => {
    return this.getCustomerRef()
      .collection('config')
      .doc('eventcalendar')
  }

  
  // ** Personel **
  addEmployee = (employee) => {
    this.logEvent('create_employee')
    return this.getCustomerRef()
      .collection('employees')
      .add(employee)
      .then((u) => {
        // let user = employee
        // user.displayName = employee.firstname + ' ' + employee.lastname
        // delete user.log
        // delete user.firstname
        // delete user.lastname
        // delete user.title
        // return this.firestore
        //   .collection('users')
        //   .doc(u.id)
        //   .set(user, { merge: true })
        //   .then(() => {
            return Promise.resolve(employee)
          // })
      })
  }

  setEmployee = (employee) => {
    this.logEvent('update_employee')
    return this.getCustomerRef()
      .collection('employees')
      .doc(employee.id)
      .set(employee, { merge: true })
      .then((u) => {
        // let user = employee
        // user.displayName = employee.firstname + ' ' + employee.lastname
        // delete user.log
        // delete user.firstname
        // delete user.lastname
        // delete user.title
        // return this.firestore
        //   .collection('users')
        //   .doc(employee.id)
        //   .set(user, { merge: true })
        //   .then(() => {
            return Promise.resolve(employee)
          // })
      })
  }

  
  addWorkToEmployee = (employeeid, work, id) => {
    this.logEvent('add_work_to_employee')
    work.employeeid = employeeid
    // return this.db.ref('hours/' + customerId + '/' + employee + '/' + id).set(work)
    return this.getCustomerRef().collection('hours').doc(id).set(work).then((w) => {
      return Promise.resolve(w)
    })
  }
  
  getEmployees = () =>
    this.getCustomerRef()
      .collection('employees')
  
  getEmployee = (employeeid) =>
    this.getCustomerRef()
      .collection('employees')
      .doc(employeeid)

  getEmployeeCourses = (employeeid) =>
      this.getCustomerRef()
        .collection('employees')
      .doc(employeeid)
      .collection('courses')

  addEmployeeCourses = (employeeid, courses) => {
    this.logEvent('add_course_to_employee')
    const batch = this.firestore.batch()
    courses.map((c) => {
      if (c.id) {
        let ref = this.getCustomerRef()
        .collection('employees')
        .doc(employeeid)
          .collection('courses').doc(c.id)
        batch.set(ref, c, { merge : true })
      } else {
        let ref = this.getCustomerRef()
        .collection('employees')
        .doc(employeeid)
          .collection('courses').doc()
        batch.set(ref, c)
      }
      return c
    })
    return batch.commit()
  }
  
  
  getCustomerWorks = () => {
    return this.getCustomerRef()
      .collection('hours')
  }

  // getUserWorks = (customerId) => {
  //   return this.getCustomerRef()
  //     .collection('hours')
  //     .where('employeeid', '==', this.auth.currentUser.uid)
  //   // return this.db.ref('hours/' + customerId + '/' + this.auth.currentUser.uid + '/')
  // }

  // getWorkById = (customerId, id) => {
  //   return this.getCustomerRef()
  //     .collection('hours')
  //     .doc(id)
  //     .get()
  //   // return this.db.ref('hours/' + customerId + '/' + this.auth.currentUser.uid + '/' + id).once("value")
  // }

  // ** Configs **

  addKontoPlan = (kontoplan) => {
    this.logEvent('add_config_konto')
    return this.getCustomerRef()
      .collection('config')
      .doc('kontoplan')
      .set(kontoplan, { merge: true })
  }

  getKontoPlan = () => {
    return this.getCustomerRef()
      .collection('config')
      .doc('kontoplan')
  }

  addWorkSettings = (worksettings) => {
    this.logEvent('add_config_worksettings')
    return this.getCustomerRef()
      .collection('config')
      .doc('worksettings')
      .set(worksettings, { merge: true })
  }

  getWorkSettings = () => {
    return this.getCustomerRef()
      .collection('config')
      .doc('worksettings')
  }

  getAuthSettings = () => {
    return this.getCustomerRef()
      .collection('config')
      .doc('authsettings')
  }

}
export default Firebase
/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import { withFirebase } from '../components/Firebase'
import DemoAlert from './components/DemoAlert'

// reactstrap components
import {
  Row,
  Col
} from 'reactstrap'
import KontoPlan from './config/KontoPlan'
import WorkSettings from './config/WorkSettings'

// core components

class ConfigDash extends React.Component {
  render () {
    return (
      <>
        <div className='content'>
          <DemoAlert demolocation='config' {...this.props} />
          <Row>
            <Col md='12'>
              <KontoPlan plan={this.props.profile.customer.plan} {...this.props} />
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <WorkSettings plan={this.props.profile.customer.plan} {...this.props} />
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default withFirebase(ConfigDash)

import React, { Component } from 'react';
import { 
  Router,
  Route
  // Switch,
  // Redirect
 } from "react-router-dom";
import { createBrowserHistory } from "history";

import { withAuthentication } from './components/Session';
import { WhereToGo } from './components/Auth';
// import AuthLayout from "layouts/Auth.jsx";
// import AdminLayout from "layouts/Admin.jsx";
// import AuthCheck from "components/AuthCheck.jsx"

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

const hist = createBrowserHistory();
/*
const App = () => (
  <Router history={hist}>
    <WhereToGo {...props} />
  </Router>
)
*/
/*

          <Switch>
            <Route path="/auth" render={props => <AuthLayout {...props} />} />
            <Route path="/admin" render={props => <AdminLayout {...props} />} />
          </Switch>
*/

class App extends Component {


  render() {
    console.log(this.props)
    // const restProps = this.props
    return (
        <Router history={hist}>
          <Route {...this.props} render={props => <WhereToGo {...props} />} />
        </Router>
    )
  }
}

export default withAuthentication(App);

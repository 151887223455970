/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// import firebase from "components/Firebase/index"
import { withFirebase } from '../../components/Firebase'
import { withAuthentication } from 'components/Session/index'
// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  //  Label,
  //  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Collapse,
  Spinner,
} from 'reactstrap'

// import { Link } from "react-router-dom"

const INITIAL_STATE = {}

class Login extends React.Component {
  constructor(props) {
    super(props)
    // this.onSubmit = this.onSubmit.bind(this)
    this.setEmail = this.setEmail.bind(this)
    this.setPassword = this.setPassword.bind(this)
    // this.login = this.login.bind(this)
    let _loading = false
    let initialLoading = localStorage.getItem('loading')
    const newInit = Math.round((new Date()).getTime() / 1000)
    if (Math.abs(newInit - initialLoading) < 120) {
      _loading = true
    } else {
      localStorage.removeItem('loading')
    }
    this.state = {
      email: '',
      password: '',
      error: false,
      loading: _loading,
      emailIsOpen: false,
    }
  }

  onSubmitLoading = (event) => {
    if (!this.state.emailIsOpen) {
      this.toggleEmail()
    } else {
      this.setState({ loading: true }, this.onSubmit(event))
    }
  }

  onSubmit = (event) => {
    const { email, password } = this.state
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        // console.log('auth done')
        this.setState({ ...INITIAL_STATE })
        this.props.history.push('/admin/dashboard')
      })
      .catch((error) => {
        // alert(error.message)
        console.log(error.message)
        this.setState({ error: true, le: error.message })
      })
    event.preventDefault()
  }

  onOfficeLoading = (event) => {
    const tsNow = Math.round((new Date()).getTime() / 1000)
    localStorage.setItem('loading', tsNow)
    this.setState({ loading: true }, this.onOffice(event))
  }

  onOffice = (event) => {
    this.props.firebase
      .doSignInWithMicrosoft()
      .then(() => {
        // console.log('auth done')
        this.setState({ ...INITIAL_STATE })
        this.props.history.push('/admin/dashboard')
      })
      .catch((error) => {
        alert(error.message)
        this.setState({ error: true, le: error.message })
        this.props.firebase.doSignOut()
      })
    event.preventDefault()
  }

  setEmail = (value) => {
    this.setState({ email: value })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onSubmit(event)
    }
  }

  setPassword = (value) => {
    this.setState({ password: value })
  }

  componentDidMount() {
    document.body.classList.toggle('login-page')
  }
  componentWillUnmount() {
    document.body.classList.toggle('login-page')
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.user) {
      this.props.history.push('/admin/dashboard')
      return true
    }
    if (
      nextProps.loginError &&
      !this.state.loginError &&
      !nextState.loginError
    ) {
      // !== nextState.le) {
      this.setState({ error: true, loginError: nextProps.loginError })
    }
    return true
  }

  onDismiss = () => {
    localStorage.removeItem('loading')
    this.setState({ error: !this.state.error, loading: false })
  }

  toggleEmail = () => {
    this.setState({ emailIsOpen: !this.state.emailIsOpen })
  }

  render() {
    if (!this.state.loading) {
      return (
        <div className='login-page'>
          <Container>
            <Row>
              <Col className='ml-auto mr-auto' lg='4' md='6'>
                <Form action='' className='form' method=''>
                  <Card className='card-login'>
                    <CardHeader>
                      <CardHeader>
                        <h3 className='header text-center'>Login</h3>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      <Collapse isOpen={this.state.emailIsOpen}>
                        <InputGroup>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <i className='nc-icon nc-single-02' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder='Email'
                            type='text'
                            // value={email}
                            onChange={(e) => this.setEmail(e.target.value)}
                            onKeyPress={this.handleKeyPress}
                          />
                        </InputGroup>
                        <InputGroup>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                              <i className='nc-icon nc-key-25' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder='Password'
                            type='password'
                            autoComplete='off'
                            onChange={(e) => this.setPassword(e.target.value)}
                            onKeyPress={this.handleKeyPress}
                          />
                        </InputGroup>
                      </Collapse>
                      <Collapse isOpen={!this.state.emailIsOpen}></Collapse>
                      <Button
                        block
                        className='btn-round mb-3'
                        color='warning'
                        onClick={this.onSubmitLoading}
                      >
                        {this.state.emailIsOpen ? 'Login' : 'Email tunnuksilla'}
                      </Button>
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        className='btn-round mb-3'
                        color='danger'
                        onClick={this.onOfficeLoading}
                      >
                        Office <i className='fa fa-windows' />
                        tunnuksilla
                      </Button>
                      <Alert
                        color='danger'
                        fade={false}
                        isOpen={this.state.error}
                        toggle={this.onDismiss}
                      >
                        <span>
                          <b>
                            {this.props.loginError
                              ? this.props.loginError
                              : this.state.loginError}
                          </b>
                        </span>
                      </Alert>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
          <div
            className='full-page-background'
            style={{
              backgroundImage: `url(${require('assets/img/bg/fabio-mangione.jpg')})`,
            }}
          />
        </div>
      )
    } else {
      return (
        <div className='login-page'>
          <Container>
            <Row>
              <Col className='ml-auto mr-auto' lg='4' md='6'>
                <Form action='' className='form' method=''>
                  <Card className='card-login'>
                    <CardHeader>
                      <CardHeader>
                        <h3 className='header text-center'>Login</h3>
                      </CardHeader>
                    </CardHeader>
                    <CardBody className='text-center'><Spinner type="grow" color="secondary"/></CardBody>
                    <CardFooter>
                      <Alert
                        color='danger'
                        fade={false}
                        isOpen={this.state.error}
                        toggle={this.onDismiss}
                      >
                        <span>
                          <b>
                            {this.props.loginError
                              ? this.props.loginError
                              : this.state.loginError}
                          </b>
                        </span>
                      </Alert>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
          <div
            className='full-page-background'
            style={{
              backgroundImage: `url(${require('assets/img/bg/fabio-mangione.jpg')})`,
            }}
          />
        </div>
      )
    }
  }
}

export default withAuthentication(withFirebase(Login))

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import NotificationAlert from 'react-notification-alert'
import DemoAlert from '../components/DemoAlert'
import DocumentUpload from 'components/DocumentUpload/DocumentUpload'
import DocumentList from 'views/components/DocumentList'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
} from 'reactstrap'

const folderBoard = 'minutes-board'
const folderAnnual = 'minutes-annual'

class Minutes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fileurl: '',
      modalNoticeBoard: false,
      modalNoticeAnnual: false,
      reload: false,
      addnewbuttonBoard: '',
      addnewbuttonAnnual: '',
    }
  }
  demoNotify = () => {
    let options = {}
    options = {
      place: 'tr',
      message: (
        <div>
          <div>Tämä toiminto ei ole käytössä demo versiossa.</div>
        </div>
      ),
      type: 'warning',
      icon: 'now-ui-icons ui-1_bell-53',
      autoDismiss: 4,
    }
    this.refs.notificationAlert.notificationAlert(options)
  }

  componentDidMount() {
    const addButtonBoard = (
      <Button
        color='info'
        className='float-right'
        onClick={() => this.addNewBoard()}
      >
        <span className='btn-label'>
          <i className='nc-icon nc-simple-add' />
        </span>
        Lisää uusi
      </Button>
    )
    const addButtonAnnual = (
      <Button
        color='info'
        className='float-right'
        onClick={() => this.addNewAnnual()}
      >
        <span className='btn-label'>
          <i className='nc-icon nc-simple-add' />
        </span>
        Lisää uusi
      </Button>
    )
    this.setState({
      addnewbuttonBoard: addButtonBoard,
      addnewbuttonAnnual: addButtonAnnual,
    })
  }

  addNewBoard = () => {
    if (this.props.profile.customer.plan === 'free') {
      this.demoNotify()
    } else {
      this.setState({
        modalNoticeBoard: !this.state.modalNoticeBoard,
      })
    }
  }

  addNewAnnual = () => {
    if (this.props.profile.customer.plan === 'free') {
      this.demoNotify()
    } else {
      this.setState({
        modalNoticeAnnual: !this.state.modalNoticeAnnual,
      })
    }
  }

  uploadDoneBoard = () => {
    this.toggleModalNoticeBoard()
    this.setState({ reload: true })
  }

  uploadDoneAnnual = () => {
    this.toggleModalNoticeAnnual()
    this.setState({ reload: true })
  }

  toggleModalNoticeBoard = () => {
    this.setState({
      modalNoticeBoard: !this.state.modalNoticeBoard,
    })
  }

  toggleModalNoticeAnnual = () => {
    this.setState({
      modalNoticeAnnual: !this.state.modalNoticeAnnual,
    })
  }

  render() {
    return (
      <>
        <NotificationAlert ref='notificationAlert' />
        <div className='content'>
          <DemoAlert demolocation='minutes' {...this.props} />
          <Row>
            <Col md='12'>
              <Card>
                <CardHeader>
                  <CardTitle tag='h4'>Hallituksen pöytäkirjat</CardTitle>
                  {this.state.addnewbuttonBoard}
                </CardHeader>
                <CardBody>
                  <DocumentList
                    {...this.props}
                    folder={folderBoard}
                    reload={this.state.reload}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <Card>
                <CardHeader>
                  <CardTitle tag='h4'>
                    Kevät ja syyskokousten pöytäkirjat
                  </CardTitle>
                  {this.state.addnewbuttonAnnual}
                </CardHeader>
                <CardBody>
                  <DocumentList
                    {...this.props}
                    folder={folderAnnual}
                    reload={this.state.reload}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={this.state.modalNoticeBoard}
            toggle={this.toggleModalNoticeBoard}
          >
            <div className='modal-header'>
              <button
                aria-hidden={true}
                className='close'
                data-dismiss='modal'
                type='button'
                onClick={this.toggleModalNoticeBoard}
              >
                <i className='nc-icon nc-simple-remove' />
              </button>
              <h5 className='modal-title' id='myModalLabel'>
                Lisää uusi hallituksen pöytäkirja
              </h5>
            </div>
            <div className='modal-body'>
              <div className='instruction'>
                <Row>
                  <Col md='12'>
                    <DocumentUpload
                      {...this.props}
                      onDone={this.uploadDoneBoard}
                      folder={folderBoard}
                      forcedownload={true}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={this.state.modalNoticeAnnual}
            toggle={this.toggleModalNoticeAnnual}
          >
            <div className='modal-header'>
              <button
                aria-hidden={true}
                className='close'
                data-dismiss='modal'
                type='button'
                onClick={this.toggleModalNoticeAnnual}
              >
                <i className='nc-icon nc-simple-remove' />
              </button>
              <h5 className='modal-title' id='myModalLabel'>
                Lisää uusi vuosikokouksen pöytäkirja
              </h5>
            </div>
            <div className='modal-body'>
              <div className='instruction'>
                <Row>
                  <Col md='12'>
                    <DocumentUpload
                      {...this.props}
                      onDone={this.uploadDoneAnnual}
                      folder={folderAnnual}
                      forcedownload={true}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        </div>
      </>
    )
  }
}

export default Minutes

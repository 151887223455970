import React from "react";
import { withFirebase } from '../../components/Firebase'
import Select from "react-select";

class DocumentSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      size: 'normal',
      documents: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reload !== this.props.reload) {
      this.updateFileList()
    }
  }

  updateFileList = () => {
    if(this.props.folder) {
      let documents = []
      const storageRef = this.props.folder + '/' + this.props.profile.customer.id
      /*const listAllTask =*/ this.props.firebase.storage.ref(`${storageRef}`).listAll().then((res) => {
        res.items.forEach(function(itemRef) {
          // All the items under listRef.
          // console.log('file found')
          let _doc = {}
          itemRef.getMetadata().then(function(metadata) {
            return Promise.resolve(metadata)
          }).then((metadata) => {
            _doc.label = metadata.customMetadata.nameString + ' (' + metadata.customMetadata.dateString + ')'
            itemRef.getDownloadURL().then((url) => _doc.value = url )
          }).catch(function(error) {})
          documents.push(_doc)
        })
        this.setState({ documents: documents })
      })
    }
  }

  componentDidMount() {
    this.updateFileList()
  }

  handleChange = (value) => {
    if(this.props.update) {
      this.props.update(value)
    }
  }

  render() {
    if (this.state.visible && this.state.documents) {
      // let _s = (this.state.size === 'normal' ? '50px' : '25px')
      return (<Select
        className="react-select primary"
        classNamePrefix="react-select"
        name="singleSelect"
        value={this.state.singleSelect}
        onChange={value => {
          this.setState({ singleSelect: value })
          this.handleChange(value)
        }

        }
        options={this.state.documents}
        placeholder="Valitse tiedosto ..."
      />)
    } else {
      return (<div />);
    }
  }
}

export default withFirebase(DocumentSelect);
/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
// import CoachingAges from "./charts/coaching/CoachingAges"
// import CoachingSums from "./charts/coaching/CoachingSums"
import DemoAlert from '../../components/DemoAlert'
import { withFirebase } from '../../../components/Firebase'
import moment from 'moment'
// reactstrap components
import {
  Row,
  Col
} from "reactstrap";
import LineChart from "./LineChart";
import BarChart from "./BarChart";


const lineChart = {
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    datasets: [
      {
        data: [18877.39, 14000, 15000],
        barPercentage: 0.5,
        barThickness: 3,
        maxBarThickness: 3,
        minBarLength: 2,
        borderColor: "#51CACF",
        backgroundColor: "transparent",
        type: 'line',
        lineTension: 0,
        label: 'Saldo 2020',
        yAxisID: 'saldo'
      },
      {
        data: [2804.33, 500, 9000],
        barPercentage: 0.5,
        barThickness: 3,
        maxBarThickness: 3,
        minBarLength: 2,
        borderColor: "#6bd098",
        backgroundColor: "#6bd098",
        type: 'bar',
        label: 'Tulot 2020',
        yAxisID: 'default'
      },
      {
        data: [-10354.23, -5000, -8000],
        barPercentage: 0.5,
        barThickness: 6,
        maxBarThickness: 8,
        minBarLength: 2,
        borderColor: "#f17e5d",
        backgroundColor: "#f17e5d",
        type: 'bar',
        label: 'Menot 2020',
        yAxisID: 'default'
      },
      {
        data: [13248.12,
          7089.45,
          31537.16,
          33327.09,
          47865.44,
          38950.73,
          30965.68,
          28727.31,
          28759.03,
          45854.16,
          31949.61,
          26427.29
        ],
        barPercentage: 1,
        barThickness: 3,
        maxBarThickness: 3,
        minBarLength: 2,
        borderColor: "#cdf6f7",
        backgroundColor: "transparent",
        type: 'line',
        label: 'Saldo 2019',
        lineTension: 0,
        yAxisID: 'saldo'
      },
      {
        data: [10999.72,
          5420.77,
          34758.84,
          16625.64,
          25027.29,
          5640.42,
          3266.50,
          3426.29,
          10140.99,
          29165.53,
          6469.10,
          12245.10
        ],
        barPercentage: 1.0,
        barThickness: 3,
        maxBarThickness: 3,
        minBarLength: 2,
        borderColor: "#bdfcd9",
        backgroundColor: "#bdfcd9",
        type: 'bar',
        label: 'Tulot 2019',
        yAxisID: 'default'
      },
      {
        data: [-10733.61,
        -11579.44,
        -10311.13,
        -14835.71,
        -10488.94,
        -14555.13,
        -11251.55,
        -5664.66,
        -10109.27,
        -12070.40,
        -20373.65,
        -17767.42
        ],
        barPercentage: 0.5,
        barThickness: 6,
        maxBarThickness: 8,
        minBarLength: 2,
        borderColor: "#ffcaba",
        backgroundColor: "#ffcaba",
        type: 'bar',
        label: 'Menot 2019',
        yAxisID: 'default'
      }


    ]
  },
  options: {
    legend: {
      display: true,
      position: "right",
      labels: {
        fontSize: 10,
        boxWidth: 15
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }
}

class CoachingCharts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true,
      cashflow: null,
      updated: '',
      addnewbutton: '',
      reload: false,
      modalNotice: false,
    }
  }
  fillDataset = () => {
    const currentYear = new Date().getFullYear()
    let _datasets = { months: [], saldo: {}, expenses: {}, incomes: {} }
    for (let i = 2020; i <= currentYear; i++) {
      _datasets.sums[i] = []
      _datasets.groups[i] = []
      _datasets.hourutilization[i] = []
      _datasets.occupancy[i] = []
    }
    return _datasets
  }

  getDemoData = () => {
    const lastUpdate = new Date()
    const updateString = ' - ' + lastUpdate.getDate() + '.' + (lastUpdate.getMonth() + 1) + '.'
    this.setState({ updated: updateString }, this.randomiseData)
  }

  randomiseData = (value) => {
    let _f = JSON.parse(JSON.stringify({}))
    if (value && value.value !== 'all') {
      let _ds = _f.data.datasets.map((d) => {
        let _da = d.data.map((v) => Math.round(v * (0.6 + (0.4 * Math.random()))))
        d.data = _da
        return d
      })
      _f.data.datasets = _ds
    }
    this.setState({ cashflow: _f })
  }

  fillYear = () => {
    return {
      sums: {
        men: new Array(12).fill(null),
        women: new Array(12).fill(null)
      },
      groups: {
        count: new Array(12).fill(null),
        hours: new Array(12).fill(null)
      },
      yobs: {},
    }
  }
  aggregateData = () => {
      if (this.state.coachingaggregate) {
        let _datasets = []
        let years = Object.keys(this.state.coachingaggregate)
        for (let i = 0; i < years.length; i++) {
          let year = years[i]
          if(isNaN(year)) continue
          let months = Object.keys(this.state.coachingaggregate[year])
          _datasets[year] = this.fillYear()
          for (let m = 0; m < months.length; m++) {
            let month = months[m]
            if (this.state.coachingaggregate[year][month]) {
              _datasets[year].sums.men[month] = this.state.coachingaggregate[year][month][this.state.level].men
              _datasets[year].sums.women[month] = this.state.coachingaggregate[year][month][this.state.level].women
              _datasets[year].groups.count[month] = this.state.coachingaggregate[year][month][this.state.level].groups
              _datasets[year].groups.hours[month] = this.state.coachingaggregate[year][month][this.state.level].hours
              if (!_datasets[year].yobs[month]) {
                _datasets[year].yobs[month] = {
                  men: new Array(moment().year() - 1900).fill(null),
                  women: new Array(moment().year() - 1900).fill(null),
                  sum: new Array(moment().year() - 1900).fill(null)
                }
              }
              let yobKeys = Object.keys(this.state.coachingaggregate[year][month][this.state.level].yobs)
              yobKeys.map((y) => {
                let index = y - 1900
                _datasets[year].yobs[month].men[index] = this.state.coachingaggregate[year][month][this.state.level].yobs[y].men
                _datasets[year].yobs[month].women[index] = this.state.coachingaggregate[year][month][this.state.level].yobs[y].women
                _datasets[year].yobs[month].sum[index] = this.state.coachingaggregate[year][month][this.state.level].yobs[y].men + this.state.coachingaggregate[year][month][this.state.level].yobs[y].women
                return y
              })
            } 
          }
          // console.log(_datasets[year].yobs)
        }

        let _sumsData = Object.assign({}, lineChart)
        _sumsData.data.datasets = []
        let _groupsData = {
          data: {
            labels: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
            datasets: []
          },
          options: { legend: { display: true, position: "right",  labels: { fontSize: 10, boxWidth: 15 }},
            scales: { yAxes: [{ ticks: { beginAtZero: true, }, },],},
          }
        }
        let _yobsData = {
          data: {
            labels: [],
            datasets: []
          },
          options: { legend: { display: true, position: "right",  labels: { fontSize: 10, boxWidth: 15 }},
            scales: { yAxes: [{ stacked: true }], xAxes: [{ stacked: true }],},
          }
        }
        for (let u = 1900; u <= moment().year(); u++) {
          _yobsData.data.labels.push(u.toString())
        }
        let dsYears = Object.keys(_datasets)
        for (let dy = 0; dy < dsYears.length; dy++) {
          // console.log(_datasets[dsYears[dy]])
          _sumsData.data.datasets.push(this.getLineDataset('Miehet ' + dsYears[dy], _datasets[dsYears[dy]].sums.men))
          _sumsData.data.datasets.push(this.getLineDataset('Naiset ' + dsYears[dy], _datasets[dsYears[dy]].sums.women, 'red'))
          _groupsData.data.datasets.push(this.getLineDataset('Ryhmiä ' + dsYears[dy], _datasets[dsYears[dy]].groups.count))
          _groupsData.data.datasets.push(this.getLineDataset('Tunteja ' + dsYears[dy], _datasets[dsYears[dy]].groups.hours, 'red'))
          if (_datasets[dsYears[dy]].yobs[moment().month()]) {
            console.log(dsYears[dy], _datasets[dsYears[dy]].yobs[moment().month()])
            _yobsData.data.datasets.push(this.getBarDataset('Naisia ' + dsYears[dy], _datasets[dsYears[dy]].yobs[moment().month()].women, 'red'))
            _yobsData.data.datasets.push(this.getBarDataset('Miehiä ' + dsYears[dy], _datasets[dsYears[dy]].yobs[moment().month()].men))
          }
        }
        const lastUpdate = this.state.coachingaggregate.updated.toDate()
        const updateString = '- Päivitetty ' + lastUpdate.getDate() + '.' + (lastUpdate.getMonth() + 1) + '.' + lastUpdate.getFullYear()
        _groupsData.updated = updateString
        _sumsData.updated = updateString
        _yobsData.updated = updateString
        this.setState({ sumsChart: _sumsData, groupsData: _groupsData, yobsData: _yobsData, loading: false })
    }
  }

  getLineDataset = (title, dataArray, cLor) => {
    let border = '#51CACF'
    let bgColor = 'transparent'
    if (cLor === 'red') {
      border = 'rgb(255, 99, 132)'
      bgColor = 'rgba(255, 99, 132, 0.2)'
    }
    return (
      {
        data: dataArray,
        borderColor: border,
        backgroundColor: bgColor,
        lineTension: 0,
        fill: false,
        label: title,
      }
    )
  }

  getBarDataset = (title, dataArray, cLor) => {
    let border = '#51CACF'
    let bgColor = '#51CACF'
    if (cLor === 'red') {
      border = 'rgb(255, 99, 132)'
      bgColor = 'rgba(255, 99, 132)'
    }
    return (
      {
        data: dataArray,
        borderColor: border,
        backgroundColor: bgColor,
        lineTension: 0,
        fill: false,
        label: title,
      }
    )
  }


  componentDidMount() {
    let level = 'adults'
    if (this.props.extra) level = this.props.extra
    if (this.props.profile.customer.plan !== 'free') {
      this.props.firebase.getcoachingaggregate().get().then(doc => {
        if (doc.exists) {
          this.setState({
            coachingaggregate: doc.data(),
            level: level
          }, this.aggregateData)
        }
      })
    } else {
      this.getDemoData()
    }
    // if (this.props.profile.userprofile.role && this.props.profile.userprofile.role === 'ADMIN') {
    //   const addButton = (<Button color="info" className="float-right" onClick={() => this.addNew()}>
    //     <span className="btn-label">
    //       <i className="nc-icon nc-simple-add" />
    //     </span>
    //   Lisää
    //   </Button>)
    //   this.setState({ addnewbutton: addButton })
    // }
  }


  render() {
    if (!this.state.loading) {
      return (
        <>
          <div className="content">
            <DemoAlert demolocation="coaching" {...this.props} />
            <Row>
              <Col md="12">
                <LineChart chartdata={this.state.sumsChart} title='Summat' {...this.props} />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <LineChart chartdata={this.state.groupsData} title='Ryhmät' {...this.props} />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <BarChart chartdata={this.state.yobsData} title='Ikäjakauma' {...this.props} />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                {/* <CoachingSums
                men={121} 
                female={83}
                cmen={30} 
                cfemale={8}
                hmen={38}
                hfemale={43}
                u10men={50} 
                u10female={53}
               /> */}
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return (<></>)
    }
  }
}

export default withFirebase(CoachingCharts)

import React from 'react'
// import DemoAlert from 'views/components/DemoAlert'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Label,
  UncontrolledTooltip,
} from 'reactstrap'
import NotificationAlert from 'react-notification-alert'
import Switch from 'react-bootstrap-switch'

class WorkSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      worksettings: {},
      loading: true,
      saved: true,
    }
  }

  componentDidMount() {
    if (this.props.profile.customer.plan !== 'free') {
      this.unsubscribe = this.props.firebase
        .getWorkSettings()
        .onSnapshot((doc) => {
          if (doc.exists) {
            this.setState({
              worksettings: doc.data(),
              loading: false,
            })
          } else {
            this.setState({
              loading: false,
              worksettings: {
                autoaccepttc: {
                  value: false,
                  label: 'Tennisclub työvuorojen automaatti hyväksyntä',
                  description:
                    'Jos valmentaja on kk-palkkalainen ja työvuoro on tennisclubissa se hyväksytään automaattisesti.',
                },
              },
            })
          }
        })
    } else {
      this.getDemoData()
    }
  }
  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  onChange = (element, state) => {
    let currentValues = JSON.parse(JSON.stringify(this.state.worksettings))
    currentValues.autoaccepttc.value = state
    this.onSave(null, currentValues)
  }

  onSave = (event, worksettings) => {
    if (event) event.preventDefault()
    if(!worksettings) worksettings = this.state.worksettings
    this.props.firebase
      .addWorkSettings(
        worksettings
      )
      .then((dataSnapshot) => {
        this.setState({ saved: true })
      })
  }

  workSettings = () => {
    if (this.state.worksettings) {
      let keys = Object.keys(this.state.worksettings)
      return keys.map((key, index) => {
        let i = index
        let setting = this.state.worksettings[key]
        return (
          <Row key={i} style={{ border: '1px solid rgba(0, 0, 0, 0.00)' }}>
            <Col sm='6' md='6' xs='6'>
              <Label for={key}>{setting.label}</Label>{' '}
              <div name={key}>
              <Switch
                name={key}
                defaultValue={setting.value}
                onColor='primary'
                offColor='primary'
                onChange={(el, state) => this.onChange(el, state)}
              />{' '}
              <Button
                className='btn-round btn-icon btn-icon-mini btn-neutral'
                color='info'
                id={'tooltip' + key}
                title=''
                type='button'
              >
                <i className='nc-icon nc-alert-circle-i' />
              </Button>
              <UncontrolledTooltip delay={0} target={'tooltip' + key}>
                {setting.description}
                </UncontrolledTooltip>
                </div>
            </Col>
          </Row>
        )
      })
    }
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <NotificationAlert ref='notificationAlert' />
          <div className='content'>
            <Row>
              <Col md='12'>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col lg='10' md='5' sm='5'>
                        <CardTitle tag='h5'>Työhyväksynnan asetukset</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.workSettings()}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }
}

export default WorkSettings

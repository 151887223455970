/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin for creating notifications over the dashboard
import ReactBSAlert from "react-bootstrap-sweetalert";

const standardDisclaimer = 'Näkymässä näytetty data on esittelytarkoituksiin.'

const messages = {
  default: 'Tämän näkymän data on esittelytarkoitukseen luotua. Oman seuranne data tulee tähän kun olette Cintoian asiakas.',// +
  //' <a href="https://www.cintoa.com/#contact" target="_blank">Otathan yhteyttä</a>',
  dashboard: 'Tässä näkymässä näet läpileikkauksen seuranne tilanteesta.' +
             'Taloudellisen, laskutuksen, jäsenmäärät, työntekijät, palkat, viestintäkanavat ja valmennuksen.',
  coaching: 'Täältä löytyy suran valmennuksen tilanne ikäryhmittäin ja kulukohdeittain',
  communication: 'Täällä näette seuranne viestinnän tilanteen viestimäärien ja seuraajien suhteen.',
  competition: null,
  cash: 'Tässä näkymässä näette seuran kassan tilanteen. Vertailuarvona on edellinen vuosi',
  ledger: 'Tässä näkymässä on seuranne ajantaisaiset saatavat (reskontra). Näette eri laskutuskohteiden laskujen erääntymisajankohdat',
  purchaseinvoices: 'Tässä näkymässä hyväksytte tai hylkäätte laskunne. Laskut siirtyvät hyväksynnän jälkeen maksatukseen. ',
  bylaws: 'Täältä löytyy seuran voimassa olevat säännöt',
  minutes: 'Täältä löytyy hallituksen pöytäkirjat. Pöytäkirjat allekirjoitetaan sähköisesti ja näette kokoajan allekirjoitusten tilanteen.',
  clubtimeline: 'Tässä näkymässä näet mitä toistuvia tapahtumia seuran hallinnon kannalta tulee hoidettavaksi milloinkin.',
  personel: 'Täällä pääsette hallitsemaan henkilökunnan tietoja',
  workacceptance: 'Tässä näkymässä pääsee hyväksymään työntekijöiden tunnit. Hyväksytyt työt siirtyvät automaattisesti palkanmaksuun.'
}

class DemoAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demonotification: null
    };
  }

  demoAlert = () => {
    let message = (messages[this.props.demolocation] ? messages[this.props.demolocation] : messages.default)
    this.setState({
      demonotification: (
        <ReactBSAlert
          custom
          style={{ display: "block", marginTop: "-150px" }}
          title=''
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
        >
          {message}<br/><br/><small>{standardDisclaimer}</small>
        </ReactBSAlert>
      )
    });
  };
  hideAlert = () => {
    this.props.profile.toggleDemoTick(this.props.demolocation)
    this.setState({
      demonotification: null
    });
  };

  componentDidMount() {
    if (this.props.profile.customer.plan === 'free') {
      // console.log(this.props.profile.demoticks)
      // console.log(this.props.demolocation)
      if(!this.props.profile.demoticks[this.props.demolocation]) {
        this.demoAlert()
      }
    }
  }

  render() {
    return (
      <>
        {this.state.demonotification}
      </>
    );
  }
}

export default DemoAlert;

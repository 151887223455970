/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import moment from 'moment'
import 'moment/locale/fi'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  Button,
  Input,
} from 'reactstrap'
import { withFirebase } from '../../components/Firebase'
import ReactDatetime from 'react-datetime'
import Label from 'reactstrap/lib/Label'
import Cources from './Courses'

const personAttributes = [
  'firstname',
  'lastname',
  'title',
  'email',
  'address',
  'salarymodel',
  'phone',
  'startday',
  'endday',
  'state',
  'twitter',
  'notes',
  'courses',
  'agreements',
]

class Person extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      person: null,
      loading: true,
      detailEdit: false,
      someEdit: false,
      profileEdit: false,
      updated: false,
    }
  }

  componentDidMount() {
    // console.log(this.props.computedMatch.params.id)
    this.loadPerson()
  }

  loadPerson = () => {
    this.props.firebase
    .getEmployee(
      this.props.computedMatch.params.id
    )
    .get()
    .then((doc) => {
      if (doc.exists) {
        let _p = { ...doc.data(), id: doc.id }
        this.setState({ person: _p, loading: false })
      } else {
        this.setState({ loading: false })
      }
    })
  }

  onSave = (editDetails, editProfile, editSome) => {
    if (this.state.updated) {
      let _updated = JSON.parse(JSON.stringify(this.state.person))
      if (!_updated) _updated = {}
      let isNew = _updated.id === 'undefined'
      personAttributes.map((a) => {
        if (this.state[a]) {
          _updated[a] = this.state[a]
        }
        return a
      })
      if (isNew) {
        this.props.firebase
          .setEmployee(_updated)
          .then((result) => {
            let _c = this.getClearAttributes()
            _c.detailEdit = !this.state.detailEdit
            this.setState(_c, this.loadPerson)
          })
      } else {
        this.props.firebase
          .setEmployee(_updated)
          .then((result) => {
            let _c = this.getClearAttributes()
            if (editDetails) {
              _c.detailEdit = !this.state.detailEdit
            } else if (editProfile) {
              _c.profileEdit = !this.state.profileEdit
            } else if (editSome) {
              _c.someEdit = !this.state.someEdit
            }
            this.setState(_c, this.loadPerson)
          })
      }
    } else {
      let _c = {}
      if (editDetails) {
        _c.detailEdit = !this.state.detailEdit
      } else if (editProfile) {
        _c.profileEdit = !this.state.profileEdit
      } else if (editSome) {
        _c.someEdit = !this.state.someEdit
      }
      this.setState(_c)
    }
  }

  getClearAttributes = () => {
    let _c = {}
    personAttributes.map((a) => {
      _c[a] = null
      return a
    })
    return _c
  }

  toggleDetailEdit = () => {
    if (this.state.detailEdit) {
      this.onSave(true, false, false)
    } else {
      this.setState({ detailEdit: !this.state.detailEdit })
    }
  }

  toggleSomeEdit = () => {
    if (this.state.someEdit) {
      this.onSave(false, false, true)
    } else {
      this.setState({ someEdit: !this.state.someEdit })
    }
  }

  toggleProfileEdit = () => {
    if (this.state.profileEdit) {
      this.onSave(false, true, false)
    } else {
      this.setState({ profileEdit: !this.state.profileEdit })
    }
  }

  setAttr = (event) => {
    let _s = {}
    _s[event.target.name] = event.target.value
    _s.updated = true
    this.setState(_s)
  }

  setStartday = (event) => {
    this.setState({ startday: event.format('YYYY-MM-DD'), updated: true })
  }


  render() {
    if (!this.state.loading) {
      return (
        <>
          <div className='content'>
            <Row>
              <Col md='4'>
                <Card className='card-user'>
                  <div className='image'>
                    <img
                      alt='...'
                      src={require('assets/img/bg/joshua-earlesz.jpg')}
                    />
                  </div>
                  <CardBody>
                    <div className='author'>
                      <a href='#pablo' onClick={(e) => e.preventDefault()}>
                        <img
                          alt='...'
                          className='avatar border-gray'
                          src={require('assets/img/faces/kaci-baum-2.jpg')}
                        />
                      </a>
                      {this.state.profileEdit ? (
                        <div>
                          <Label for='firstname'>Etunimi</Label>
                          <Input
                            type='text'
                            name='firstname'
                            id='firstname'
                            placeholder='Etunimi'
                            onChange={this.setAttr}
                            defaultValue={this.state.person.firstname}
                          />
                          <Label for='lastname'>Sukunimi</Label>
                          <Input
                            type='text'
                            name='lastname'
                            id='lastname'
                            placeholder='Sukunimi'
                            onChange={this.setAttr}
                            defaultValue={this.state.person.lastname}
                          />
                          <hr />
                        </div>
                      ) : (
                        <h5 className='title'>
                          {this.state.person.firstname}{' '}
                          {this.state.person.lastname}
                        </h5>
                      )}
                      <p className='description text-center'>
                        {this.state.profileEdit ? (
                          <>
                            <Label for='title'>Titteli</Label>
                            <Input
                              type='text'
                              name='title'
                              id='title'
                              placeholder='Titteli...'
                              onChange={this.setAttr}
                              defaultValue={this.state.person.title}
                            />
                          </>
                        ) : (
                          <>{this.state.person.title}</>
                        )}
                      </p>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button
                      className='btn-icon btn-neutral float-md-right'
                      onClick={(e) => this.toggleProfileEdit()}
                    >
                      <i
                        className={
                          this.state.profileEdit
                            ? 'fa fa-cloud-upload'
                            : 'fa fa-pencil'
                        }
                      />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md='8'>
                <Card>
                  <CardHeader>
                    <Button
                      className='btn-icon btn-neutral float-md-right'
                      onClick={(e) => this.toggleDetailEdit()}
                    >
                      <i
                        className={
                          this.state.detailEdit
                            ? 'fa fa-cloud-upload'
                            : 'fa fa-pencil'
                        }
                      />
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <p className='description'>
                        {' '}
                        <Button
                          size='sm'
                          className='btn-icon btn-round'
                          color='twitter'
                        >
                          <i className='fa fa-envelope' />
                        </Button>{' '}
                        <Input
                          disabled={!this.state.detailEdit}
                          className={
                            this.state.detailEdit
                              ? 'person-edit'
                              : 'person-noedit'
                          }
                          type='text'
                          name='email'
                          id='email'
                          placeholder='email...'
                          onChange={this.setAttr}
                          defaultValue={this.state.person.email}
                        />
                      </p>
                      <p className='description'>
                        {' '}
                        <Button
                          size='sm'
                          className='btn-icon btn-round'
                          color='twitter'
                        >
                          <i className='fa fa-phone' />
                        </Button>{' '}
                        <Input
                          disabled={!this.state.detailEdit}
                          className={
                            this.state.detailEdit
                              ? 'person-edit'
                              : 'person-noedit'
                          }
                          type='text'
                          name='phone'
                          id='phone'
                          placeholder='puhelin...'
                          onChange={this.setAttr}
                          defaultValue={this.state.person.phone}
                        />
                      </p>
                      <p className='description'>
                        <Button
                          className='btn-icon btn-round'
                          color='twitter'
                          size='sm'
                        >
                          <i className='fa fa-map-marker' />
                        </Button>{' '}
                        <Input
                          disabled={!this.state.detailEdit}
                          className={
                            this.state.detailEdit
                              ? 'person-edit'
                              : 'person-noedit'
                          }
                          type='text'
                          name='address'
                          id='address'
                          placeholder='osoite...'
                          onChange={this.setAttr}
                          defaultValue={this.state.person.address}
                        />
                      </p>
                      <p className='description'>
                        <Button
                          className='btn-icon btn-round'
                          color='twitter'
                          size='sm'
                        >
                          <i className='fa fa-money' />
                        </Button>{' '}
                        <Input
                          disabled={!this.state.detailEdit}
                          className={
                            this.state.detailEdit
                              ? 'person-edit'
                              : 'person-noedit'
                          }
                          type='select'
                          name='salarymodel'
                          id='salarymodelSelect'
                          onChange={this.setAttr}
                          defaultValue={this.state.person.salarymodel}
                          placeholder='palkkamalli...'
                        >
                          <option>palkkamalli...</option>
                          <option value='monthly'>Kuukausipalkka</option>
                          <option value='hourly'>Tuntipalkka</option>
                        </Input>
                      </p>
                      <span className='description'>
                        <Button
                          className='btn-icon btn-round'
                          color='twitter'
                          size='sm'
                        >
                          <i className='fa fa-hourglass-start' />
                        </Button>{' '}
                        <ReactDatetime
                          value={
                            this.state.person.startday
                              ? moment(this.state.person.startday, 'YYYY-MM-DD')
                              : undefined
                          }
                          className={
                            this.state.detailEdit
                              ? 'person-edit'
                              : 'person-noedit'
                          }
                          onChange={this.setStartday}
                          inputProps={{
                            className:
                              (this.state.detailEdit
                                ? 'person-edit'
                                : 'person-noedit') + ' form-control',
                            placeholder: 'aloituspäivä...',
                            disabled: !this.state.detailEdit,
                          }}
                          timeFormat={false}
                        />
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md='4'>
                <Card>
                  <CardHeader>
                    <Button
                      className='btn-icon btn-neutral float-md-right'
                      onClick={() => this.toggleSomeEdit()}
                    >
                      <i
                        className={
                          this.state.someEdit
                            ? 'fa fa-cloud-upload'
                            : 'fa fa-pencil'
                        }
                      />
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <p className='description'>
                        <Button
                          className='btn-icon btn-round'
                          color='twitter'
                          size='sm'
                        >
                          <i className='fa fa-twitter' />
                        </Button>{' '}
                        <Input
                          disabled={!this.state.someEdit}
                          className={
                            this.state.someEdit
                              ? 'person-edit'
                              : 'person-noedit'
                          }
                          type='text'
                          name='twitter'
                          id='twitter'
                          placeholder='twitter tunnus...'
                          onChange={this.setAttr}
                          defaultValue={this.state.person.twitter}
                        />
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md='8'>
                <Cources {...this.props} person={this.state.person} />
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }
}

export default withFirebase(Person)

/*
            <Row>
            <Col md='4'></Col>
            <Col md='8'>
                <Card className='card-user'>
                  <CardHeader>
                    <CardTitle tag='h5'>Työsopimus</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table responsive>
                      <thead className='text-primary'>
                        <tr>
                          <th>Tiedosto</th>
                          <th>Päivä</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1200022-työsopimus.pdf</td>
                          <td>01.02.2014</td>
                        </tr>
                        <tr>
                          <td>12828192-ts.docx</td>
                          <td>01.10.2013</td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>

            </Row>
*/
import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle
} from "reactstrap";
import { getCoachingAges, getCoachingGenders } from "variables/mockup-data"
import DemonstrationData from '../../components/DemonstrationData'
const colors = ['#006600', '#009900', '#00CC00', '#00FF00', '#66FF66']

class CoacningAges extends React.Component {
  constructor(props) {
    super(props);
    // let ratingData = this.ratingsToData(this.props.data)
    let c = this.getChart()
    // console.log('constructor')
    this.state = { options: c.options, data: null }
  }

  updateData = (ratings) => {
    // const merged = deepmerge(this.ratingsToData(ratings), this.getChart().data)
    this.setState({ data: this.ratingsToData(ratings) })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.data === nextProps.data) {
      return true
    } else {
      this.updateData(nextProps.data)
      return true
    }
  }

  ratingsToData = (ratings) => {
    // console.log(ratings)

    let data = { datasets: [], labels: [] }
    let i = 0
    for (let key in ratings) {
      let ds = {}
      ds.borderColor = this.getChartColors()[i]
      ds.backgroundColor = this.getChartColors()[i]
      ds.hoverBorderColor = this.getChartColors()[i]
      ds.borderWidth = 1
      ds.fill = true
      ds.label = (key === 'M' ? 'Miehet' : 'Naiset')
      ds.data = Object.keys(ratings[key]).map((k) => ratings[key][k])
      data.labels = Object.keys(ratings[key])
      data.datasets.push(ds)
      i++
    }
    // console.log('returning')
    // console.log(data)
    return data
  }

  getChartColors = () => ["#fcc468", "#4cbdd7"]

  getChart = () => {

    let chartData = {
      data: {
        labels: [],
        datasets: []
      },
      options: {
        tooltips: {
          tooltipFillColor: "rgba(0,0,0,0.5)",
          tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          tooltipFontSize: 14,
          tooltipFontStyle: "normal",
          tooltipFontColor: "#fff",
          tooltipTitleFontFamily:
            "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          tooltipTitleFontSize: 14,
          tooltipTitleFontStyle: "bold",
          tooltipTitleFontColor: "#fff",
          tooltipYPadding: 6,
          tooltipXPadding: 6,
          tooltipCaretSize: 8,
          tooltipCornerRadius: 6,
          tooltipXOffset: 10
        },
        legend: {
          display: true
        },
        scales: {
          yAxes: [
            {
              stacked:true,
              ticks: {
                fontColor: "#9f9f9f",
                fontStyle: "bold",
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 2
              },
              gridLines: {
                zeroLineColor: "transparent",
                display: true,
                drawBorder: false,
                color: "#9f9f9f"
              }
            }
          ],
          xAxes: [
            {
              stacked:true,
              barPercentage: 0.8,
              gridLines: {
                zeroLineColor: "white",
                display: false,

                drawBorder: false,
                color: "transparent"
              },
              ticks: {
                padding: 2,
                fontColor: "#9f9f9f",
                fontStyle: "bold"
              }
            }
          ]
        }
      }
    }
    let mock = null
    if(this.props.profile.customer.federations[0] === 'tennisfin') {
      mock = getCoachingAges()
    } else {
      mock = getCoachingGenders()
    }
    for (let i = 0; i < mock.datas.length; i++) {
      let ds = {
        label: mock.titles[i],
        borderColor: colors[i],
        fill: true,
        backgroundColor: colors[i],
        hoverBorderColor: colors[i],
        borderWidth: 8,
        data: mock.datas[i],
        stacked: true
      }
      chartData.data.datasets.push(ds)
    }
    chartData.data.labels = mock.labels

    return chartData
  }


  render() {
    // console.log('rendering')
    // console.log(this.state.data)
    const chartData = this.getChart()
    if (chartData) {
      return (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Juniorivalmennus ikäjakauma<DemonstrationData plan={this.props.plan}/></CardTitle>
            <p className="card-category"></p>
          </CardHeader>
          <CardBody>
            <Bar
              data={chartData.data}
              options={chartData.options}
            />
          </CardBody>
          <CardFooter>
          </CardFooter>
        </Card>
      )
    } else {
      return (<Card>
        <CardHeader>
          <CardTitle tag="h4">Juniorivalmennus ikäjakauma</CardTitle>
          <p className="card-category"></p>
        </CardHeader>
        <CardBody>loading ... </CardBody></Card>)
    }

  }
}
export default CoacningAges
import React from "react";
import HoursChart from "views/personel/HoursChart"
import WorkAcceptance from "./WorkAcceptance"
import { withFirebase } from '../../components/Firebase'

// reactstrap components
import {
  Row,
  Col
} from "reactstrap";

class Hours extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true
    };
  }


  componentDidMount() {
    const { eid } = this.props.computedMatch.params
    this.setState({ loading: true })
    // check account type
    if (this.props.profile.customer.plan !== 'free') {
      this.props.firebase.getEmployee(eid).get().then((employee) => {
        this.setState({ employee: employee.data() })
      })
      this.unsubscribe = this.props.firebase
        .employeeHours(eid)
        .onSnapshot((snapshot) => {
          console.log('updated')
          // let empNames = []
          // let hours = []
          let rawData = []
          snapshot.forEach((doc) => {
            let _d = { ...doc.data(), id: doc.id }
            rawData.push(_d)
          })
          this.setState(
            {
              data: rawData,
              loading: false
            }
          )
        })
    } else {
      this.getDemoData()
    }
  }
  componentWillUnmount() {
    if (this.props.profile.customer.plan !== 'free') {
      this.unsubscribe()
    }
  }

  render() {
    if(this.state.loading) {
      return (
        <>
          <div className="content">
            <Row>
              <Col md="12">
                Loading ...
              </Col>
            </Row>
          </div>
        </>
      )
  
    } else {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <HoursChart plan="nofree" data={this.state.data}  {...this.props} />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <WorkAcceptance personal={true} employee={this.state.employee} employeedata={this.state.data} {...this.props} />
            </Col>
          </Row>
        </div>
      </>
    )
    }
  }
}

export default withFirebase(Hours)
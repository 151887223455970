import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";

class Widget extends React.Component {
  render() {
    const cl = "fa fa-" + this.props.network
    return (
      <Card className="card-stats">
        <CardBody>
          <Row>
            <Col md="4" xs="5">
              <Button className="btn-icon" color={this.props.network} onClick={this.props.onSelect}>
                <i className={cl} />
              </Button>            </Col>
            <Col md="8" xs="7">
              <div className="numbers">
                <p className="card-category">Seuraajia</p>
                <CardTitle tag="p">{this.props.followers}</CardTitle>
                <p />
                <p className="card-category">Julkaisuja</p>
                <CardTitle tag="p">{this.props.posts}</CardTitle>
                <p />
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            <i className="fa fa-calendar" />
                    Viimeinen 30 päivää
                  </div>
        </CardFooter>
      </Card>
    )
  }
}
export default Widget
/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import { withFirebase } from '../components/Firebase';
import Ledger from './charts/accounting/Ledger'
import Cashflow from './charts/accounting/Cashflow'
import CommunicationDash from 'views/CommunicationDash'
import PersonelSum from 'views/personel/PersonelSums'
import CoachingSums from './charts/coaching/CoachingSums'
import ClubTimeline from 'views/pages/ClubTimeline'
import Membersdashboard from './Membersdashboard'
import DemoAlert from './components/DemoAlert'

// reactstrap components
import { Row, Col } from 'reactstrap'

class Dashboard extends React.Component {
  coachDashboard = () => {
    return (
      <>
        <div className='content'>
          <DemoAlert demolocation='dashboard' {...this.props} />
          <Row>
            <Col lg='4' md='6' sm='6'>
              <Membersdashboard {...this.props} summary={true} />
            </Col>
            <Col lg='4' md='6' sm='6'>
              <CoachingSums
                men={121}
                female={83}
                cmen={30}
                cfemale={8}
                hmen={38}
                hfemale={43}
                u10men={50}
                u10female={53}
              />
            </Col>
            <Col lg='4' md='6' sm='6'></Col>
          </Row>
          <Row>
            <CommunicationDash summary={true} />
          </Row>
          <Row>
            <Col lg='12' md='12' sm='12'>
              <ClubTimeline summary={true} />
            </Col>
          </Row>
        </div>
      </>
    )
  }

  userDashboard = () => {
    return (
      <>
        <div className='content'>
          <DemoAlert demolocation='dashboard' {...this.props} />
          <Row>
            <Col lg='4' md='6' sm='6'>
              <Cashflow
                plan={this.props.profile.customer.plan}
                {...this.props}
                summary={true}
              />
            </Col>
            <Col lg='4' md='6' sm='6'>
              <Ledger
                plan={this.props.profile.customer.plan}
                {...this.props}
                summary={true}
              />
            </Col>
            <Col lg='4' md='6' sm='6'>
              <Membersdashboard {...this.props} summary={true} />
            </Col>
          </Row>
          <Row>
            <Col lg='4' sm='6'>
              <PersonelSum widget='amounts' {...this.props} />
            </Col>
            <Col lg='4' md='6' sm='6'>
              <CoachingSums
                men={121}
                female={83}
                cmen={30}
                cfemale={8}
                hmen={38}
                hfemale={43}
                u10men={50}
                u10female={53}
              />
            </Col>
          </Row>
          <Row>
            <CommunicationDash summary={true} />
          </Row>
          <Row>
            <Col lg='12' md='12' sm='12'>
              <ClubTimeline summary={true} />
            </Col>
          </Row>
        </div>
      </>
    )
  }

  render() {
    if (this.props.profile.userprofile.role)
      if (
        this.props.profile.userprofile.role === 'ADMIN' ||
        this.props.profile.userprofile.role === 'USER'
      ) {
        return this.userDashboard()
      } else if (this.props.profile.userprofile.role === 'COACH') {
        return this.coachDashboard()
      }
  }
}

export default withFirebase(Dashboard)

import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle
} from "reactstrap";




class Ratings extends React.Component {
  constructor(props) {
    super(props);
    // let ratingData = this.ratingsToData(this.props.data)
    let c = this.getChart()
    // console.log('constructor')
    this.state = { options: c.options, data: null }
  }

  updateData = (ratings) => {
    // const merged = deepmerge(this.ratingsToData(ratings), this.getChart().data)
    this.setState({ data: this.ratingsToData(ratings) })
  }

  shouldComponentUpdate(nextProps, nextState) {
    console.log('shouldComponentUpdate')
    // console.log(nextProps.data)
    // console.log(nextState.data)

    if (this.props.data === nextProps.data) {

      // console.log('yes - no update')
      // console.log(this.state.data)
      // console.log(nextState.data)
      return true;
      /*
      if(this.state.data == nextState.data) {
        console.log('yes - no update')
        console.log(this.state.data)
        console.log(nextState.data)
        return true;
      } else {
        console.log('no')
        console.log(this.state)
        return false;
      }
      */
    } else {
      // console.log('yes')
      // console.log(this.state)
      this.updateData(nextProps.data)
      // console.log(this.state)
      return true
    }
  }

  /*
  componentDidMount() {
    // let ratingData = this.ratingsToData(this.props.data)
    console.log('ratingData')
    console.log(this.props.data)
    // this.setState({ data: ratingData })
  }
 */

 ratingsToData = (ratings) => {
    // console.log(ratings)
    
    let data = { datasets:[], labels:[] }
    let i = 0
    for (let key in ratings) {
      let ds = {}
      ds.borderColor = this.getChartColors()[i]
      ds.backgroundColor = this.getChartColors()[i]
      ds.hoverBorderColor = this.getChartColors()[i]
      ds.borderWidth = 1
      ds.fill = true
      ds.label = (key === 'M' ? 'Miehet' : 'Naiset')
      ds.data = Object.keys(ratings[key]).map((k) => ratings[key][k])
      data.labels = Object.keys(ratings[key])
      data.datasets.push(ds)
      i++
    }
    // console.log('returning')
    // console.log(data)
    return data
  }

  getChartColors = () => ["#fcc468", "#4cbdd7"]

  getChart = () => ({
    data: {
      labels: [],
      datasets: [
        {
          label: "Data",
          borderColor: "#fcc468",
          fill: true,
          backgroundColor: "#fcc468",
          hoverBorderColor: "#fcc468",
          borderWidth: 8,
          data: []
        },
        {
          label: "Data",
          borderColor: "#4cbdd7",
          fill: true,
          backgroundColor: "#4cbdd7",
          hoverBorderColor: "#4cbdd7",
          borderWidth: 8,
          data: []
        }
      ]
    },
    options: {
      tooltips: {
        tooltipFillColor: "rgba(0,0,0,0.5)",
        tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        tooltipFontSize: 14,
        tooltipFontStyle: "normal",
        tooltipFontColor: "#fff",
        tooltipTitleFontFamily:
          "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        tooltipTitleFontSize: 14,
        tooltipTitleFontStyle: "bold",
        tooltipTitleFontColor: "#fff",
        tooltipYPadding: 6,
        tooltipXPadding: 6,
        tooltipCaretSize: 8,
        tooltipCornerRadius: 6,
        tooltipXOffset: 10
      },
      legend: {
        display: false
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: "#9f9f9f",
              fontStyle: "bold",
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 2
            },
            gridLines: {
              zeroLineColor: "transparent",
              display: true,
              drawBorder: false,
              color: "#9f9f9f"
            }
          }
        ],
        xAxes: [
          {
            barPercentage: 0.8,
            gridLines: {
              zeroLineColor: "white",
              display: false,
  
              drawBorder: false,
              color: "transparent"
            },
            ticks: {
              padding: 2,
              fontColor: "#9f9f9f",
              fontStyle: "bold"
            }
          }
        ]
      }
    }
  })
  

  render() {
    // console.log('rendering')
    // console.log(this.state.data)
    if(this.state.data) {
    return (
      <Card>
      <CardHeader>
        <CardTitle tag="h4">Pelaajien tasot</CardTitle>
        <p className="card-category"></p>
      </CardHeader>
      <CardBody>
        <Bar
          data={this.state.data}
          options={this.state.options}
        />
      </CardBody>
      <CardFooter>
        <div className="legend">
          <i className="fa fa-circle text-warning" />{this.state.data.datasets[0].label} <i className="fa fa-circle text-info" />{this.state.data.datasets[1].label}
        </div>
        <hr />
        <div className="stats">
          <i className="fa fa-check" />
          Tasoluettelon data
        </div>
      </CardFooter>
    </Card>
    )
    } else {
      return (<Card>
      <CardHeader>
        <CardTitle tag="h4">Ratings</CardTitle>
        <p className="card-category"></p>
      </CardHeader>
      <CardBody>loading ... </CardBody></Card>)
    }

  }
}
export default Ratings